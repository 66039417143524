import posthog from 'posthog-js'

import { PosthogEvent } from '../../hooks/posthogCapture/types'
import { SessionMetadataItem } from '../../types/responses/session-metadata'
import { POSTHOG_EVENT } from '../constants'
import { isDevelopment } from '../helpers/envHelpers'

class PosthogService {
  init() {
    if (!this.isEnabled()) {
      return
    }

    posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
      api_host: 'https://ph.rq.app',
      autocapture: !isDevelopment(),
      disable_session_recording: isDevelopment(),
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    })
  }

  isEnabled() {
    return !isDevelopment()
  }

  identifyUserAndCurrentCompanyGroup(sessionMetadata: SessionMetadataItem) {
    if (!this.isEnabled()) {
      return
    }

    const { currentUser, currentCompany, currentCompanyUser } = sessionMetadata

    if (currentUser && !currentUser.impersonated) {
      posthog.identify(currentUser.keycloakId, {
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.fullName,
        company: currentCompany?.presentationName,
        currentCompanyUserId: currentCompanyUser?.id,
      })

      if (currentCompany !== null) {
        // Important this matches what we do in the API project - look for `PostHog::groupIdentify` there.
        // @see https://posthog.com/docs/product-analytics/group-analytics#how-to-set-group-properties
        posthog.group('company', currentCompany.id.toString(), {
          id: currentCompany.id,
          name: currentCompany.name,
          type: currentCompany.companyType.code,
          status: currentCompany.status,
          companyNumber: currentCompany.companyNumber,
          frn: currentCompany.frn,
        })
      }

      posthog.capture(POSTHOG_EVENT.login)
    }
  }

  capture(eventData: PosthogEvent, sendInstantly: boolean = false) {
    const { appArea, action, ...otherEventProperties } = eventData

    // Put things in a human-readable format for the event title
    const propertiesString = Object.entries(otherEventProperties)
      .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
      .join(', ')

    const eventName = `${appArea} - ${action}${propertiesString ? ` { ${propertiesString} }` : ''}`

    if (isDevelopment()) {
      // Because in dev these events don't get sent to PH. This allows
      // us to debug without having to change things and then crosscheck
      // with what's actually appearing in PH.
      console.log(
        `%c🐽 ${eventName}`,
        'color: #eee; background-image: linear-gradient(45deg, #005497, #002a4b); background-color: #fff8ef; border-radius: 1rem; padding: .25rem .5rem;',
      )
    }

    // The leading emoji is just to help us spot these easily in PostHog (VS all the automated events)
    posthog.capture(
      `🟦 ${eventName}`,
      eventData,
      sendInstantly ? { send_instantly: true } : {},
    )
  }
}

const posthogService = new PosthogService()

export default posthogService
