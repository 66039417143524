import { useQuery } from '@tanstack/react-query'
import ms from 'ms'

import { QUERY_KEYS } from '../lib/queryKeys'
import stateService from '../lib/services/stateService'

export function useAppStateQuery() {
  return useQuery({
    queryKey: QUERY_KEYS.appState,
    queryFn: () => stateService.getStates(),
    staleTime: ms('5s'),
  })
}
