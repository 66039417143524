import {
  IncomeInput,
  MonthlyOutgoingsType,
  PensionContributionInput,
} from '../../utils/types'

export const IncomeInputFields: Record<keyof IncomeInput, keyof IncomeInput> = {
  user_annual_gross_salary: 'user_annual_gross_salary',
  user_annual_gross_bonus: 'user_annual_gross_bonus',
  user_annual_gross_commissions: 'user_annual_gross_commissions',
  user_annual_gross_dividends: 'user_annual_gross_dividends',
  user_annual_gross_rental_income: 'user_annual_gross_rental_income',

  joint_monthly_outgoings_type: 'joint_monthly_outgoings_type',
  joint_monthly_outgoings: 'joint_monthly_outgoings',
  joint_monthly_outgoings_rent: 'joint_monthly_outgoings_rent', // Front-end only
  joint_monthly_outgoings_household: 'joint_monthly_outgoings_household', // Front-end only
  joint_monthly_outgoings_car_finance: 'joint_monthly_outgoings_car_finance', // Front-end only
  joint_monthly_outgoings_car_other: 'joint_monthly_outgoings_car_other', // Front-end only
  joint_monthly_outgoings_food: 'joint_monthly_outgoings_food', // Front-end only
  joint_monthly_outgoings_personal_insurances:
    'joint_monthly_outgoings_personal_insurances', // Front-end only
  joint_monthly_outgoings_loans_and_credit_cards:
    'joint_monthly_outgoings_loans_and_credit_cards', // Front-end only
  joint_monthly_outgoings_children_expenses:
    'joint_monthly_outgoings_children_expenses', // Front-end only
  joint_monthly_outgoings_personal_expenses:
    'joint_monthly_outgoings_personal_expenses', // Front-end only
  joint_monthly_outgoings_savings: 'joint_monthly_outgoings_savings', // Front-end only
  joint_monthly_outgoings_holidays: 'joint_monthly_outgoings_holidays', // Front-end only
  joint_monthly_outgoings_having_fun: 'joint_monthly_outgoings_having_fun', // Front-end only
  joint_monthly_outgoings_misc: 'joint_monthly_outgoings_misc', // Front-end only
  joint_monthly_spent_confidence: 'joint_monthly_spent_confidence',

  user_monthly_outgoings_type: 'user_monthly_outgoings_type',
  user_monthly_outgoings: 'user_monthly_outgoings',
  user_monthly_outgoings_rent: 'user_monthly_outgoings_rent', // Front-end only
  user_monthly_outgoings_household: 'user_monthly_outgoings_household', // Front-end only
  user_monthly_outgoings_car_finance: 'user_monthly_outgoings_car_finance', // Front-end only
  user_monthly_outgoings_car_other: 'user_monthly_outgoings_car_other', // Front-end only
  user_monthly_outgoings_food: 'user_monthly_outgoings_food', // Front-end only
  user_monthly_outgoings_personal_insurances:
    'user_monthly_outgoings_personal_insurances', // Front-end only
  user_monthly_outgoings_loans_and_credit_cards:
    'user_monthly_outgoings_loans_and_credit_cards', // Front-end only
  user_monthly_outgoings_children_expenses:
    'user_monthly_outgoings_children_expenses', // Front-end only
  user_monthly_outgoings_personal_expenses:
    'user_monthly_outgoings_personal_expenses', // Front-end only
  user_monthly_outgoings_savings: 'user_monthly_outgoings_savings', // Front-end only
  user_monthly_outgoings_holidays: 'user_monthly_outgoings_holidays', // Front-end only
  user_monthly_outgoings_having_fun: 'user_monthly_outgoings_having_fun', // Front-end only
  user_monthly_outgoings_misc: 'user_monthly_outgoings_misc', // Front-end only
  user_monthly_spent_confidence: 'user_monthly_spent_confidence',
  user_has_pension_contribution: 'user_has_pension_contribution',
  user_pension_contribution: 'user_pension_contribution',

  partner_annual_gross_salary: 'partner_annual_gross_salary',
  partner_annual_gross_bonus: 'partner_annual_gross_bonus',
  partner_annual_gross_commissions: 'partner_annual_gross_commissions',
  partner_annual_gross_dividends: 'partner_annual_gross_dividends',
  partner_annual_gross_rental_income: 'partner_annual_gross_rental_income',
  partner_monthly_outgoings_type: 'partner_monthly_outgoings_type',
  partner_monthly_outgoings: 'partner_monthly_outgoings',
  partner_monthly_outgoings_rent: 'partner_monthly_outgoings_rent', // Front-end only
  partner_monthly_outgoings_household: 'partner_monthly_outgoings_household', // Front-end only
  partner_monthly_outgoings_car_finance:
    'partner_monthly_outgoings_car_finance', // Front-end only
  partner_monthly_outgoings_car_other: 'partner_monthly_outgoings_car_other', // Front-end only
  partner_monthly_outgoings_food: 'partner_monthly_outgoings_food', // Front-end only
  partner_monthly_outgoings_personal_insurances:
    'partner_monthly_outgoings_personal_insurances', // Front-end only
  partner_monthly_outgoings_loans_and_credit_cards:
    'partner_monthly_outgoings_loans_and_credit_cards', // Front-end only
  partner_monthly_outgoings_children_expenses:
    'partner_monthly_outgoings_children_expenses', // Front-end only
  partner_monthly_outgoings_personal_expenses:
    'partner_monthly_outgoings_personal_expenses', // Front-end only
  partner_monthly_outgoings_savings: 'partner_monthly_outgoings_savings', // Front-end only
  partner_monthly_outgoings_holidays: 'partner_monthly_outgoings_holidays', // Front-end only
  partner_monthly_outgoings_having_fun: 'partner_monthly_outgoings_having_fun', // Front-end only
  partner_monthly_outgoings_misc: 'partner_monthly_outgoings_misc', // Front-end only
  partner_monthly_spent_confidence: 'partner_monthly_spent_confidence',
  partner_has_pension_contribution: 'partner_has_pension_contribution',
  partner_pension_contribution: 'partner_pension_contribution',
}

export const PensionContributionInputFields: Record<
  keyof PensionContributionInput,
  string
> = {
  employee_type: 'employee_type',
  employee_stake: 'employee_stake',
  employer_type: 'employer_type',
  employer_stake: 'employer_stake',
  has_confirmed_stakes: 'has_confirmed_stakes',
}

export const MonthlyOutgoingsBreakdownFields: Array<keyof IncomeInput> = [
  'user_monthly_outgoings_rent',
  'user_monthly_outgoings_household',
  'user_monthly_outgoings_car_other',
  'user_monthly_outgoings_food',
  'user_monthly_outgoings_personal_insurances',
  'user_monthly_outgoings_loans_and_credit_cards',
  'user_monthly_outgoings_children_expenses',
  'user_monthly_outgoings_personal_expenses',
  'user_monthly_outgoings_savings',
  'user_monthly_outgoings_holidays',
  'user_monthly_outgoings_having_fun',
  'user_monthly_outgoings_misc',
]

export const MONTHLY_OUTGOING_TYPE = {
  totalAmount: 'Total amount',
  breakdown: 'Breakdown',
} satisfies Record<string, MonthlyOutgoingsType>

export type MonthlyOutgoingsFor = 'joint' | 'user' | 'partner'

export const MONTHLY_OUTGOINGS_FOR: Record<
  MonthlyOutgoingsFor,
  MonthlyOutgoingsFor
> = {
  joint: 'joint',
  user: 'user',
  partner: 'partner',
}
