import React from 'react'

// Setup the Broadcast Channel
const contextChangeChannel = new BroadcastChannel('rq-context-change')

export const COMPANY_CHANGED = 'COMPANY_CHANGED'
export const CLIENT_CHANGED = 'CLIENT_CHANGED'

export interface ContextChangeMessage {
  type: string
  payload: {
    company?: {
      id: number
    }
  }
}

export function useUserContextChanged(
  callback?: (message: ContextChangeMessage) => void,
) {
  React.useEffect(() => {
    const handleMessage = (event: MessageEvent<ContextChangeMessage>) => {
      const contextMessage = event.data
      if (
        callback &&
        contextMessage &&
        (contextMessage.type === COMPANY_CHANGED ||
          contextMessage.type === CLIENT_CHANGED)
      ) {
        callback(contextMessage) // The callback is invoked here with the received message
      }
    }

    // Attach the event listener
    contextChangeChannel.addEventListener('message', handleMessage)

    // Clean up the event listener when the component unmounts
    return () => {
      contextChangeChannel.removeEventListener('message', handleMessage)
    }
  }, [callback])

  // Method to post context changes
  function postContextChange(type: string, payload: object) {
    const changeMessage: ContextChangeMessage = {
      type,
      payload,
    }

    // Broadcast the context change to other windows
    contextChangeChannel.postMessage(changeMessage)
  }

  return {
    postContextChange,
  }
}
