import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useCompassParams } from '../components/flows/CompassFlow/utils/helpers'
import { isUnauthorizedError } from '../lib/helpers/helperFunctions'
import { ROUTES } from '../lib/routes'
import authService from '../lib/services/authService'
import compassRequestService from '../lib/services/compass/compassRequestService'
import { initializeCompass } from '../redux/slices/compass'
import useAppDispatch from './useAppDispatch'
import { useAuth } from './useAuth'

export function useCreateCompassMetadataMutation() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { token, companyReferralCode } = useCompassParams()

  const { currentUser } = useAuth()

  return useMutation({
    mutationFn: () => {
      return compassRequestService.createCompassMetadata({
        token: token ?? undefined,
        companyReferralCode: companyReferralCode ?? undefined,
      })
    },
    onSuccess: (metadata) => {
      dispatch(
        initializeCompass({
          metadata,
          currentUser,
          searchParams: window.location.search,
        }),
      )
    },
    onError: (error) => {
      if (isUnauthorizedError(error)) {
        // If the problem is because of invalid token,
        // we'll show an invalid link message to the user.
        if (token) {
          return navigate(ROUTES.invalidOrExpired, { replace: true })
        }

        // Otherwise, log them out.
        authService.logout()
      }
    },
  })
}
