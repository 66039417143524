import classNames from 'classnames'
import React from 'react'
import { Link, LinkProps, useNavigate } from 'react-router-dom'

export type ActionLinkProps = LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    beforeNavigate?: () => void
    disabled?: boolean
  }

// Use when you want to run some code before navigating to a new route.
const ActionLink: React.FC<ActionLinkProps> = ({
  to,
  replace,
  beforeNavigate,
  disabled = false,
  className,
  ...props
}) => {
  const navigate = useNavigate()

  return (
    <Link
      to={to}
      onClick={async (event) => {
        event.preventDefault()

        if (disabled) {
          return
        }

        if (beforeNavigate) {
          await beforeNavigate()
        }
        navigate(to, { replace })
      }}
      className={classNames({ 'opacity-75': disabled }, className)}
      {...props}
    />
  )
}

export default ActionLink
