import { FcaServiceTypeCollectionItem } from '../../types/responses/fca-service-types'
import apiService from './apiService'

class FcaServiceTypeService {
  public async getAll(): Promise<FcaServiceTypeCollectionItem> {
    const response = await apiService.get('/v1/fca-service-types')

    return response.data['hydra:member']
  }
}

const fcaServiceTypeService = new FcaServiceTypeService()

export default fcaServiceTypeService
