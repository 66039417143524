import React, { useState } from 'react'

type Props = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  src?: string

  // If fallback is a React element, it will be rendered as is.
  // If fallback is a string, it will be rendered as an <img> element with the
  // string as the src attribute.
  fallback: React.ReactElement | string
  onFailedToLoad?: () => void
}

const Image: React.FC<Props> = ({
  fallback,
  src,
  alt,
  onFailedToLoad,
  ...props
}) => {
  const [failedToLoad, setFailedToLoad] = useState(false)

  if (!src || failedToLoad) {
    if (onFailedToLoad) {
      onFailedToLoad()
    }

    return typeof fallback === 'string' ? (
      <img src={fallback} alt={alt} {...props} />
    ) : (
      fallback
    )
  }

  return (
    <img
      src={src}
      alt={alt}
      {...props}
      onError={() => {
        setFailedToLoad(true)
      }}
    />
  )
}

export default Image
