import { CommonData } from '../../types/responses/common-data'
import { COMMON_DATA_ERROR_MESSAGE } from '../constants'
import apiService from './apiService'

class CommonDataService {
  async getAll(): Promise<CommonData> {
    const { data } = await apiService.get<CommonData>('/v1/common-data/all')

    // The common data endpoint sometimes returns empty results in the dev
    // environment because of some caching issue. Since the app assumes the
    // common data is always available in order to work properly, let's throw
    // an error to avoid subtle/misleading issues. We'll display an error
    // message in the UI when this happens.
    if (!data.companyTypes) {
      throw new Error(COMMON_DATA_ERROR_MESSAGE)
    }

    return {
      serviceAreas: data.serviceAreas,
      fcaServiceAreas: data.fcaServiceAreas,
      fcaServiceTypes: data.fcaServiceTypes,
      expertiseAreas: data.expertiseAreas,
      referralStatuses: data.referralStatuses,
      regulators: data.regulators,
      companyTypes: data.companyTypes,
      networks: data.networks,
    }
  }
}

const commonDataService = new CommonDataService()

export default commonDataService
