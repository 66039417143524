import classNames from 'classnames'
import { FormikContext } from 'formik'
import React, { CSSProperties, useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useLocalStorage } from 'react-use'

import ActionButton from '../misc/ActionButton'
import PlainButton from '../misc/PlainButton'

export interface PageAction {
  label: string
  action: () => Promise<unknown> | void
  isProcessingText?: string
}

interface Props {
  actions: PageAction[]
  showSubmitFormCheckbox?: boolean
}

const PageActions: React.FC<Props> = ({
  actions,
  showSubmitFormCheckbox = true,
}) => {
  const formikContext = useContext(FormikContext)
  const [isProcessing, setIsProcessing] = useState(false)
  const [shouldSubmitForm, setShouldSubmitForm] = useLocalStorage<boolean>(
    'PageActions.shouldSubmitForm',
    false,
  )
  const [isExpanded, setIsExpanded] = useLocalStorage<boolean>(
    'PageActions.isExpanded',
    false,
  )

  const styles: CSSProperties = {
    position: 'fixed',
    bottom: '0px',
    right: '80px',
    zIndex: 10000000000,
    maxWidth: '350px',
  }

  if (!isExpanded) {
    return (
      <Button
        variant="danger"
        size="sm"
        style={styles}
        onClick={() => setIsExpanded(true)}
      >
        Show page actions
      </Button>
    )
  }

  return (
    <div
      className="bg-white d-flex flex-column border py-1 px-2 text-sm d-print-none"
      style={styles}
    >
      <header className="d-flex flex-column flex-sm-row gap-1 justify-content-between mb-1">
        {formikContext && showSubmitFormCheckbox ? (
          <label
            htmlFor="submitForm"
            className="d-flex align-items-center py-1"
          >
            <input
              type="checkbox"
              id="submitForm"
              className="me-2"
              defaultChecked={shouldSubmitForm}
              onChange={(event) => {
                setShouldSubmitForm(event.target.checked)
              }}
            />
            <span>Submit form</span>
          </label>
        ) : (
          <div />
        )}

        {actions.length > 1 && (
          <PlainButton
            className="text-reset text-decoration-underline"
            onClick={() => {
              setIsExpanded(false)
            }}
          >
            Hide
          </PlainButton>
        )}
      </header>

      {actions.map(
        ({ label, action, isProcessingText = 'Processing' }, index) => {
          return (
            <ActionButton
              key={label}
              onClick={async () => {
                setIsProcessing(true)
                await action()

                if (formikContext && shouldSubmitForm) {
                  await formikContext.submitForm()
                  formikContext.setSubmitting(false)
                }

                setIsProcessing(false)
              }}
              variant="danger"
              size="sm"
              className={classNames({ 'mb-1': index < actions.length - 1 })}
              isProcessing={isProcessing}
              isProcessingText={isProcessingText}
            >
              {label}
            </ActionButton>
          )
        },
      )}
    </div>
  )
}

export default PageActions
