import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import invariant from 'tiny-invariant'

import companyConsentService from '../lib/services/companyConsentService'
import { setCompanyConsentTypes } from '../redux/slices/company'
import useAppDispatch from './useAppDispatch'
import useCurrentCompanyOrFail from './useCurrentCompanyOrFail'

export function useCompanyConsentQuery() {
  const currentCompany = useCurrentCompanyOrFail()
  const currentCompanyId = currentCompany.id
  const dispatch = useAppDispatch()

  const query = useQuery({
    queryKey: ['company-consents', { currentCompanyId }],
    queryFn: () => {
      invariant(
        currentCompanyId,
        'Expected current company ID to be set before fetching company consents',
      )
      return companyConsentService.getCompanyConsents(currentCompanyId)
    },
    enabled: !!currentCompanyId,
    refetchOnWindowFocus: false,
  })

  const companyConsents = query.data

  useEffect(() => {
    if (companyConsents) {
      dispatch(setCompanyConsentTypes(companyConsents['hydra:member']))
    }
  }, [companyConsents, dispatch])

  return query
}
