import { StateKey, StateType } from '../../types/misc'
import { CreateStateRequest } from '../../types/requests/states'
import {
  CreateStateResponse,
  StateCollection,
  StateItem,
} from '../../types/responses/state'
import { STATE_TYPE } from '../constants'
import apiService from './apiService'

class StateService {
  private endpoint = '/v1/states'

  async getState(key: StateKey): Promise<StateItem> {
    const response = await apiService.get(`${this.endpoint}/${key}`)

    return response.data
  }

  async getStates(): Promise<StateCollection> {
    const response = await apiService.get(`${this.endpoint}/all`)

    return response.data
  }

  async createState(
    options: CreateStateRequest & { stateType: StateType },
  ): Promise<CreateStateResponse> {
    const { stateType, ...request } = options
    const endpoint = this.getCreateStateEndpoint(stateType)
    const response = await apiService.post(endpoint, request)

    return response.data
  }

  async removeElementFromState(options: {
    stateType: StateType
    stateKey: StateKey
    element: number
  }): Promise<StateItem> {
    // Get fresh state to reduce race conditions
    const freshState = await this.getState(options.stateKey)

    if (!Array.isArray(freshState.stateValue)) {
      throw new Error('Invalid state value: Expected it to be an array')
    }

    // Remove the element from the array
    freshState.stateValue = freshState.stateValue.filter(
      (el: number) => el !== options.element,
    )

    // Update the state
    const endpoint = this.getCreateStateEndpoint(options.stateType)
    await apiService.post(endpoint, {
      stateKey: freshState.stateKey,
      stateValue: freshState.stateValue,
    })

    return freshState
  }

  private getCreateStateEndpoint(stateType: StateType) {
    switch (stateType) {
      case STATE_TYPE.user:
        return `${this.endpoint}/user`
      case STATE_TYPE.company:
        return `${this.endpoint}/company`
      case STATE_TYPE.companyUser:
        return `${this.endpoint}/company-user`
      default:
        throw new Error(`Invalid state type: ${stateType}`)
    }
  }
}

const stateService = new StateService()

export default stateService
