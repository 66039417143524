import { onlineManager } from '@tanstack/react-query'
import get from 'lodash/get'
import React from 'react'

import NewUpdateAvailableMessage from './components/NewUpdateAvailableMessage'
import OfflineMessage from './components/OfflineMessage'
import UnhandledErrorMessage from './components/UnhandledErrorMessage'

interface Props {
  error?: unknown
}

const ErrorMessage: React.FC<Props> = ({ error }) => {
  const isOffline = !onlineManager.isOnline()

  // https://vite.dev/guide/build.html#load-error-handling
  const isOutdatedAssetsError =
    typeof error === 'object' &&
    get(error, 'error.message', '').includes(
      'Failed to fetch dynamically imported module',
    )

  if (isOffline) {
    return <OfflineMessage />
  }

  if (isOutdatedAssetsError) {
    return <NewUpdateAvailableMessage />
  }

  return <UnhandledErrorMessage error={error} />
}

export default ErrorMessage
