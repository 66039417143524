import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'

type Layout = 'companyDashboard' | 'clientDashboard'

export const LAYOUTS: Record<Layout, Layout> = {
  companyDashboard: 'companyDashboard',
  clientDashboard: 'clientDashboard',
}

export interface LayoutState {
  layout?: Layout
}

const INITIAL_STATE: LayoutState = {}

const layout = createSlice({
  name: 'layout',
  initialState: INITIAL_STATE,
  reducers: {
    setLayout(state, action: PayloadAction<Layout>) {
      state.layout = action.payload
    },
  },
})

export const selectLayoutSlice = (state: RootState) => state.layout

export const { setLayout } = layout.actions

export const selectLayout = createSelector(
  selectLayoutSlice,
  (layoutSlice) => layoutSlice.layout,
)

export const selectIsClientDashboardLayout = createSelector(
  selectLayout,
  (layout) => layout === LAYOUTS.clientDashboard,
)

export const layoutReducer = layout.reducer
