import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldCheck } from '@rq-ratings/pro-regular-svg-icons'
import React, { CSSProperties, Suspense } from 'react'
import {
  AlertCircle,
  Compass,
  HelpCircle,
  Layout,
  RefreshCcw,
  Share2,
} from 'react-feather'
import { useLocation } from 'react-router-dom'
import { useMount } from 'react-use'

import useAppDispatch from '../../../hooks/useAppDispatch'
import { useAppStateQuery } from '../../../hooks/useAppStateQuery'
import { useClientMetadata } from '../../../hooks/useClientMetadata'
import { useLatestCompassReportQuery } from '../../../hooks/useLatestCompassReportQuery'
import {
  CLIENT_DASHBOARD_SIDEBAR_LINKS,
  COMPASS_REPORT_STATUS,
} from '../../../lib/constants'
import { extractIdentifierFromIri } from '../../../lib/helpers/helperFunctions'
import { buildCompassActionsUrl } from '../../../lib/helpers/routeHelpers'
import { ROUTES } from '../../../lib/routes'
import CompassMetadataProvider from '../../../providers/CompassMetadataProvider'
import { LAYOUTS, setLayout } from '../../../redux/slices/layout'
import { ClientMetadataItem } from '../../../types/responses/client-metadata'
import Main from '../../misc/Main'
import SentryErrorBoundary from '../../misc/SentryErrorBoundary'
import LoadingWidget from '../../widgets/LoadingWidget'
import Footer from './components/Footer'
import Navbar from './components/Navbar/Navbar'
import ReviewPartnerModal from './components/ReviewPartnerModal'
import Sidebar, { SidebarPage } from './components/sidebar/Sidebar'

interface Props {
  children: React.ReactNode
}

const ClientDashboardLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation()
  const metadata = useClientMetadata() as ClientMetadataItem
  const dispatch = useAppDispatch()

  const latestCompassReportQuery = useLatestCompassReportQuery()

  useMount(() => {
    dispatch(setLayout(LAYOUTS.clientDashboard))
  })

  const appStateQuery = useAppStateQuery()

  if (
    latestCompassReportQuery.isLoading &&
    !latestCompassReportQuery.isFetched
  ) {
    return <LoadingWidget />
  }

  const latestCompassReport = latestCompassReportQuery.data

  function buildSidebarLinks(): SidebarPage[] {
    const fontAwesomeIconStyles: CSSProperties = {
      fontSize: '15px',
      position: 'relative',
    }

    const links: SidebarPage[] = [
      {
        title: CLIENT_DASHBOARD_SIDEBAR_LINKS.compassDashboard,
        href: ROUTES.compassDashboard,
        icon: <Layout />,
      },
    ]

    if (metadata.hasCompassReport) {
      links.push({
        title: CLIENT_DASHBOARD_SIDEBAR_LINKS.yourSnapshots,
        href: ROUTES.compassSnapshots,
        icon: <Compass />,
      })
    } else {
      links.push({
        title: CLIENT_DASHBOARD_SIDEBAR_LINKS.startCompass,
        href: ROUTES.compassStart,
        icon: <Compass />,
      })
    }

    if (metadata.latestCompletedCompassReport) {
      const token = extractIdentifierFromIri(
        metadata.latestCompletedCompassReport,
      )

      links.push({
        title: CLIENT_DASHBOARD_SIDEBAR_LINKS.actions,
        href: buildCompassActionsUrl(token),
        icon: <AlertCircle />,
      })
    }

    if (metadata.hasCompassReport && metadata.sponsorCompany?.hasPanel) {
      links.push({
        title:
          latestCompassReport?.status === COMPASS_REPORT_STATUS.COMPLETE
            ? CLIENT_DASHBOARD_SIDEBAR_LINKS.updateCompass
            : CLIENT_DASHBOARD_SIDEBAR_LINKS.continueCompass,
        href: ROUTES.compassUpdate,
        icon: <RefreshCcw />,
      })

      links.push({
        title: CLIENT_DASHBOARD_SIDEBAR_LINKS.ourPartners,
        href: ROUTES.compassPartners,
        icon: (
          <FontAwesomeIcon
            icon={faShieldCheck}
            style={{ ...fontAwesomeIconStyles, top: '1px' }}
          />
        ),
      })
    }

    links.push(
      {
        title: CLIENT_DASHBOARD_SIDEBAR_LINKS.sharing,
        href: ROUTES.compassSharing,
        icon: <Share2 />,
      },
      {
        title: CLIENT_DASHBOARD_SIDEBAR_LINKS.howItWorks,
        href: ROUTES.compassHowItWorks,
        icon: <HelpCircle />,
      },
    )

    return links
  }

  const sidebarLinks = buildSidebarLinks()

  if (appStateQuery.isLoading) {
    return <LoadingWidget />
  }

  return (
    <>
      <CompassMetadataProvider>
        <ReviewPartnerModal />
        <div className="wrapper">
          <Sidebar items={[{ pages: sidebarLinks }]} />

          <Main>
            <Navbar />
            <div className="content">
              <SentryErrorBoundary key={location.pathname}>
                <Suspense fallback={<LoadingWidget />}>{children}</Suspense>
              </SentryErrorBoundary>
            </div>
            <Footer />
          </Main>
        </div>
      </CompassMetadataProvider>
    </>
  )
}

export default ClientDashboardLayout
