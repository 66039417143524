import React from 'react'

import ReloadButton from './ReloadButton'

const OfflineMessage: React.FC = () => (
  <section className="position-relative">
    <h2>Oops.</h2>

    <ReloadButton />

    <p>
      It seems there's a problem with your network. Please check your internet
      connection and then try refreshing the page.
    </p>
  </section>
)

export default OfflineMessage
