import React from 'react'

import PlainButton from '../../PlainButton'

const NewUpdateAvailableMessage: React.FC = () => (
  <>
    <h2>New update detected</h2>

    <p className="mb-2">
      {/* The reload should happen in src/main.ts from the vite:preloadError
      event listener */}
      A new update has been detected. Reloading the page...
    </p>

    <p className="mb-0">
      If the page doesn't reload after a few seconds,{' '}
      <PlainButton onClick={() => window.location.reload()}>
        click here
      </PlainButton>
      .
    </p>
  </>
)

export default NewUpdateAvailableMessage
