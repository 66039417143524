import { useMutation } from '@tanstack/react-query'

import { CONSENT_DECISION, CONSENT_TYPE } from '../lib/constants'
import companyConsentService from '../lib/services/companyConsentService'
import { ConsentDecisionType } from '../types/misc'
import { useInvalidateCompanyConsentsQuery } from './useInvalidateCompanyConsentsQuery'
import useNotyf from './useNotyf'

export function useUpdateIcaewConsentMutation() {
  const notyf = useNotyf()
  const invalidateCompanyConsentsQuery = useInvalidateCompanyConsentsQuery()

  return useMutation({
    mutationFn: (consentDecision: ConsentDecisionType) =>
      companyConsentService.updateCompanyConsent({
        consentType: CONSENT_TYPE.ICAEW,
        decision: consentDecision,
      }),
    onSuccess: async (_data, consentDecision) => {
      await invalidateCompanyConsentsQuery()

      const message =
        consentDecision === CONSENT_DECISION.OPT_IN
          ? 'You have opted in. Your data will be shared with the ICAEW.'
          : 'You have opted out. Your data will not be shared with the ICAEW.'

      notyf.open({
        type: 'success',
        message,
        duration: 3000,
      })
    },
    onError: () => {
      notyf.error(
        'There was a problem processing your request. Please refresh the page and try again.',
      )
    },
  })
}
