import React from 'react'

const PaymentDetails: React.FC = () => (
  <>
    <span className="text-decoration-underline">Account Details</span> <br />
    RQ Ratings Ltd <br />
    Account number: 15425134 <br />
    Sort code: 04-06-05 <br />
  </>
)

export default PaymentDetails
