import classNames from 'classnames'
import React, { HTMLAttributes } from 'react'

type Props = HTMLAttributes<HTMLDivElement> & {
  isActive?: boolean
}

const FormGroup: React.FC<Props> = ({ className, ...props }) => (
  <div {...props} className={classNames('mb-3 mb-md-2', className)} />
)

export default FormGroup
