import classNames from 'classnames'
import React, { HTMLAttributes } from 'react'

type Props = HTMLAttributes<HTMLHeadingElement>

const PageTitle: React.FC<Props> = ({
  children,
  className,
  style = {},
  ...props
}) => (
  <h1
    className={classNames('h3 mb-3 text-black fw-bolder', className)}
    style={{ fontSize: '28px', lineHeight: 1.3, ...style }}
    {...props}
  >
    {children}
  </h1>
)

export default PageTitle
