import React from 'react'

import LoadingWidget from '../widgets/LoadingWidget'

const LoadingScreen: React.FC = () => (
  <div className="d-flex align-items-center justify-content-center h-100">
    <LoadingWidget />
  </div>
)

export default LoadingScreen
