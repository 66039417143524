import { ReferralFirmCollectionItem } from '../../../../../../types/responses/companies'

export type ReferralFirmTableItem = ReferralFirmCollectionItem & {
  disabledReason?: DisabledReason
}

export const DISABLED_REASON: Record<DisabledReason, DisabledReason> = {
  missingServiceAndUnapproved: 'missingServiceAndUnapproved',
  missingService: 'missingService',
  unapproved: 'unapproved',
  nonStandaloneServicesHaveFinancialPlanSelected:
    'nonStandaloneServicesHaveFinancialPlanSelected',
  fcaFirmRestrictedService: 'fcaFirmRestrictedService',
}

export type DisabledReason =
  | 'missingServiceAndUnapproved'
  | 'missingService'
  | 'unapproved'
  | 'nonStandaloneServicesHaveFinancialPlanSelected'
  | 'fcaFirmRestrictedService'
