import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import ActionButton from '../ActionButton'

interface Props {
  companyName: string
  onBooked: () => void
  onNoBooked: () => void
  onHide: () => void
  isProcessing: boolean
}

const DidYouBookTheMeetingModal: React.FC<Props> = ({
  companyName,
  onBooked,
  onNoBooked,
  onHide,
  isProcessing,
}) => {
  return (
    <Modal
      show
      onHide={onHide}
      centered
      contentClassName="border box-shadow mx-3"
    >
      <Modal.Header></Modal.Header>

      <Modal.Body>
        Did you book a meeting with <strong>{companyName}</strong>?
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onNoBooked}>
          No
        </Button>

        <ActionButton
          variant="success"
          isProcessing={isProcessing}
          isProcessingText="Creating referral"
          onClick={onBooked}
        >
          Yes
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

export default DidYouBookTheMeetingModal
