import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CONSENT_DECISION, CONSENT_TYPE } from '../../lib/constants'
import { ConsentType } from '../../types/misc'
import { CompanyConsentCollection } from '../../types/responses/companies'
import { RootState } from '../store'
import { selectCurrentCompany } from './session'

interface CompanyState {
  consentTypes: CompanyConsentCollection['hydra:member']
}

const INITIAL_STATE: CompanyState = {
  consentTypes: [],
}

export const company = createSlice({
  name: 'company',
  initialState: INITIAL_STATE,
  reducers: {
    setCompanyConsentTypes(
      state,
      action: PayloadAction<CompanyState['consentTypes']>,
    ) {
      state.consentTypes = action.payload
    },
  },
})

export const { setCompanyConsentTypes } = company.actions

export const selectCompany = (state: RootState) => state.company

export const selectCompanyConsentTypes = createSelector(
  selectCompany,
  (company) => company.consentTypes,
)

// TODO: This does the job for now, but we can delegate this type of logic to
// the API when we add more consent types.
export const selectHasRespondedToConsentPrompt = (consentType: ConsentType) =>
  createSelector(selectCompanyConsentTypes, (companyConsentTypes) => {
    return companyConsentTypes.some(
      (companyConsentType) => companyConsentType.consentType === consentType,
    )
  })

// TODO: Move this logic to a computed field from an API endpoint
export const selectShouldShowIcaewConsentPrompt = createSelector(
  selectCurrentCompany,
  selectHasRespondedToConsentPrompt(CONSENT_TYPE.ICAEW),
  (currentCompany, hasRespondedToConsentPrompt) => {
    return (
      !!currentCompany &&
      currentCompany.isRegulatedByIcaew &&
      !hasRespondedToConsentPrompt
    )
  },
)

export const selectConsentTypeDecision = (consentType: ConsentType) =>
  createSelector(selectCompanyConsentTypes, (companyConsentTypes) => {
    const companyConsent = companyConsentTypes.find(
      (companyConsentType) => companyConsentType.consentType === consentType,
    )

    return companyConsent ? companyConsent.decision : undefined
  })

export const selectHasOptedInToConsentType = (consentType: ConsentType) =>
  createSelector(selectConsentTypeDecision(consentType), (consentDecision) => {
    return consentDecision === CONSENT_DECISION.OPT_IN
  })

export const companyReducer = company.reducer
