/**
 * Helper functions for working with the Office.js API.
 *
 * Do not use Office imports - the library is loaded by external script
 */

import { LOCAL_STORAGE_KEYS } from '../constants'
import { ROUTES } from '../routes'
import authService from '../services/authService'

export function isOfficeAddIn() {
  return (
    window.location.pathname === ROUTES.officeTaskPane ||
    isEmbeddedAsIFrameInOutlook() ||
    isOfficeDialog()
  )
}

function isEmbeddedAsIFrameInOutlook() {
  return (
    window.self !== window.top &&
    (document.referrer.includes('outlook') || // For when the task pane is initially shown in Outlook
      document.referrer.includes(ROUTES.officeTaskPane)) // For when we're redirected to the task pane after login
  )
}

function isOfficeDialog() {
  try {
    return (
      (window.opener && 'Office' in window.opener) ||
      // Can't rely on window.opener for WebView2 (Microsoft Edge2 Chromium-based)
      window.Microsoft?.Office !== undefined
    )
  } catch (_error) {
    // To handle cross-origin security issue (https://tinyurl.com/cross-origin-error)
    return false
  }
}

export function isOffice(): boolean {
  return (
    typeof Office !== 'undefined' &&
    typeof Office.context !== 'undefined' &&
    typeof Office.context.host !== 'undefined'
  )
}

export function isOfficeUI(): boolean {
  return isOffice() && typeof Office.context.ui !== 'undefined'
}

export function isOfficeParent(): boolean {
  return (
    isOffice() &&
    isOfficeUI() &&
    typeof Office.context.ui.displayDialogAsync !== 'undefined'
  )
}

export function isHostOutlookApplication(): boolean {
  return Office.context.host === Office.HostType.Outlook
}

export function isOfficeWeb(): boolean {
  return Office.context.platform === Office.PlatformType.OfficeOnline
}

export function openOfficeDialog(
  path: string,
  callback?: true | ((asyncResult: Office.AsyncResult<Office.Dialog>) => void),
  options?: Office.DialogOptions,
) {
  const url = window.location.origin + path

  if (!isOfficeUI()) {
    console.error('Office.context.ui is not available, redirecting instead.')
    return window.location.assign(url)
  }

  if (!Office.context.ui.displayDialogAsync) {
    console.error('Office.context.ui.displayDialogAsync is not available.')
    return
  }

  if (callback === true) {
    callback = (asyncResult) => defaultDialog(asyncResult)
  } else if (callback === undefined) {
    callback = () => {
      return
    }
  }

  Office.context.ui.displayDialogAsync(
    url,
    {
      ...options,
      width: widthPixelsToPercentage(options?.width || 1250),
      height: heightPixelsToPercentage(options?.height || 1100),
      promptBeforeOpen: false,
    },
    callback,
  )
}

// Office.js doesn't support pixel widths so we need to convert to percentage
function widthPixelsToPercentage(width: number) {
  return Math.floor((width / window.screen.availWidth) * 100)
}

// Office.js doesn't support pixel heights so we need to convert to percentage
function heightPixelsToPercentage(height: number) {
  return Math.floor((height / window.screen.availHeight) * 100)
}

export function defaultDialog(asyncResult: Office.AsyncResult<Office.Dialog>) {
  const dialog = asyncResult.value
  dialog.addEventHandler(Office.EventType.DialogMessageReceived, async () => {
    dialog.close()
  })
}

export function setOfficeTokens(
  accessToken: string | null,
  refreshToken: string | null,
) {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.officeWebAccessToken)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.officeWebRefreshToken)

  if (isOfficeParent()) {
    // To limit the use of localstorage tokens we only allow this for web version of office add-in which can't read iframe cookies from auth.rqratings
    if (accessToken) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.officeWebAccessToken,
        accessToken,
      )
    }

    if (refreshToken) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.officeWebRefreshToken,
        refreshToken,
      )
    }
  }
}

export function getOfficeTokens() {
  let accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.officeWebAccessToken,
  )
  let refreshToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.officeWebRefreshToken,
  )

  if (accessToken === null || authService.isTokenExpired(accessToken)) {
    accessToken = null
  }

  if (refreshToken === null || authService.isTokenExpired(refreshToken)) {
    refreshToken = null
  }

  setOfficeTokens(accessToken, refreshToken)

  return {
    accessToken: accessToken || undefined,
    refreshToken: refreshToken || undefined,
  }
}
