import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Settings } from 'react-feather'

import { useAuth } from '../../../../../hooks/useAuth'
import AccountDropdown from './AccountDropdown'
import GuestMenu from './GuestMenu'
import IcaewAccountDropdown from './IcaewAccountDropdown'

const NavbarMenu: React.FC = () => {
  const { isAuthenticated, isInitialized, isIcaewUser } = useAuth()

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>

      {isInitialized &&
        (isAuthenticated ? (
          isIcaewUser ? (
            <IcaewAccountDropdown />
          ) : (
            <AccountDropdown />
          )
        ) : (
          <GuestMenu />
        ))}
    </Dropdown>
  )
}

export default NavbarMenu
