import { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'

import { ConstraintViolationListError } from '../../types/api'
import { LOCAL_STORAGE_KEYS } from '../constants'
import { isConstraintViolationListError } from '../helpers/helperFunctions'

class ErrorTypeService {
  isApiError(error: unknown): error is AxiosError<{
    'hydra:title': string
    'hydra:description': string
  }> {
    if (!this.isAxiosError(error)) {
      return false
    }

    const responseData = error.response?.data

    return !!(
      responseData?.hasOwnProperty('hydra:title') &&
      responseData?.hasOwnProperty('hydra:description')
    )
  }

  isForbiddenError(error: unknown): boolean {
    return (
      this.isAxiosError(error) &&
      error.response?.status === StatusCodes.FORBIDDEN
    )
  }

  isBadRequestError(error: unknown): error is AxiosError {
    return (
      this.isAxiosError(error) &&
      error.response?.status === StatusCodes.BAD_REQUEST
    )
  }

  isUnprocessibleEntityError(error: unknown): error is AxiosError {
    return (
      this.isAxiosError(error) &&
      error.response?.status === StatusCodes.UNPROCESSABLE_ENTITY
    )
  }

  is404Error(error: unknown): boolean {
    return (
      this.isAxiosError(error) &&
      error.response?.status === StatusCodes.NOT_FOUND
    )
  }

  isAxiosError(error: unknown): error is AxiosError {
    return error instanceof AxiosError
  }

  isTokenError(error: unknown): boolean {
    if (!this.isApiError(error)) {
      return false
    }

    const statusCode = error?.response?.status
    const errorDescription = error.response?.data?.['hydra:description']

    return (
      statusCode === StatusCodes.UNAUTHORIZED &&
      (errorDescription === 'Invalid token' ||
        errorDescription === 'Expired token')
    )
  }

  isExpiredContextError(error: unknown): boolean {
    return (
      this.isInvalidUserContextException(error) &&
      this.areRequestAndResponseCommitHashesDifferent(error)
    )
  }

  isConstraintViolationListError(
    error: unknown,
  ): error is ConstraintViolationListError {
    return isConstraintViolationListError(error)
  }

  private isInvalidUserContextException(error: unknown) {
    if (!this.isApiError(error)) {
      return false
    }

    return (
      error.response?.status === StatusCodes.BAD_REQUEST &&
      error.response.data['hydra:description'].includes(
        'Make sure the user context is set',
      ) // TODO: Would be nice if we could check an error code instead of the error description
    )
  }

  private areRequestAndResponseCommitHashesDifferent(error: unknown) {
    if (!this.isApiError(error)) {
      return false
    }

    const previousRequestCommitHash = localStorage.getItem(
      LOCAL_STORAGE_KEYS.apiCommitHash,
    )

    const responseCommitHash = error.response?.headers['x-commit-hash']

    return (
      !!responseCommitHash &&
      !!previousRequestCommitHash &&
      responseCommitHash !== previousRequestCommitHash
    )
  }
}

const errorTypeService = new ErrorTypeService()

export default errorTypeService
