// Install Outlook using `brew install --cask microsoft-outlook` to get
// the inspector tools

import React, { useEffect, useState } from 'react'

import LoadingWidget from '../components/widgets/LoadingWidget'
import {
  IItemState,
  IMailboxState,
  IOfficeContext,
  OfficeContext,
} from '../contexts/OfficeContext'
import useLayout from '../hooks/useLayout'
import useScript from '../hooks/useScript'
import useSidebar from '../hooks/useSidebar'
import {
  isOffice,
  isOfficeAddIn,
  isOfficeParent,
} from '../lib/helpers/officeHelpers'

interface Props {
  children: React.ReactNode
}

const OfficeProvider: React.FC<Props> = ({ children }) => {
  return isOfficeAddIn() ? (
    <OfficeProviderComponent>{children}</OfficeProviderComponent>
  ) : (
    children
  )
}

const OfficeProviderComponent: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<IOfficeContext>({} as IOfficeContext)
  const { setIsOpen } = useSidebar()
  const { setLayout } = useLayout()
  const [officeJs] = useScript(
    'https://appsforoffice.microsoft.com/lib/1/hosted/office.js',
  )

  useEffect(() => {
    if (!officeJs) {
      return
    }

    // This is far from ideal but at least stops these function calls throwing
    // an error when invoked inside the Office context
    window.confirm = () => true
    window.alert = () => true
    window.prompt = () => null

    const asyncHandler = async () => {
      // Fix issue with Office.js nullifying Web APIs: https://tinyurl.com/y59sv3zk
      // @ts-expect-error ---
      delete history.pushState
      // @ts-expect-error ---
      delete history.replaceState

      // We'll call this function to read the current office state
      // from mailbox item.
      const update = () => {
        // Unpack the office context.
        const mailbox = Office.context.mailbox
        const item = mailbox?.item

        const getItemState = (): IItemState | undefined => {
          if (!item) return undefined
          const {
            from,
            to,
            internetMessageId,
            conversationId,
            itemType,
            cc,
            body,
          } = item
          return {
            from,
            to,
            cc,
            internetMessageId,
            conversationId,
            itemType,
            body,
          }
        }

        const getMailboxState = (): IMailboxState | undefined => {
          if (!mailbox) return undefined

          const { userProfile } = mailbox

          return {
            userProfile,
            item: getItemState(),
          }
        }

        setState({
          state: {
            mailbox: getMailboxState(),
          },
          isInitialized: true,
        })
      }

      // Update our application, now that Office is initialized.
      update()

      // Update context after changing email
      if (isOfficeParent()) {
        Office.context.mailbox.addHandlerAsync(
          Office.EventType.ItemChanged,
          update,
        )
      }

      if (isOffice()) {
        setIsOpen(false)
      }
    }
    Office.onReady(() => {
      asyncHandler()
    })
  }, [officeJs, setLayout, setIsOpen])

  return state.isInitialized ? (
    <OfficeContext.Provider value={state}>{children}</OfficeContext.Provider>
  ) : (
    <LoadingWidget />
  )
}

export default OfficeProvider
