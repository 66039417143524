import React from 'react'
import { Link } from 'react-router-dom'

/**
 * When (if) adding icons here, they should:
 * a. Be an SVG
 * b. Contain 3 elements (or sets of elements, with classes 'front', 'middle' and 'back')
 * c. Look good both as outline and with 3 fill colours
 */
const InviteTypeButtonIcons = {
  groupOfPeople: (
    <svg
      className="group-of-people-icon"
      aria-hidden="true"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="back"
        d="M8.31116 20.6363C8.31779 20.4409 8.20994 20.2596 8.03512 20.1721C8.03499 20.1721 8.03486 20.172 8.03473 20.1719L8.03066 20.1698L8.00541 20.1564C7.98184 20.1437 7.94514 20.1233 7.89718 20.0951C7.80119 20.0386 7.66049 19.9509 7.48994 19.8303C7.14828 19.5886 6.69025 19.217 6.23216 18.7021C5.31974 17.6764 4.40572 16.0825 4.40572 13.7879C4.40572 9.21061 8.11633 5.5 12.6936 5.5C17.2709 5.5 20.9815 9.21061 20.9815 13.7879C20.9815 16.0825 20.0675 17.6764 19.1551 18.7021C18.697 19.217 18.2389 19.5886 17.8973 19.8303C17.7267 19.9509 17.586 20.0386 17.49 20.0951C17.4421 20.1233 17.4054 20.1437 17.3818 20.1564L17.3566 20.1698L17.3525 20.1719L17.3521 20.1721C17.352 20.1722 17.3519 20.1722 17.3518 20.1723C17.3517 20.1723 17.3516 20.1724 17.3515 20.1724C17.177 20.26 17.0694 20.4411 17.076 20.6363C17.0827 20.8318 17.2027 21.0055 17.3833 21.0808C21.7908 22.9188 24.8872 27.2684 24.8872 32.3401V36.7222H0.5V32.3401C0.5 27.2684 3.59643 22.9188 8.00389 21.0808C8.18446 21.0055 8.30453 20.8318 8.31116 20.6363Z"
        strokeLinejoin="round"
      />
      <path
        className="back"
        d="M8.29966 20.6229C8.29966 20.6229 9.70687 21.5993 12.4639 21.5993C15.2208 21.5993 17.0875 20.6229 17.0875 20.6229"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        className="middle"
        d="M32.7556 20.6363C32.7622 20.4409 32.6544 20.2596 32.4796 20.1721C32.4794 20.1721 32.4793 20.172 32.4792 20.1719L32.4751 20.1698L32.4499 20.1564C32.4263 20.1437 32.3896 20.1233 32.3416 20.0951C32.2456 20.0386 32.1049 19.9509 31.9344 19.8303C31.5927 19.5886 31.1347 19.217 30.6766 18.7021C29.7642 17.6764 28.8502 16.0825 28.8502 13.7879C28.8502 9.21061 32.5608 5.5 37.138 5.5C41.7153 5.5 45.4259 9.21061 45.4259 13.7879C45.4259 16.0825 44.5119 17.6764 43.5995 18.7021C43.1414 19.217 42.6834 19.5886 42.3417 19.8303C42.1712 19.9509 42.0305 20.0386 41.9345 20.0951C41.8865 20.1233 41.8498 20.1437 41.8262 20.1564L41.801 20.1698L41.7969 20.1719L41.7965 20.1721C41.7964 20.1722 41.7963 20.1722 41.7962 20.1723C41.7961 20.1723 41.796 20.1724 41.7959 20.1724C41.6215 20.26 41.5139 20.4411 41.5205 20.6363C41.5271 20.8318 41.6472 21.0055 41.8278 21.0808C46.2352 22.9188 49.3316 27.2684 49.3316 32.3401V36.7222H24.9444V32.3401C24.9444 27.2684 28.0409 22.9188 32.4483 21.0808C32.6289 21.0055 32.749 20.8318 32.7556 20.6363Z"
        strokeLinejoin="round"
      />
      <path
        className="middle"
        d="M32.7441 20.6229C32.7441 20.6229 34.1513 21.5993 36.9083 21.5993C39.6653 21.5993 41.532 20.6229 41.532 20.6229"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        className="front"
        d="M20.4997 26.124C20.5063 25.9286 20.3985 25.7473 20.2237 25.6598L20.2236 25.6598L20.2233 25.6597L20.2177 25.6568C20.2117 25.6536 20.2017 25.6484 20.1879 25.641C20.1604 25.6261 20.1179 25.6025 20.0626 25.5699C19.9519 25.5048 19.7901 25.404 19.5943 25.2655C19.2022 24.988 18.6768 24.5618 18.1513 23.9712C17.1043 22.7942 16.0556 20.9647 16.0556 18.3333C16.0556 13.0866 20.3088 8.83333 25.5556 8.83333C30.8023 8.83333 35.0556 13.0866 35.0556 18.3333C35.0556 20.9647 34.0069 22.7942 32.9598 23.9712C32.4343 24.5618 31.909 24.988 31.5168 25.2655C31.321 25.404 31.1592 25.5048 31.0485 25.5699C30.9932 25.6025 30.9507 25.6261 30.9232 25.641C30.917 25.6443 30.9116 25.6472 30.9069 25.6497C30.9012 25.6527 30.8967 25.655 30.8934 25.6568L30.8878 25.6597L30.8875 25.6598L30.8874 25.6598C30.8873 25.6599 30.8872 25.66 30.887 25.6601C30.7124 25.7476 30.6048 25.9288 30.6114 26.124C30.618 26.3195 30.7381 26.4932 30.9187 26.5685C35.9588 28.6704 39.5 33.6444 39.5 39.4444V44.5H11.6111V39.4444C11.6111 33.6444 15.1523 28.6704 20.1924 26.5685C20.373 26.4932 20.4931 26.3195 20.4997 26.124Z"
        strokeLinejoin="round"
      />
      <path
        className="front"
        d="M20.5556 26.1111C20.5556 26.1111 22.1569 27.2222 25.2941 27.2222C28.4314 27.2222 30.5556 26.1111 30.5556 26.1111"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
    </svg>
  ),
  barChart: (
    <svg
      className="bar-chart-icon"
      aria-hidden="true"
      width="47"
      height="44"
      viewBox="0 0 47 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="back"
        d="M0 23.7566C0 21.5373 1.82979 19.7383 4.08696 19.7383H23.9087V43.9325H4.08696C1.82979 43.9325 0 42.1333 0 39.9143V23.7566Z"
      />
      <path
        className="middle"
        d="M11.5586 4.03084C11.5515 1.80672 13.3834 1.69613e-05 15.6455 1.48443e-05L31.5073 0C33.7645 -2.1123e-06 35.5943 1.79904 35.5943 4.01826V39.9466C35.5943 42.1657 33.7645 43.9649 31.5073 43.9649H11.6856L11.5586 4.03084Z"
      />
      <path
        className="front"
        d="M23.1836 13.4323C23.1836 11.2131 25.0133 9.41406 27.2706 9.41406H42.9115C45.1688 9.41406 46.9985 11.2131 46.9985 13.4323V39.8725C46.9985 42.0918 45.1688 43.8908 42.9115 43.8908H23.1836V13.4323Z"
      />
    </svg>
  ),
}

interface Props {
  iconName: keyof typeof InviteTypeButtonIcons
  text: string
  onClick: () => void
  /**
   * Passing a value for `href` will result in an anchor tag, rather than a button
   */
  href?: string
  className?: string
}

const InviteTypeButton: React.FC<Props> = ({
  iconName,
  text,
  onClick,
  href,
  className,
}) => {
  const buttonClasses =
    className +
    ' invite-type-button px-4 py-5 rounded-3 col d-flex flex-column align-items-center gap-2'

  function renderContents(): JSX.Element {
    return (
      <>
        {InviteTypeButtonIcons[iconName]}

        <span className="text-wrap-balance">{text}</span>
      </>
    )
  }

  if (href !== undefined) {
    return (
      <Link
        className={buttonClasses}
        to={href}
        onClick={onClick !== undefined ? (): void => onClick() : undefined}
      >
        {renderContents()}
      </Link>
    )
  }
  return (
    <button
      type="button"
      className={buttonClasses}
      onClick={onClick !== undefined ? (): void => onClick() : undefined}
    >
      {renderContents()}
    </button>
  )
}

export default InviteTypeButton
