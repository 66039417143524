import { InvitationEmail } from '../../../../pages/inviteToRq/types'

export const ToAccountantEmail: InvitationEmail = {
  subject: 'Collaborating with your firm',
  renderText: (invitationLink: string) => {
    return (
      <>
        <p>Hi [Accountant],</p>
        <p>
          We have just signed up to the collaboration platform RQ, so that we
          can work more closely with your firm. From now on, we would like to
          use RQ to manage our collaboration with your firm (you can use it for
          all the other professionals you introduce clients to, not just us).
        </p>

        <p>Based on feedback from other accountants, RQ will:</p>

        <p>
          <strong>1) Enhance your advisory offering</strong>
        </p>
        <p>
          Through RQ’s financial planning discovery tool, you will gain a deeper
          understanding of your clients’ wider goals and surface opportunities
          to help in an expanded range of areas.
        </p>

        <p>
          <strong>2) Ensure Compliance</strong>
        </p>
        <p>
          Ensure you are fulfilling your regulatory obligations when
          introducing, sharing revenue and collaborating with other
          professionals, as well as lowering the administrative burden
          associated with DPB compliance.
        </p>

        <p>
          <strong>3) Streamline data-sharing</strong>
        </p>
        <p>
          Streamline data-sharing between our firms, saving us both time and
          effort on the administrative side and giving us both an up-to-date
          view of a client's journey. This will give our joint clients a more
          seamless experience.
        </p>

        <p>
          <strong>What to do</strong>
        </p>
        <p>
          Please follow this link to set up your account on the RQ
          platform:&nbsp;
          <a href={invitationLink}>{invitationLink}</a>.
          <br />
          <em>
            Note: use this link to sign up and the RQ platform is free for your
            firm to use.
          </em>
        </p>

        <p>
          Alternatively, let me know a time that works for your firm and we can
          coordinate a three way call with the RQ team where they can answer any
          questions you may have. If easier, you can book a meeting directly
          with the&nbsp;
          <a href="https://meetings-eu1.hubspot.com/johnny-ridd/rq-demo">
            RQ team here
          </a>
          .
        </p>

        <p>
          RQ is free for your firm to use and will allow you to invite all
          professionals you collaborate with – accountants, lawyers, mortgage
          advisers and others - to join you on RQ. This will ensure you are
          front of mind and bring your circle of trusted professionals even
          closer together.
        </p>

        <p>
          Yours,
          <br />
          [Your name]
        </p>
      </>
    )
  },
}
