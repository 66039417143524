import { useEffect, useState } from 'react'

// Inserts a script tag just before the closing body tag to load an
// external script.
// Remember to update the Content-Security-Policy in index.html too with any external scripts
const useScript = (scriptSrc: string, hash?: string) => {
  const [script, setScript] = useState<null | HTMLScriptElement>(null)
  const [error, setError] = useState<null | Error>(null)

  useEffect(() => {
    const scriptElement = document.createElement('script')
    scriptElement.src = scriptSrc
    scriptElement.async = true
    scriptElement.crossOrigin = 'anonymous'
    scriptElement.referrerPolicy = 'no-referrer'
    if (hash) {
      scriptElement.integrity = hash
    }

    scriptElement.onload = () => setScript(scriptElement)
    scriptElement.onerror = () =>
      setError(new Error(`Failed to load ${scriptSrc}`))

    document.body.appendChild(scriptElement)

    return () => {
      document.body.removeChild(scriptElement)
    }
  }, [scriptSrc, hash])

  return [script, error]
}

export default useScript
