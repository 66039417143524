import { useMutation } from '@tanstack/react-query'

import clientService from '../lib/services/clientService'
import { setClientMetadata } from '../redux/slices/clientMetadata'
import useAppDispatch from './useAppDispatch'

export function useCreateClientMetadataMutation() {
  const dispatch = useAppDispatch()

  return useMutation({
    mutationFn: () => clientService.createClientMetadata(),
    onSuccess: (metadata) => {
      dispatch(setClientMetadata(metadata))
    },
  })
}
