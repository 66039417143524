import classNames from 'classnames'
import React from 'react'
import { Card } from 'react-bootstrap'

import useMinWidth from '../../hooks/useMinWidth'
import Main from '../misc/Main'
import MinimalLayout from './MinimalLayout'

interface Props {
  children: React.ReactNode
  variant?: 'card' | 'default'
}

const LandingGreyLayout: React.FC<Props> = ({ children, variant }) => {
  const isMinWidthSm = useMinWidth('sm')
  const isSmallDevice = !isMinWidthSm

  function render() {
    if (variant === 'card' && !isSmallDevice) {
      return (
        <div className="w-100 mt-4 px-3">
          <div className="mx-auto d-flex flex-column align-items-center">
            <Card className="border-box w-100" style={{ maxWidth: '600px' }}>
              {children}
            </Card>
          </div>
        </div>
      )
    }

    return <div style={{ padding: '0 13px' }}>{children}</div>
  }

  return (
    <div className={classNames({ 'bg-white': isSmallDevice })}>
      <MinimalLayout isDarkMode={false}>
        <Main>{render()}</Main>
      </MinimalLayout>
    </div>
  )
}

export default LandingGreyLayout
