import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CompassPdfGuideDataItem } from '../../types/responses/companies'
import { RootState } from '../store'

export interface CompassPdfGuideDataState {
  data?: CompassPdfGuideDataItem
}

const INITIAL_STATE: CompassPdfGuideDataState = {}

export const compassPdfGuide = createSlice({
  name: 'compassPdfGuide',
  initialState: INITIAL_STATE,
  reducers: {
    setCompassPdfGuideData: (
      state,
      action: PayloadAction<CompassPdfGuideDataItem>,
    ) => {
      state.data = action.payload
    },
  },
})

const selectCompassPdfGuide = (state: RootState) => state.compassPdfGuide

export const selectCompassPdfGuideData = createSelector(
  selectCompassPdfGuide,
  (compassPdfGuide) => compassPdfGuide.data,
)

export const { setCompassPdfGuideData } = compassPdfGuide.actions

export const compassPdfGuideReducer = compassPdfGuide.reducer
