import { CreateOrUpdateFcaServiceRequest } from '../../types/requests/fca-services'
import apiService from './apiService'

class FcaServiceService {
  private endpoint = '/v1/fca-services'

  public async createFcaService(request: CreateOrUpdateFcaServiceRequest) {
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }

  async updateFcaService(
    fcaServiceId: number,
    request: CreateOrUpdateFcaServiceRequest,
  ) {
    const response = await apiService.patch(
      `${this.endpoint}/${fcaServiceId}`,
      request,
    )

    return response.data
  }
}

const fcaServiceService = new FcaServiceService()

export default fcaServiceService
