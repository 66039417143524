import { FromAccountantEmails } from '../../components/misc/PanelInviteEmailTemplate/email-templates/FromAccountantEmails'
import { FromIfaEmails } from '../../components/misc/PanelInviteEmailTemplate/email-templates/FromIfaEmails'
import { FromInsuranceAdviserEmails } from '../../components/misc/PanelInviteEmailTemplate/email-templates/FromInsuranceAdviserEmails'
import { FromMortgageAdviserEmails } from '../../components/misc/PanelInviteEmailTemplate/email-templates/FromMortgageAdviserEmails'
import { FromSolicitorEmails } from '../../components/misc/PanelInviteEmailTemplate/email-templates/FromSolicitorEmails'
import { GenericEmail } from '../../components/misc/PanelInviteEmailTemplate/email-templates/GenericEmail'
import { ToAccountantEmail } from '../../components/misc/PanelInviteEmailTemplate/email-templates/ToAccountantEmail'
import { COMPANY_TYPE_CODE } from '../../lib/constants'
import { CompanyTypeCode } from '../../types/misc'
import { InvitationEmail, InvitationEmails } from './types'

export function getInvitationEmailTemplate(
  fromCompanyCode: CompanyTypeCode,
  toCompanyCode: CompanyTypeCode,
): InvitationEmail {
  if (COMPANY_TYPE_CODE.accountant === toCompanyCode) {
    return ToAccountantEmail
  }

  const emailTemplates: Record<string, InvitationEmails> = {
    accountant: FromAccountantEmails,
    solicitor: FromSolicitorEmails,
    ifa: FromIfaEmails,
    insurance_adviser: FromInsuranceAdviserEmails,
    mortgage_adviser: FromMortgageAdviserEmails,
  }

  return emailTemplates[fromCompanyCode]?.[toCompanyCode] ?? GenericEmail
}

export function getCompanyTypePlaceholder(
  companyTypeCode: CompanyTypeCode,
): string {
  return companyTypeCode.replaceAll('_', ' ')
}

export function filterText(text: string): string {
  text = text.replace('<>', '')
  text = text.replaceAll("{' '}", '')
  return text
}

export function stripTags(text: string): string {
  text = text.replace(/[\s]*(<[/]?b>[\s]*)/gim, ' ')
  text = text.replace(/(<([^>]+)>)/gi, '')
  text = text.replace(/^[\s]+/g, '')
  text = text.replace(/^[ ]+|[ ]]+$/gm, '')
  text = text.replace(/[\n\r]{4,}/g, '\n')

  return text
}
