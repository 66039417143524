import { ERROR_MESSAGES } from '../lib/constants'
import useNotyf from './useNotyf'

export function useShowGenericServerError() {
  const notyf = useNotyf()

  return function () {
    notyf.error(ERROR_MESSAGES.genericServerError)
  }
}
