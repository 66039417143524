import { useLocation } from 'react-router-dom'

import { PDF_PATHS } from '../lib/constants'
import { ROUTES } from '../lib/routes'
import { useMatchesPath } from './useMatchesPath'

export function useIsPdf() {
  const location = useLocation()

  return (
    useMatchesPath(PDF_PATHS) &&
    location.pathname !== ROUTES.compassPdfReportPreview
  )
}
