import { CompanySearchUIFilters } from './types'

export type PanelInviteStep =
  | 'companySearch'
  | 'commercialAgreements'
  | 'inviteNewFirm'
  | 'invitationSummary'

export const PANEL_INVITE_STEPS: Record<PanelInviteStep, PanelInviteStep> = {
  companySearch: 'companySearch',
  commercialAgreements: 'commercialAgreements',
  inviteNewFirm: 'inviteNewFirm',
  invitationSummary: 'invitationSummary',
}

export const INITIAL_COMPANY_SEARCH_FILTERS: CompanySearchUIFilters = {
  isRqRated: 0,
  name: '',
  address: '',
  lat: null,
  lon: null,
  distance: 10,
  frns: [],
  areasOfAdvices: [],
  specialisms: [],
  accreditations: [],
  specialistQualifications: [],
  minInvestableAssets: null,
  isTransactionalWork: null,
  companyTypeId: 1,
  pageSize: 30,
  page: 1,
}
