import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import { Form } from 'react-bootstrap'

import FieldError from './FieldError'
import FieldLabel from './FieldLabel'
import TextInput from './TextInput'

interface Props {
  name: string
  label?: string | React.ReactNode
  onChange?: (newSelection: string[]) => void
  options: MultiSelectCheckboxOption[]
  compact?: boolean
  otherOption?: {
    name: string
    label: string
    placeholder?: string
    onChange?: (newValue: string) => void
  }
  noneOption?: {
    name: string
    label: string
    onChange: (isChecked: boolean) => void
  }
}

export type MultiSelectCheckboxOption = {
  label: string
  value: string
  isOtherOption?: boolean
}

const MultiCheckboxInput: React.FC<Props> = ({
  name,
  label,
  onChange,
  options,
  otherOption,
  noneOption,
  compact = false,
}) => {
  const { setFieldValue, submitCount, getFieldMeta, getFieldHelpers } =
    useFormikContext()
  const [field, { error }] = useField<string[]>(name)
  const showError = !!(submitCount > 0 && error)
  const id = `field_${name}`

  const handleChange = (
    option: MultiSelectCheckboxOption,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked
    const previouslyCheckedValues = field.value
    const newlyCheckedValues = isChecked
      ? [...previouslyCheckedValues, option.value]
      : previouslyCheckedValues.filter((value) => value !== option.value)

    setFieldValue(name, newlyCheckedValues)

    if (onChange) {
      onChange(newlyCheckedValues)
    }
  }

  const renderOptions = () => {
    return options.map((option) => {
      const id = `field_${name}_${option.value}`
      const isChecked = field.value.includes(option.value)
      const { isOtherOption } = option
      const optionLabel = isOtherOption ? otherOption?.label : option.label

      return (
        <OptionWrapper key={option.value} compact={compact}>
          <div className="d-flex">
            <Form.Check
              id={id}
              name={name}
              checked={isChecked}
              onChange={(event) => handleChange(option, event)}
              className="me-2"
            />

            {optionLabel && (
              <FieldLabel className="mb-0" htmlFor={id}>
                {optionLabel}
              </FieldLabel>
            )}
          </div>

          {otherOption && isOtherOption && isChecked && (
            <div className="mt-2">
              <TextInput
                name={otherOption.name}
                placeholder={otherOption.placeholder}
                autoFocus={(value) =>
                  typeof value === 'undefined' || value === ''
                }
                onChange={otherOption.onChange}
              />
            </div>
          )}
        </OptionWrapper>
      )
    })
  }

  const renderNoneOption = () => {
    if (!noneOption) {
      return null
    }

    const field = getFieldMeta<boolean>(noneOption.name)
    const helpers = getFieldHelpers(noneOption.name)
    const isChecked = field.value

    const id = `field_${noneOption.name}_none`

    return (
      <OptionWrapper
        className={classNames('border-bottom-0')}
        compact={compact}
      >
        <div className="d-flex">
          <Form.Check
            id={id}
            name={name}
            value={field.value ? '1' : '0'}
            defaultChecked={isChecked}
            className="me-2"
            onChange={(event) => {
              const newValue = event.target.checked

              helpers.setValue(newValue)
              noneOption.onChange(newValue)
            }}
          />
          {noneOption.label && (
            <FieldLabel htmlFor={id} className="mb-0">
              {noneOption.label}
            </FieldLabel>
          )}
        </div>
      </OptionWrapper>
    )
  }

  return (
    <>
      {label && <FieldLabel>{label}</FieldLabel>}
      <div className={classNames(compact ? 'd-flex flex-row gap-4' : '')}>
        {renderOptions()}
      </div>
      {noneOption && renderNoneOption()}
      {showError && <FieldError inputId={id}>{error}</FieldError>}
    </>
  )
}

const OptionWrapper: React.FC<{
  children: React.ReactNode
  className?: string
  compact?: boolean
}> = ({ children, className, compact }) => (
  <div
    className={classNames(compact ? 'py-1' : 'py-3 border-bottom', className)}
    style={{ borderColor: '#D1D4D7' }}
  >
    {children}
  </div>
)

export default MultiCheckboxInput
