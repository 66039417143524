import { CompanyTypeCode } from '../../types/misc'
import { COMPANY_TYPE_CODE } from '../constants'

class InviteToRqService {
  shouldShowDiscountNote(companyTypeCode: CompanyTypeCode): boolean {
    return ![
      COMPANY_TYPE_CODE.ifa,
      COMPANY_TYPE_CODE.mortgage_adviser,
      COMPANY_TYPE_CODE.insurance_adviser,
    ].includes(companyTypeCode)
  }
}

const inviteToRqService = new InviteToRqService()

export default inviteToRqService
