import { InvitationEmail } from '../../../../pages/inviteToRq/types'

export const GenericEmail: InvitationEmail = {
  subject: 'Collaborating with your firm',
  renderText: (invitationLink: string) => (
    <>
      <p>Hi [Name],</p>

      <p>
        We have just signed up to the professional collaboration platform RQ so
        that we can work more closely with your firm. From now on we would like
        to use RQ to manage our collaboration with you.
      </p>

      <p>
        <b>What to do</b>
      </p>
      <p>
        Please follow this link to set up your account on the RQ platform:&nbsp;
        <a href={invitationLink}>{invitationLink}</a>
      </p>

      <p>
        You can use RQ with as many professional connections as you collaborate
        with – lawyers, financial planners, accountants and others. RQ will help
        ensure you are front of mind and bring your circle of trusted
        professionals even closer together.
      </p>

      <p>
        Yours,
        <br />
        [Your name]
      </p>
    </>
  ),
}
