import classNames from 'classnames'
import React from 'react'

import LoadingWidget from './LoadingWidget'

export interface LoadingOverlayProps {
  isActive: boolean
  children: React.ReactNode
  className?: string
  showSpinner?: boolean
  spinnerPosition?: 'top' | 'center'
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isActive,
  children,
  className,
  showSpinner = true,
  spinnerPosition = 'center',
}) => {
  function renderSpinner() {
    if (spinnerPosition === 'center') {
      return (
        <div
          className="position-absolute w-100"
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <div className="d-flex justify-content-center">
            <LoadingWidget />
          </div>
        </div>
      )
    }

    return <LoadingWidget />
  }

  return (
    <div className={classNames(isActive ? 'opacity-50' : '', className)}>
      {isActive && (
        <div
          className="position-absolute w-100 h-100"
          style={{
            zIndex: '2',
          }}
        >
          {showSpinner && renderSpinner()}
        </div>
      )}
      {children}
    </div>
  )
}

export default LoadingOverlay
