import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

import useCurrentCompanyOrFail from '../../../../hooks/useCurrentCompanyOrFail'
import CompleteRegistrationFlow from '../CompleteRegistrationFlow'

interface Props {
  containerClassName?: string
  className: string
  children: React.ReactNode
}

const CompleteRegistrationPopUpButton: React.FC<Props> = ({
  containerClassName,
  className,
  children,
}) => {
  const currentCompany = useCurrentCompanyOrFail()

  const [showModal, setShowModal] = useState<boolean>(false)

  if (currentCompany.isRegistrationComplete) {
    return null
  }

  return (
    <div className={containerClassName}>
      <Button
        variant="outline-warning"
        onClick={() => setShowModal(true)}
        className={className}
      >
        {children}
      </Button>

      {showModal && (
        <CompleteRegistrationFlow onHide={() => setShowModal(false)} />
      )}
    </div>
  )
}

export default CompleteRegistrationPopUpButton
