import React from 'react'
import { Navigate } from 'react-router-dom'

import LoadingScreen from '../components/misc/LoadingScreen'
import { useAuth } from '../hooks/useAuth'
import { useHomeRoute } from '../hooks/useHomeRoute'
import { isOfficeParent, isOfficeUI } from '../lib/helpers/officeHelpers'
import { ROUTES } from '../lib/routes'

// Serves as a front-door to the application. We'll redirect the user to the
// appropriate route depending on their authentication and account status.
const IndexPage: React.FC = () => {
  const { isInitialized, isAuthenticated } = useAuth()
  const homeRoute = useHomeRoute()

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (isOfficeUI() && !isOfficeParent()) {
    // If sign out flow (authService.logout()) has been triggered from officeJS then respond to logoutKeycloak event listener so dialog window can be closed
    Office.context.ui.messageParent('logoutKeycloak')
  }

  const redirectRoute = isAuthenticated ? homeRoute : ROUTES.signIn
  return <Navigate to={redirectRoute} replace />
}

export default IndexPage
