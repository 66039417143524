import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import RequestReferralCallbackForm, {
  RequestCallbackFormInput,
} from '../RequestReferralCallbackForm'

interface Props {
  company: {
    presentationName: string
  }
  onSubmit: (values: RequestCallbackFormInput) => void
  callbackTelephone?: string
  onHide: () => void
}

const RequestCallbackModal: React.FC<Props> = ({
  company,
  callbackTelephone,
  onSubmit,
  onHide,
}) => {
  if (callbackTelephone) {
    return (
      <Modal show onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Callback submitted!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Your callback preferences have been submitted to{' '}
            {company.presentationName}.
          </p>

          <p className="mb-0">
            The adviser is going to call you on {callbackTelephone}.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Schedule a meeting</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <RequestReferralCallbackForm
          companyName={company.presentationName}
          onSubmit={(values) => {
            onSubmit(values)
          }}
        />
      </Modal.Body>
    </Modal>
  )
}

export default RequestCallbackModal
