import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import useModal from '../../../../../hooks/useModal'
import PaymentInstructionsModal from '../../../../../pages/rqCertifiedApplicationStatus/components/PaymentInstructionsModal'

const PendingPaymentConfirmationButtons: React.FC = () => {
  const {
    isShowingModal: isShowingPaymentInstructionsModal,
    showModal: showPaymentInstructionsModal,
    hideModal: hidePaymentInstructionsModal,
  } = useModal()

  const buttonClass = 'w-100 w-md-auto cursor-not-allowed'

  return (
    <>
      <Button
        className={buttonClass}
        variant="outline-success"
        onClick={showPaymentInstructionsModal}
      >
        View payment instructions
      </Button>

      {isShowingPaymentInstructionsModal && (
        <PaymentInstructionsModal onHide={hidePaymentInstructionsModal} />
      )}

      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip>
            You will be able to continue your application once we process your
            payment. We'll notify you when this happens.
          </Tooltip>
        }
      >
        <div className={buttonClass}>
          <Button variant="primary" disabled className="btn-fluid">
            Continue your application
          </Button>
        </div>
      </OverlayTrigger>
    </>
  )
}

export default PendingPaymentConfirmationButtons
