import React from 'react'

interface Props {
  sponsor: {
    companyName: string
    companyLogoUrl?: string | null
  }
}

const SidebarSponsoredBy: React.FC<Props> = ({ sponsor }) => {
  const { companyName, companyLogoUrl } = sponsor

  return (
    <aside className="text-center">
      <div className="fw-bold">Introduced by:</div>

      {companyLogoUrl ? (
        <img
          style={{ maxWidth: '100px', maxHeight: '100px' }}
          className="img-fluid mt-2 mb-4"
          src={companyLogoUrl}
          alt={companyName}
        />
      ) : (
        <div className="lead p-2">{companyName}</div>
      )}
    </aside>
  )
}

export default SidebarSponsoredBy
