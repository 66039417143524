import React, { useState } from 'react'
import { Button, Nav, Navbar as BootstrapNavbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { useIsCompanyUser } from '../../../../../hooks/useIsCompanyUser'
import { useIsRoute } from '../../../../../hooks/useIsRoute'
import useSidebar from '../../../../../hooks/useSidebar'
import { ROUTES } from '../../../../../lib/routes'
import InviteToRqModal from '../../../../modals/InviteToRqModal/InviteToRqModal'
import NavbarMenu from './NavbarMenu'

const Navbar: React.FC = () => {
  const { isOpen, setIsOpen } = useSidebar()
  const [showInviteToRqModal, setShowInviteToRqModal] = useState(false)
  const isCompanyUser = useIsCompanyUser()
  const isRoute = useIsRoute()

  const shouldShowReferButton = isCompanyUser && !isRoute(ROUTES.referClient)

  return (
    <>
      <BootstrapNavbar
        variant="light"
        expand
        className="navbar-bg d-flex flex-wrap"
      >
        <span
          className="sidebar-toggle d-flex"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <i className="hamburger align-self-center" />
        </span>

        <div className="d-flex gap-3">
          {isCompanyUser && (
            <Button
              className="px-4"
              size="lg"
              variant="success"
              onClick={(): void => setShowInviteToRqModal(!showInviteToRqModal)}
            >
              Invite to RQ
            </Button>
          )}

          {shouldShowReferButton && (
            <Link
              to={ROUTES.referClient}
              className="px-4 btn btn-primary btn-lg"
            >
              Refer
            </Link>
          )}
        </div>

        {showInviteToRqModal && (
          <InviteToRqModal onHide={(): void => setShowInviteToRqModal(false)} />
        )}

        <BootstrapNavbar.Collapse>
          <Nav className="navbar-align">
            <NavbarMenu />
          </Nav>
        </BootstrapNavbar.Collapse>
      </BootstrapNavbar>
    </>
  )
}

export default Navbar
