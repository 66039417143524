import { useMutation } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import React, { useState } from 'react'
import { useUpdateEffect } from 'react-use'

import companyService from '../../../../../../lib/services/companyService'
import sentryService from '../../../../../../lib/services/sentryService'
import { CompanyItem } from '../../../../../../types/responses/companies'

interface Props {
  company: CompanyItem
  isIfaFirm: boolean
}

const OtherServicesField: React.FC<Props> = ({ company, isIfaFirm }) => {
  const [otherServices, setOtherServices] = useState(
    company.otherServices || '',
  )

  const debouncedOtherServices = useDebounce(otherServices, 400)

  const updateOtherServiceMutation = useMutation({
    mutationFn: (otherServices: string) =>
      companyService.updateOtherServices(company.id, otherServices),
    onError: () => {
      sentryService.captureMessage({
        message: 'Failed to update other services',
      })
    },
  })

  useUpdateEffect(() => {
    updateOtherServiceMutation.mutate(debouncedOtherServices)
  }, [debouncedOtherServices])

  return (
    <section className={isIfaFirm ? 'mt-3 text-start' : 'mt-4'}>
      <label className="form-label">Other services (optional)</label>

      <input
        type="text"
        className="form-control"
        value={otherServices}
        onChange={(event) => setOtherServices(event.target.value)}
        placeholder="Enter comma-separated list of services"
      />
    </section>
  )
}

export default OtherServicesField
