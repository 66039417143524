import { InternalAxiosRequestConfig } from 'axios'
import { jwtDecode } from 'jwt-decode'
import Keycloak, { KeycloakRegisterOptions } from 'keycloak-js'

import { JwtPayload, UserType } from '../../types/misc'
import { LOCAL_STORAGE_KEYS } from '../constants'
import { getApiUrl } from '../helpers/apiHelpers'
import {
  getOfficeTokens,
  isOfficeParent,
  isOfficeUI,
  openOfficeDialog,
  setOfficeTokens,
} from '../helpers/officeHelpers'
import {
  buildCompassRedirectPageUrl,
  CompassRedirectPageParams,
} from '../helpers/routeHelpers'
import { ROUTES } from '../routes'

export interface KeycloakData {
  isAuthenticated: boolean
}

export interface KeycloakSessionExtended {
  expiryTime: number | undefined
}

class AuthService extends EventTarget {
  private readonly keycloak: Keycloak
  private keycloakInitialized: boolean

  constructor() {
    super()

    this.keycloak = this.createKeycloakInstance()
    this.keycloakInitialized = false
    this.keycloak.onAuthLogout = this.handleAuthLogout
  }

  private createKeycloakInstance(): Keycloak {
    return new Keycloak({
      url: import.meta.env.VITE_KEYCLOAK_URL,
      realm: import.meta.env.VITE_KEYCLOAK_REALM,
      clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
    })
  }

  onSessionExtended = () => {
    this.dispatchEvent(
      new CustomEvent<KeycloakSessionExtended>('sessionExtended', {
        detail: { expiryTime: this.keycloak.refreshTokenParsed?.exp },
      }),
    )
  }

  async initializeKeycloak(): Promise<KeycloakData> {
    if (!this.keycloakInitialized) {
      if (isOfficeParent()) {
        // Unfortunately it's not possible to perform a SSO redirect to the auth page within Outlook & Outlook web or access
        // the Keycloak session cookies on auth.rqratings when embedded in the iframe on Outlook web version
        await this.keycloak.init({
          token: getOfficeTokens().accessToken,
          refreshToken: getOfficeTokens().refreshToken,
          checkLoginIframe: false, // This is to avoid the error: 'Timeout when waiting for 3rd party check iframe message'
        })
      } else {
        await this.keycloak.init({
          onLoad: 'check-sso',
          checkLoginIframe: false, // This is to avoid the error: 'Timeout when waiting for 3rd party check iframe message'
          // Do not use silentCheckSsoRedirect for now - there are too many issues with 3rd party cookies and modern browsers @see https://www.keycloak.org/docs/25.0.0/securing_apps/index.html#_modern_browsers
        })
      }

      this.keycloakInitialized = true
    }

    const isAuthenticated = !!this.keycloak.authenticated

    if (isAuthenticated) {
      this.onSessionExtended()

      if (isOfficeUI() && !isOfficeParent()) {
        const { token: accessToken, refreshToken } = this.keycloak
        const message = JSON.stringify({
          token: accessToken,
          refreshToken: refreshToken,
        })
        Office.context.ui.messageParent(message)
      }
    }

    return { isAuthenticated }
  }

  async setAuthHeader(
    config: InternalAxiosRequestConfig,
  ): Promise<InternalAxiosRequestConfig> {
    if (
      !config.headers ||
      // Don't modify Authorization header if specified at request level
      config.headers['Authorization'] ||
      // Make sure we're not sending the token to the wrong API
      config.baseURL !== getApiUrl()
    ) {
      return config
    }

    // Get new token 5 seconds before the old one expires
    if (this.keycloak.token && this.keycloak.isTokenExpired(5)) {
      console.log('Token expired')
      await this.refreshToken()
    }

    // Do not store tokens in localStorage. Ideally this.keycloak.token should
    // not leave or be exposed by this class. See https://tinyurl.com/yhcadfbn
    if (this.keycloak.token) {
      config.headers['Authorization'] = `Bearer ${this.keycloak.token}`
    }

    return config
  }

  async refreshToken() {
    await this.keycloak
      .updateToken(9999999)
      .then(() => {
        if (this.keycloak.token && this.keycloak.refreshToken) {
          this.onSessionExtended()
          setOfficeTokens(this.keycloak.token, this.keycloak.refreshToken)
          console.log('Successfully updated token')
        }
      })
      .catch((error) => {
        console.error('Failed to update token. Logging the user out...', {
          error,
        })

        this.logout()
      })
  }

  registerAsCompanyUser(options?: KeycloakRegisterOptions) {
    this.keycloak.register(options)
  }

  signInAsCompanyUser(options?: { redirectUri?: string }) {
    const { redirectUri = this.getLoginRedirectUri() } = options || {}

    this.keycloak.login({
      redirectUri: redirectUri,
    })
  }

  signInAsClientUser(options: CompassRedirectPageParams) {
    const redirectUri = buildCompassRedirectPageUrl(options)
    this.signIn(redirectUri)
  }

  signIn(redirectUri?: string) {
    this.keycloak.login({
      redirectUri: redirectUri || this.getLoginRedirectUri(),
    })
  }

  registerAsClientUser(options: CompassRedirectPageParams) {
    this.keycloak.register({
      redirectUri: buildCompassRedirectPageUrl(options),
    })
  }

  getLoginRedirectUri(): string {
    return `${window.location.origin}${ROUTES.index}`
  }

  async logout(returnUri?: string) {
    setOfficeTokens(null, null)

    if (isOfficeParent()) {
      // If user in Office main window we need to open the Office browser window
      // to the sign out page
      return openOfficeDialog(
        ROUTES.signOut,
        (asyncResult) => {
          const dialog = asyncResult.value
          dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            async (arg) => {
              if ('message' in arg && arg.message === 'logoutKeycloak') {
                dialog.close()
                window.location.reload()
              }
            },
          )
        },
        { width: 420, height: 700 },
      )
    }

    const redirectUri = returnUri || this.getLoginRedirectUri()

    await this.keycloak.logout({ redirectUri: redirectUri })
  }

  handleAuthLogout = (): void => {
    this.logout()
  }

  getAccountManagementUrl() {
    return (
      import.meta.env.VITE_KEYCLOAK_URL +
      '/realms/' +
      import.meta.env.VITE_KEYCLOAK_REALM +
      '/account/#/'
    )
  }

  getPreviouslyUserSelectedScope(): string | undefined {
    return (
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.previouslySelectedScope) ||
      undefined
    )
  }

  setUserSelectedScope(scope: UserType) {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.previouslySelectedScope,
      scope,
    )
  }

  getPreviouslyUserSelectedCompanyId(): number | undefined {
    const previouslySelectedCompanyId = window.localStorage.getItem(
      LOCAL_STORAGE_KEYS.previouslySelectedCompanyId,
    )

    return previouslySelectedCompanyId
      ? Number(previouslySelectedCompanyId)
      : undefined
  }

  setUserSelectedCompanyId(companyId: number) {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.previouslySelectedCompanyId,
      companyId.toString(),
    )
  }

  isTokenExpired(token: string): boolean {
    try {
      const decodedToken = jwtDecode<JwtPayload>(token)
      if (!decodedToken || typeof decodedToken.exp === 'undefined') {
        return true
      }

      const currentTime = Math.floor(Date.now() / 1000)
      return decodedToken.exp < currentTime
    } catch (error) {
      console.error('Error decoding token:', error)
      return true // Assume token is expired if it can't be decoded
    }
  }
}

const authService = new AuthService()

export default authService
