import { useQuery } from '@tanstack/react-query'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { useMount } from 'react-use'
import { objectKeys } from 'ts-extras'

import useAppDispatch from '../../../../../../../hooks/useAppDispatch'
import useCurrentCompanyOrFail from '../../../../../../../hooks/useCurrentCompanyOrFail'
import { markFieldsAsTouched } from '../../../../../../../lib/helpers/helperFunctions'
import { QUERY_KEYS } from '../../../../../../../lib/queryKeys'
import companyService from '../../../../../../../lib/services/companyService'
import { setIsCompanyServicesValid } from '../../../../../../../redux/slices/completeRegistrationPopup'
import { IFA_DETAILS_FORM_FIELDS } from '../../constants'
import { IfaDetailsFormValues } from './IfaDetailsForm'

const ValidationTracker: React.FC = () => {
  const currentCompany = useCurrentCompanyOrFail()
  const { isValid: isFormValid, setFieldTouched } =
    useFormikContext<IfaDetailsFormValues>()
  const dispatch = useAppDispatch()

  const servicesQuery = useQuery({
    queryKey: QUERY_KEYS.companyServices(currentCompany.id),
    queryFn: () => companyService.getCompanyServices(currentCompany.id),
  })

  const companyServices = servicesQuery.data
  const hasServices = !!companyServices && companyServices.length > 0
  const isValid = hasServices && isFormValid

  useMount(() => {
    markFieldsAsTouched(objectKeys(IFA_DETAILS_FORM_FIELDS), setFieldTouched)
  })

  useEffect(() => {
    dispatch(setIsCompanyServicesValid(isValid))
  }, [dispatch, isValid])

  return null
}

export default ValidationTracker
