import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION } from '../../lib/constants'
import { CommercialAgreementReferralDirection } from '../../types/misc'
import { RootState } from '../store'

const sliceName = 'commercialAgreementsModal'

interface CommercialAgreementsModalState {
  panelId?: number | null
  referralDirection: CommercialAgreementReferralDirection | null
}

const INITIAL_STATE: CommercialAgreementsModalState = {
  panelId: null,
  referralDirection: null,
}

export interface ShowAgreementTabPayload {
  panelId: number | null
  referralDirection?: CommercialAgreementReferralDirection
}

export const commercialAgreementsModal = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {
    setReferralDirection(
      state,
      action: PayloadAction<CommercialAgreementReferralDirection>,
    ) {
      state.referralDirection = action.payload
    },

    switchTab(state) {
      state.referralDirection = COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING
        ? COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING
        : COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING
    },

    hideCommercialAgreementsModal() {
      return INITIAL_STATE
    },

    showCommercialAgreementsModal(
      state,
      action: PayloadAction<ShowAgreementTabPayload>,
    ) {
      const { panelId, referralDirection } = action.payload
      state.panelId = panelId
      state.referralDirection =
        referralDirection ?? COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING
    },
  },
})

export const { showCommercialAgreementsModal, hideCommercialAgreementsModal } =
  commercialAgreementsModal.actions

export const commercialAgreementsModalReducer =
  commercialAgreementsModal.reducer

export const selectCommercialAgreementsModal = (state: RootState) => {
  return state.commercialAgreementsModal
}
export const selectShouldShowCommercialAgreementsModal = createSelector(
  selectCommercialAgreementsModal,
  (state) => state.referralDirection && state.panelId,
)

export const selectCommercialAgreementReferralDirection = createSelector(
  selectCommercialAgreementsModal,
  (state) => state.referralDirection,
)

export const selectCommercialAgreementsModalPanelId = createSelector(
  selectCommercialAgreementsModal,
  (state) => state.panelId,
)
