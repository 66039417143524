import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import PaymentDetails from './PaymentDetails'

interface Props {
  onHide: () => void
}

const StartApplicationModal: React.FC<Props> = ({ onHide }) => {
  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Payment instructions</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <>
          <p>
            <PaymentDetails />
          </p>

          <p>
            We are processing your application fee payment. We will notify you
            when your payment details have been confirmed and when you can begin
            your application.
          </p>
        </>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end">
        <Button variant="outline-secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default StartApplicationModal
