import { useSuspenseQuery } from '@tanstack/react-query'

import { CompanyProfileSearchParams } from '../components/misc/CompanyProfile/CompanyProfile'
import { QUERY_KEYS } from '../lib/queryKeys'
import companyProfileService from '../lib/services/companyProfileService'
import companyService from '../lib/services/companyService'

export function useCompanyProfileQuery(
  params: Pick<CompanyProfileSearchParams, 'identifierType' | 'identifier'>,
) {
  return useSuspenseQuery({
    queryKey: QUERY_KEYS.companyProfile(params),
    staleTime: 5000,
    queryFn: () => {
      if (!companyProfileService.isValidIdentifierType(params.identifierType)) {
        throw new Error(`Invalid identifier type: ${params.identifierType}`)
      }

      return companyService.getCompanyProfile({
        identifier: params.identifier,
        identifierType: params.identifierType,
      })
    },
  })
}
