import React from 'react'
import { Modal } from 'react-bootstrap'
import { useExitIntent } from 'use-exit-intent'

import useModal from '../../../hooks/useModal'
import DidYouBookTheMeetingModal from './DidYouBookTheMeetingModal'

interface Props {
  companyName: string
  scheduleCallUrl: string
  onBooked: () => void
  onHide: () => void
  isProcessing: boolean
}

const NonCalendlyModal: React.FC<Props> = ({
  companyName,
  scheduleCallUrl,
  onBooked,
  onHide,
  isProcessing,
}) => {
  const {
    isShowingModal: isShowingExitPromptModal,
    showModal: showExitPromptModal,
    hideModal: hideExitPromptModal,
  } = useModal()

  const { registerHandler, isTriggered: hasExitPromptBeenShown } =
    useExitIntent({
      desktop: {
        delayInSecondsToTrigger: 60,
        triggerOnMouseLeave: true,
        triggerOnIdle: true,
        useBeforeUnload: false,
      },
      mobile: {
        triggerOnIdle: true,
        delayInSecondsToTrigger: 60,
      },
    })

  // This will show the modal when user is about to leave the page or is idle
  // for 60 seconds as configured via the useExitIntent hook above
  registerHandler({
    id: 'openModal',
    handler: () => {
      showExitPromptModal()
    },
  })

  function handleHide() {
    // If the user has answered the exit prompt already, hide the modal
    if (hasExitPromptBeenShown) {
      onHide()
    } else {
      // Otherwise, show the exit prompt modal
      showExitPromptModal()
    }
  }

  return (
    <>
      <Modal show onHide={handleHide}>
        {isShowingExitPromptModal && (
          <div
            className="bg-black position-absolute top-0 end-0 bottom-0 left-0 w-100"
            style={{ zIndex: '1000', opacity: '.6' }}
          ></div>
        )}

        <Modal.Header closeButton>
          <Modal.Title>Schedule a meeting</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h2>{companyName}</h2>

          <iframe
            title={`Schedule call with ${companyName}`}
            src={scheduleCallUrl}
            width="100%"
            height="600px"
          ></iframe>
        </Modal.Body>
      </Modal>

      {isShowingExitPromptModal && (
        <DidYouBookTheMeetingModal
          companyName={companyName}
          onBooked={onBooked}
          onNoBooked={() => {
            hideExitPromptModal()
            onHide()
          }}
          onHide={hideExitPromptModal}
          isProcessing={isProcessing}
        />
      )}
    </>
  )
}

export default NonCalendlyModal
