import React from 'react'

import authService from '../../../../../lib/services/authService'

const GuestMenu: React.FC = () => (
  <span className="d-none d-sm-inline-block">
    <div
      className="btn btn-pill me-2 btn-success"
      onClick={() => authService.signInAsCompanyUser()}
    >
      Sign In
    </div>
  </span>
)

export default GuestMenu
