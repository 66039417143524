import React from 'react'

import Main from '../../misc/Main'
import SentryErrorBoundary from '../../misc/SentryErrorBoundary'

interface Props {
  children: React.ReactNode
}

const LandingWhiteLayout: React.FC<Props> = ({ children }) => (
  <div className="bg-white">
    <Main>
      <SentryErrorBoundary variant="full-screen">
        {children}
      </SentryErrorBoundary>
    </Main>
  </div>
)

export default LandingWhiteLayout
