import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import invariant from 'tiny-invariant'

import { NetworkItem, ServiceAreaItem } from '../../types/responses/common-data'
import { CompanyUserItem } from '../../types/responses/company-users'
import { PanelItem } from '../../types/responses/panels'
import { RootState } from '../store'
import { selectCurrentCompany } from './session'

export type StatusChartTab = 'sent' | 'received'

export const STATUS_CHART_TAB: Record<StatusChartTab, StatusChartTab> = {
  sent: 'sent',
  received: 'received',
}

export type IncomeChartTab = 'currentYear' | 'allTime'

export const INCOME_CHART_TAB: Record<IncomeChartTab, IncomeChartTab> = {
  currentYear: 'currentYear',
  allTime: 'allTime',
}

export interface ReferralsDashboardState {
  filters: {
    companyUser: CompanyUserItem | null
    serviceArea: ServiceAreaItem | null
    panel: PanelItem | null
    network: NetworkItem | null
    statusChartTab: StatusChartTab
    incomeChartTab: IncomeChartTab
  }
}

const INITIAL_STATE: ReferralsDashboardState = {
  filters: {
    companyUser: null,
    serviceArea: null,
    panel: null,
    network: null,
    statusChartTab: STATUS_CHART_TAB.sent,
    incomeChartTab: INCOME_CHART_TAB.allTime,
  },
}

const referralsDashboard = createSlice({
  name: 'clientsOverview',
  initialState: INITIAL_STATE,
  reducers: {
    updateFilters(
      state,
      action: PayloadAction<Partial<ReferralsDashboardState['filters']>>,
    ) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },

    resetFilters(state) {
      state.filters = INITIAL_STATE.filters
    },
  },
})

export const referralsDashboardReducer = referralsDashboard.reducer

export const selectReferralsDashboard = (state: RootState) =>
  state.referralsDashboard

export const selectFilters = createSelector(
  selectReferralsDashboard,
  (referralsDashboard) => referralsDashboard.filters,
)

export const selectHasAppliedFilter = createSelector(
  selectFilters,
  (filters) => {
    return !!(
      filters.companyUser ||
      filters.serviceArea ||
      filters.panel ||
      filters.network
    )
  },
)

export const selectOtherCompanyId = createSelector(
  selectCurrentCompany,
  selectFilters,
  (currentCompany, filters): number | undefined => {
    invariant(currentCompany, 'Expected current company to be defined')

    const panel = filters.panel
    if (!panel) {
      return
    }

    const { fromCompany, toCompany } = panel

    const otherCompany =
      currentCompany.id === fromCompany.id ? toCompany : fromCompany

    return otherCompany.id
  },
)

export const selectServiceAreaId = createSelector(
  selectFilters,
  (filters) => filters.serviceArea?.id,
)

export const selectActiveStatusChartTab = createSelector(
  selectFilters,
  (filters) => filters.statusChartTab,
)

export const selectActiveIncomeChartTab = createSelector(
  selectFilters,
  (filters) => filters.incomeChartTab,
)

export const selectNetworkId = createSelector(
  selectFilters,
  (filters) => filters.network?.id,
)

export const { updateFilters, resetFilters } = referralsDashboard.actions
