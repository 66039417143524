import React from 'react'
import { Link } from 'react-router-dom'

export interface Props {
  query: string | null
  className?: string
}

const FcaSearchLink: React.FC<Props> = ({ query, className }) => {
  if (query === null) {
    return null
  }

  return (
    <Link
      className={className}
      to={`https://register.fca.org.uk/s/search?q=${query}&type=Companies`}
      target="_blank"
      rel="noreferrer"
    >
      {query}
    </Link>
  )
}

export default FcaSearchLink
