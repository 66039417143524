import { FallbackRender } from '@sentry/react'
import classNames from 'classnames'
import React from 'react'
import { Card } from 'react-bootstrap'

import ErrorMessage from './ErrorMessage/ErrorMessage'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  error: Parameters<FallbackRender>[0]
  fallbackComponent?: React.ReactNode
  cardProps?: React.ComponentProps<typeof Card>
}

const FullPageErrorFallback: React.FC<Props> = ({
  className,
  style,
  error,
  fallbackComponent,
  cardProps,
}) => {
  if (fallbackComponent) {
    return fallbackComponent
  }

  return (
    <div
      className={classNames(
        'mt-5 p-4 mx-auto d-flex justify-content-center',
        className,
      )}
      style={{ maxWidth: '800px', ...style }}
    >
      <Card {...cardProps}>
        <Card.Body>
          <ErrorMessage error={error} />
        </Card.Body>
      </Card>
    </div>
  )
}

export default FullPageErrorFallback
