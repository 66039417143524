import React from 'react'

import useAppSelector from '../../hooks/useAppSelector'
import { selectShouldShowIcaewConsentPrompt } from '../../redux/slices/company'
import IcaewConsentModal from './IcaewConsentModal'

const CompanyConsentModal: React.FC = () => {
  const shouldShowIcaewConsentPrompt = useAppSelector(
    selectShouldShowIcaewConsentPrompt,
  )

  if (shouldShowIcaewConsentPrompt) {
    return <IcaewConsentModal />
  }

  // No consent required
  return null
}

export default CompanyConsentModal
