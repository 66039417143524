import React from 'react'

interface Props {
  label: string
  className?: string
}

const TermsAndConditionsLink: React.FC<Props> = ({ label, className }) => {
  // You may need to purge the Cloudflare cache for both staging and production
  // if you update the contents of the file:
  // https://developers.cloudflare.com/cache/how-to/purge-cache/#purge-by-single-file-by-url
  const link = '/files/Platform_Terms_of_Use.pdf'

  return (
    <a href={link} target="_blank" rel="noreferrer" className={className}>
      {label}
    </a>
  )
}

export default TermsAndConditionsLink
