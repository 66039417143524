import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface ReferralCaseState {
  hasUserUpdatedStatus: boolean
  hasUserAddedNote: boolean
  isReferralStatusDropdownOpen: boolean
}

const INITIAL_STATE: ReferralCaseState = {
  hasUserUpdatedStatus: false,
  hasUserAddedNote: false,
  isReferralStatusDropdownOpen: false,
}

const referralCase = createSlice({
  name: 'referralCase',
  initialState: INITIAL_STATE,
  reducers: {
    resetReferralCase() {
      return {
        ...INITIAL_STATE,
      }
    },
    setHasUserUpdatedStatus(state, action: PayloadAction<boolean>) {
      state.hasUserUpdatedStatus = action.payload
    },
    setHasUserAddedNote(state, action: PayloadAction<boolean>) {
      state.hasUserAddedNote = action.payload
    },
    setIsReferralStatusDropdownOpen(state, action: PayloadAction<boolean>) {
      state.isReferralStatusDropdownOpen = action.payload
    },
  },
})

export const referralCaseReducer = referralCase.reducer

export const selectReferralCase = (state: RootState) => state.referralCase

export const {
  resetReferralCase,
  setHasUserUpdatedStatus,
  setHasUserAddedNote,
  setIsReferralStatusDropdownOpen,
} = referralCase.actions
