import { Faker } from '@faker-js/faker'
import sample from 'lodash/sample'
import sampleSize from 'lodash/sampleSize'
import invariant from 'tiny-invariant'

import { GroupedSelectOption } from '../../components/form/MultiSelectInput'
import { SelectOption } from '../../types/misc'
import { padDatePartWithZero } from './helperFunctions'

export function importFaker(): Promise<Faker> {
  return import('@faker-js/faker').then(({ fakerEN_GB }) => {
    return fakerEN_GB
  })
}

export function randomBoolean(): boolean {
  return Math.random() >= 0.5
}

export function randomBirthday(faker: Faker) {
  const date = faker.date.birthdate({ min: 18, max: 55, mode: 'age' })

  const [day, month, year] = [
    date.getDate(),
    date.getMonth() + 1,
    date.getFullYear(),
  ].map(padDatePartWithZero)

  return `${day}/${month}/${year}`
}

export function randomMonth(faker: Faker) {
  return faker.number.int({ min: 1, max: 12 })
}

export function randomYear(faker: Faker, type: 'past' | 'future') {
  const currentYear = new Date().getFullYear()
  const options =
    type === 'past'
      ? {
          min: currentYear - 100,
          max: currentYear - 1,
        }
      : {
          min: currentYear,
          max: currentYear + 20,
        }
  return faker.number.int(options)
}

export function randomEmail(faker: Faker): string {
  return `dev+${faker.number.int({ min: 1000, max: 10000 })}@rq.app`
}

export function randomMoneyAmount(faker: Faker) {
  return faker.number.int({ min: 1000, max: 100000 })
}

export function randomPercentage(faker: Faker) {
  return faker.number.int({ min: 0, max: 100 })
}

export function randomSingleSelectOption(
  options: SelectOption[],
): string | undefined {
  return sample(options)?.value
}

export function randomMultiSelectOption(options: SelectOption[]): string[] {
  return sampleSize(options, sample([1, 2])).map((option) => option.value)
}

export function randomMultiSelectGroupOption(
  groupedOptions: GroupedSelectOption[],
): string[] {
  const groups = sampleSize(groupedOptions, sample([1, 2]))

  const options = groups
    .map((group) => group.options)
    .flat()
    .map((option) => option.value)

  return sampleSize(options, sample([1, 3]))
}

export function randomElement<T>(array: T[]): T {
  if (array.length === 0) {
    throw new Error('Array must not be empty')
  }

  const randomIndex = Math.floor(Math.random() * array.length)
  const element = array[randomIndex]

  invariant(element, 'Expected element to be defined')

  return element
}

export function getRandomGBAddress() {
  // Predefined list of cities with corresponding postcodes
  const citiesWithPostcodes = [
    {
      city: 'London',
      postcodes: [
        'E1 6AN',
        'EC1A 1BB',
        'N1 1BA',
        'NW1 5AG',
        'SE1 2DA',
        'SW1A 1AA',
        'W1A 1AA',
        'WC1A 1AA',
      ],
    },
    {
      city: 'Manchester',
      postcodes: ['M1 1AE', 'M2 5BQ', 'M4 5AH'],
    },
    {
      city: 'Birmingham',
      postcodes: ['B1 1BA', 'B2 4QA', 'BA1 3DD', 'B4 7XG'],
    },
  ]

  // Randomly select a city and postcode
  const randomCityIndex = Math.floor(Math.random() * citiesWithPostcodes.length)
  const selectedCity = citiesWithPostcodes[randomCityIndex]
  invariant(selectedCity, 'Expected selectedCity to be defined')
  const randomPostcodeIndex = Math.floor(
    Math.random() * selectedCity.postcodes.length,
  )

  return {
    city: selectedCity.city,
    postcode: selectedCity.postcodes[randomPostcodeIndex],
  }
}
