import React from 'react'
import { Modal } from 'react-bootstrap'

import useAppDispatch from '../../hooks/useAppDispatch'
import useAppSelector from '../../hooks/useAppSelector'
import { useIsRoute } from '../../hooks/useIsRoute'
import { EXTERNAL_LINKS } from '../../lib/constants'
import { ROUTES } from '../../lib/routes'
import {
  hideBookMeetingModal,
  selectBookMeetingModalUrl,
} from '../../redux/slices/bookMeetingModal'
import HubspotMeeting from '../widgets/HubspotMeeting'

const BookMeetingModal: React.FC = () => {
  const bookMeetingModalUrl = useAppSelector(selectBookMeetingModalUrl)
  const dispatch = useAppDispatch()
  const isRoute = useIsRoute()

  if (!bookMeetingModalUrl) {
    return null
  }

  function closeModal() {
    dispatch(hideBookMeetingModal())
  }

  return (
    <Modal show size="lg" onHide={closeModal}>
      <Modal.Header closeButton closeLabel="Close">
        <Modal.Title>Book a slot with our team</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <HubspotMeeting
          embedUrl={`${EXTERNAL_LINKS.hubspot.bookSlotWithRqLink}?embed=true`}
          style={{
            maxHeight: isRoute(ROUTES.bookMeeting) ? '650px' : undefined,
          }}
        />
      </Modal.Body>
    </Modal>
  )
}

export default BookMeetingModal
