import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface Props {
  company: {
    presentationName: string
  }
  onAnswer: (isSharing: boolean) => void
  onHide: () => void
}

const ShareCompassReportModal: React.FC<Props> = ({
  company,
  onAnswer,
  onHide,
}) => {
  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Sharing your Compass insights</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Are you happy for your Compass snapshot to be shared with{' '}
          <b>{company?.presentationName}</b> before you speak with them?
        </p>

        <p>
          This will help them prepare for your initial conversation. You can
          revoke access to this at any time from the <b>Sharing</b> tab.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => onAnswer(false)}>
          Don't share
        </Button>

        <Button variant="success" onClick={() => onAnswer(true)}>
          Share
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ShareCompassReportModal
