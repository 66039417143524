import { AboutYourClientInput } from '../../utils/types'

export const AboutYourClientInputFields: Record<
  keyof AboutYourClientInput,
  keyof AboutYourClientInput
> = {
  existing_client: 'existing_client',
  user_name_first: 'user_name_first',
  user_name_last: 'user_name_last',
  user_date_of_birth: 'user_date_of_birth',
  user_email: 'user_email',
  user_private_situation: 'user_private_situation',
  user_professional_situation: 'user_professional_situation',

  partner_name_first: 'partner_name_first',
  partner_name_last: 'partner_name_last',
  partner_date_of_birth: 'partner_date_of_birth',
  partner_professional_situation: 'partner_professional_situation',
}

export const AboutYourClientPartnerFields = [
  AboutYourClientInputFields.partner_name_first,
  AboutYourClientInputFields.partner_name_last,
  AboutYourClientInputFields.partner_date_of_birth,
  AboutYourClientInputFields.partner_professional_situation,
]

export const PERSONAL_SITUATION_MARRIED = 'Married'
export const PERSONAL_SITUATION_RELATIONSHIP_WITH_SHARED_FINANCES =
  'In a relationship with shared finances'

export const WITH_PARTNER_PERSONAL_SITUATIONS = [
  PERSONAL_SITUATION_MARRIED,
  PERSONAL_SITUATION_RELATIONSHIP_WITH_SHARED_FINANCES,
]

export const EXISTING_CLIENT_ERROR_REGEX =
  /A client with this email address already exists/i
