import { UpdateUserContextRequest } from '../../types/requests/users'
import {
  UpdateUserContextResponse,
  UserInvitesCollection,
} from '../../types/responses/users'
import apiService from './apiService'

class UserService {
  private endpoint = '/v1/users'

  async getInvites(userId: number): Promise<UserInvitesCollection> {
    const response = await apiService.get(`${this.endpoint}/${userId}/invites`)

    return response.data
  }

  async updateUserContext(
    userId: number,
    request: UpdateUserContextRequest,
  ): Promise<UpdateUserContextResponse> {
    const response = await apiService.put<UpdateUserContextResponse>(
      `${this.endpoint}/${userId}/context`,
      request,
    )

    return response.data
  }

  async saveCompassReportDataPrivacy(
    userId: number,
    isPrivate: boolean,
  ): Promise<{ isCompassDataPrivate: boolean }> {
    const response = await apiService.patch<{
      isCompassDataPrivate: boolean
    }>(`${this.endpoint}/${userId}/compass-data-privacy`, {
      isCompassDataPrivate: isPrivate,
    })

    return response.data
  }

  async enableClientScope(userId: number) {
    const response = await apiService.post(
      `${this.endpoint}/${userId}/enable-client`,
      {},
    )

    return response.data
  }

  async enableCompanyScope(userId: number) {
    const response = await apiService.post(
      `${this.endpoint}/${userId}/enable-company`,
      {},
    )

    return response.data
  }

  async markWalkthroughAsComplete(userId: number) {
    const response = await apiService.post(
      `${this.endpoint}/${userId}/complete-walkthrough`,
      {},
    )

    return response.data
  }

  createIri(userId: number | string) {
    return `${this.endpoint}/${userId}`
  }

  getIri(userId: number) {
    return `${this.endpoint}/${userId}`
  }
}

const userService = new UserService()

export default userService
