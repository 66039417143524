import React from 'react'

import { SidebarPage } from './Sidebar'
import SidebarNavList from './SidebarNavList'

interface Props {
  className?: Element
  title?: string
  pages: SidebarPage[]
}

const SidebarNavSection: React.FC<Props> = ({ title, pages, ...rest }) => (
  <React.Fragment {...rest}>
    {title && <li className="sidebar-header">{title}</li>}
    <SidebarNavList pages={pages} depth={0} />
  </React.Fragment>
)

export default SidebarNavSection
