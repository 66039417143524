import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

import { ActionButtonProps } from './ActionButton'
import PlainButton from './PlainButton'

type Props = ActionButtonProps

const PlainActionButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ isProcessing, isProcessingText, children, className, ...props }, ref) => (
    <PlainButton
      className={classNames(className)}
      disabled={isProcessing}
      style={{ opacity: isProcessing ? '0.6' : '1' }}
      ref={ref}
      {...props}
    >
      {isProcessing ? isProcessingText : children}

      {isProcessing && (
        <FontAwesomeIcon
          icon={faSpinner}
          className="fa-spin"
          style={{ marginLeft: '6px' }}
        />
      )}
    </PlainButton>
  ),
)

export default PlainActionButton
