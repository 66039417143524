import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTriangleExclamation } from '@rq-ratings/pro-solid-svg-icons'
import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap'

interface Props {
  value: string
  isValid: boolean
  onClickRemove: () => void
  onChange: (newValue: string) => void
}

const EmailAddress: React.FC<Props> = ({
  value,
  isValid,
  onClickRemove,
  onChange,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const editableElemRef = useRef<HTMLDivElement>(null)

  const bsColour = isValid ? 'light' : 'danger'

  function beginEditing() {
    setIsEditing(true)
    editableElemRef.current?.focus()
  }

  return (
    <span
      className={`email-address text-bg-${bsColour} rounded d-inline-flex align-items-center lh-lg`}
    >
      <Button
        variant={bsColour}
        className={`pe-2 fw-bold cursor-text border-2 ${isEditing ? 'border-primary text-body bg-white' : 'border-' + bsColour}`}
        title={
          isValid ? 'Edit' : 'This is not a valid email address. Click to edit'
        }
        onClick={beginEditing}
        onFocus={beginEditing}
      >
        {!isValid && (
          <span className="pe-2">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
        )}

        <span
          contentEditable={isEditing}
          suppressContentEditableWarning
          ref={editableElemRef}
          onChange={(e) => {
            onChange(e.currentTarget.textContent ?? '')
          }}
          onBlur={(e) => {
            onChange(e.currentTarget.textContent ?? '')
            setIsEditing(false)
          }}
          onKeyDown={(e) => {
            switch (e.key) {
              case 'Backspace':
              case 'Delete':
                if ((e.currentTarget.textContent ?? '').length === 0) {
                  // pressing backspace and there's no text in there - just delete it
                  onClickRemove()
                }

                break

              case 'Enter':
                e.preventDefault()
                e.bubbles = false

                setIsEditing(false)
                onChange(e.currentTarget.textContent ?? '')
            }
          }}
          style={{ outline: 'none' }}
        >
          {value}
        </span>
      </Button>

      {/* Remove button */}
      <Button
        variant={bsColour}
        title="Remove"
        className="ps-2"
        onClick={onClickRemove}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </span>
  )
}

export default EmailAddress
