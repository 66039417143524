import queryString from 'query-string'
import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'

import useAppDispatch from '../../hooks/useAppDispatch'
import useAppSelector from '../../hooks/useAppSelector'
import { useUpdateOnboardingChecklistMutation } from '../../hooks/useUpdateOnboardingChecklistMutation'
import { EXTERNAL_LINKS } from '../../lib/constants'
import {
  ONBOARDING_CHECKLIST_STEP,
  selectOnboardingChecklist,
} from '../../redux/slices/onboardingChecklist'
import {
  hideVideoModal,
  selectVideoModalVideoUrl,
} from '../../redux/slices/videoModal'

const VideoModal: React.FC = () => {
  const videoUrl = useAppSelector(selectVideoModalVideoUrl)
  const onboardingChecklist = useAppSelector(selectOnboardingChecklist)
  const dispatch = useAppDispatch()
  const onboardingChecklistMutation = useUpdateOnboardingChecklistMutation()
  const onboardingChecklistMutationRef = useRef(onboardingChecklistMutation)

  useEffect(() => {
    const shouldMarkExploreAsCompleted =
      onboardingChecklist &&
      onboardingChecklist.exploreCompass === false &&
      videoUrl === EXTERNAL_LINKS.vimeo.compass

    if (shouldMarkExploreAsCompleted) {
      onboardingChecklistMutationRef.current.mutate({
        checklistId: onboardingChecklist.id,
        request: { [ONBOARDING_CHECKLIST_STEP.exploreCompass]: true },
      })
    }
  }, [onboardingChecklist, videoUrl])

  if (!videoUrl) {
    return null
  }

  const vimeoEmbedParams = {
    autoplay: 1,
    transparent: 1,
    autopause: 0,
  }

  const vimeoEmbedUrl = `${videoUrl}&${queryString.stringify(vimeoEmbedParams)}`

  return (
    <Modal
      className="modal-p-0"
      show={!!videoUrl}
      size="xl"
      onHide={() => dispatch(hideVideoModal())}
      style={{ background: 'rgba(0, 0, 0, 0.5)' }}
    >
      <Modal.Header
        closeButton
        closeLabel="Close"
        className="bg-black border-0"
        closeVariant="white"
      ></Modal.Header>
      <iframe
        title="Vimeo video player"
        src={vimeoEmbedUrl}
        width="1000"
        height="560"
        frameBorder="0"
        style={{ backgroundColor: 'black', width: '100%' }}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Modal>
  )
}

export default VideoModal
