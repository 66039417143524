import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { CSSProperties } from 'react'
import { Spinner } from 'react-bootstrap'

import useAppSelector from '../../../../../../hooks/useAppSelector'
import { useCompanyType } from '../../../../../../hooks/useCompanyType'
import useCurrentCompanyOrFail from '../../../../../../hooks/useCurrentCompanyOrFail'
import { QUERY_KEYS } from '../../../../../../lib/queryKeys'
import companyService from '../../../../../../lib/services/companyService'
import { selectServiceAreasByCompanyTypeCode } from '../../../../../../redux/slices/commonData'
import { ExpertiseAreaItem } from '../../../../../../types/responses/common-data'
import CompanyServiceToggleButton from './CompanyServiceToggleButton'

const CompanyNonFcaServices: React.FC = () => {
  const currentCompany = useCurrentCompanyOrFail()
  const companyType = useCompanyType()
  const queryClient = useQueryClient()
  const servicesQueryKey = QUERY_KEYS.services(currentCompany.id)
  const serviceAreas = useAppSelector(
    selectServiceAreasByCompanyTypeCode(companyType.code),
  )

  const servicesQuery = useQuery({
    queryKey: servicesQueryKey,
    queryFn: () => companyService.getCompanyServices(currentCompany.id),
  })

  const services = servicesQuery.data

  if (servicesQuery.isLoading || !services) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <Spinner />
      </div>
    )
  }

  function getServiceIdByServiceAreaId(
    serviceAreaId: number,
  ): number | undefined {
    if (!services) {
      return
    }

    return services.find((service) => service.serviceArea.id === serviceAreaId)
      ?.id
  }

  return (
    <section className="d-flex flex-column align-items-center justify-content-center h-100 pop-in">
      <div
        style={
          {
            // Even columns. Ideally max 10 per column. Definitely max 3 columns
            '--number-of-columns': Math.min(
              Math.ceil(serviceAreas.length / 10),
              3,
            ),
            display: 'grid',
            gridTemplateColumns:
              'repeat(var(--number-of-columns), minmax(auto, 1fr))',
            gap: '.5rem',
          } as CSSProperties
        }
      >
        {servicesQuery.isPending ? (
          <Spinner />
        ) : (
          <>
            {serviceAreas.map((serviceArea: ExpertiseAreaItem) => (
              <CompanyServiceToggleButton
                key={serviceArea.id}
                companyId={currentCompany.id}
                serviceId={getServiceIdByServiceAreaId(serviceArea.id)}
                serviceAreaId={serviceArea.id}
                serviceAreaName={serviceArea.name}
                onChangeSaved={() => {
                  queryClient.invalidateQueries({
                    queryKey: servicesQueryKey,
                  })
                }}
              />
            ))}
          </>
        )}
      </div>
    </section>
  )
}

export default CompanyNonFcaServices
