import invariant from 'tiny-invariant'

import useAppSelector from '../../../../hooks/useAppSelector'
import { selectCompassMetadata } from '../../../../redux/slices/compass'

export function useCompassMetadataOrFail() {
  const metadata = useAppSelector(selectCompassMetadata)

  invariant(
    metadata,
    'Compass metadata is not available yet. Make sure you are using the CompassFlow component inside a CompassProvider.',
  )

  return metadata
}
