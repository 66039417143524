import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { useAuth } from '../../../../hooks/useAuth'
import { EMAIL_ADDRESSES } from '../../../../lib/constants'
import { ROUTES } from '../../../../lib/routes'
import TermsAndConditionsLink from '../../../misc/TermsAndConditionsLink'

const Footer: React.FC = () => {
  const { currentCompany, isClientUser, isIcaewUser } = useAuth()

  function getDisclaimerRoute(): string {
    if (isClientUser) {
      return ROUTES.compassDisclaimer
    }

    return isIcaewUser ? ROUTES.icaewDisclaimer : ROUTES.disclaimer
  }

  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col md="6" className="mb-2 mb-md-0">
            <ul className="list-inline">
              <li className="list-inline-item">
                <Link className="text-muted" to={getDisclaimerRoute()}>
                  RQ Certification disclaimer
                </Link>
              </li>

              <li className="list-inline-item">
                {currentCompany && (
                  <TermsAndConditionsLink
                    label="Terms and conditions"
                    className="text-muted"
                  />
                )}
              </li>

              <li className="list-inline-item">
                <a
                  className="text-muted"
                  href="https://www.rq.app/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy
                </a>
              </li>
            </ul>
          </Col>

          <Col md="6" className="text-md-end">
            <p className="mb-0">
              <a href={`mailto:${EMAIL_ADDRESSES.rqHello}`}>
                {EMAIL_ADDRESSES.rqHello}
              </a>
              <span className="ms-2 me-2">+44(0)20 3856 4769</span>
              &copy; {new Date().getFullYear()} -{' '}
              <span className="text-muted">RQ Ratings Ltd.</span>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
