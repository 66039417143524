import React from 'react'
import { Alert } from 'react-bootstrap'

import { COMMON_DATA_ERROR_MESSAGE } from '../../lib/constants'

const CommonDataErrorMessage: React.FC = () => (
  <Alert variant="danger" className="p-3 d-flex flex-column">
    <p className="mb-0">
      {COMMON_DATA_ERROR_MESSAGE}. Run the{' '}
      <a
        href="https://github.com/RQ-Ratings/dev/blob/master/clean-environment.sh"
        target="_blank"
        rel="noreferrer"
      >
        clean-environment.sh
      </a>{' '}
      script to clear the cache.
    </p>
  </Alert>
)

export default CommonDataErrorMessage
