/**
 * Use the Company#frnTradingNameCode if you want the FRN trading name code or
 * Company#partnerProfileFrontendPath or Company#companyProfileFrontendPath if
 * you need it to generate the path for the profile page.
 *
 * @deprecated
 */
export function getFrnTradingNameCode(
  frn: string,
  frnTradingNameId?: string,
): string {
  return frn + (frnTradingNameId ? `-${frnTradingNameId}` : '')
}
