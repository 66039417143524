import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import { Spinner } from 'react-bootstrap'

import RadioOptions, {
  RadioOptionsProps,
  RadioOptionsType,
} from '../misc/RadioOptions'
import FieldError from './FieldError'
import FieldLabel, { TooltipHint } from './FieldLabel'

export type RadioOptionsInputProps<TValue> = RadioOptionsProps<TValue> & {
  name: string
  label?: React.ReactNode
  transformErrorMessage?: (originalError: string) => React.ReactElement | string
  tooltipHint?: TooltipHint
  labelClassName?: string
  isProcessing?: boolean
}

function RadioOptionsInput<TValue extends RadioOptionsType>({
  name,
  label,
  options,
  onChange,
  tooltipHint,
  labelClassName,
  transformErrorMessage,
  isProcessing,
  ...props
}: RadioOptionsInputProps<TValue>) {
  const { submitCount, setFieldValue } = useFormikContext()
  const [field, { error }] = useField(name)
  const id = `field_${name}`
  const showError = !!(submitCount > 0 && error)

  function handleChange(value: TValue) {
    setFieldValue(name, value)

    if (onChange) {
      onChange(value)
    }
  }

  return (
    <>
      {label && (
        <FieldLabel
          htmlFor={id}
          tooltipHint={tooltipHint}
          className={classNames('mb-1', labelClassName)}
        >
          {label}
        </FieldLabel>
      )}

      <div className="d-flex align-items-center gap-3">
        <RadioOptions
          options={options}
          value={field.value}
          onChange={handleChange}
          inputName={name}
          {...props}
        />
        {isProcessing && <Spinner size="sm" />}
      </div>

      {showError && (
        <FieldError inputId={id}>
          {transformErrorMessage ? transformErrorMessage(error) : error}
        </FieldError>
      )}
    </>
  )
}

export default RadioOptionsInput
