import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import SentryErrorBoundary from './components/misc/SentryErrorBoundary'
import { registerRuntimeErrorListener } from './lib/helpers/helperFunctions'
import posthogService from './lib/services/posthogService'
import sentryService from './lib/services/sentryService'

sentryService.init()
posthogService.init()

const container = document.getElementById('root') as HTMLElement

const root = createRoot(container)

registerRuntimeErrorListener()

root.render(
  <SentryErrorBoundary variant="full-screen">
    <App />
  </SentryErrorBoundary>,
)

// If we fail to load a dynamic import, reload the page
// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', () => {
  console.log('vite:preloadError occurred. Reloading the page...')
  window.location.reload()
})
