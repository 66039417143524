export function tokenFromSnapshotUrl(url: string): string | undefined {
  const uuidPattern =
    /\/compass\/snapshots\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/i

  const match = url.match(uuidPattern)

  if (match) {
    return match[1]
  }

  return undefined
}
