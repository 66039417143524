import { PersonalAssetsInputFields } from '../../../components/flows/CompassFlow/steps/8_PersonalAssets/constants'
import {
  CompassQuestionFor,
  PersonalAssetsInput,
  PropertyInput,
} from '../../../components/flows/CompassFlow/utils/types'

class CompassPersonalAssetsService {
  makePropertyPlaceholder(): PropertyInput {
    return {
      property_name: undefined,
      property_estimated_value: undefined,
    }
  }

  getProperties(
    personalAssets: PersonalAssetsInput | undefined,
    questionFor: CompassQuestionFor,
  ): PropertyInput[] {
    if (!personalAssets) {
      return []
    }

    const propertiesField = this.getPropertiesField(questionFor)

    return (personalAssets[propertiesField] ?? []) as PropertyInput[]
  }

  getPropertiesField(
    questionFor: CompassQuestionFor,
  ): keyof PersonalAssetsInput {
    const propertiesFields = {
      joint: PersonalAssetsInputFields.joint_properties,
      user: PersonalAssetsInputFields.user_properties,
      partner: PersonalAssetsInputFields.partner_properties,
    } satisfies Record<CompassQuestionFor, keyof PersonalAssetsInput>

    return propertiesFields[questionFor]
  }

  hasMortgage(personalAssets?: PersonalAssetsInput): boolean {
    const properties = personalAssets?.user_properties ?? []

    return properties.some((property) => property.has_outstanding_mortgage)
  }

  hasProvidedMortgageDetails(propertyInput: PropertyInput): boolean {
    const mortgage = propertyInput.mortgage ?? {}
    const numAnsweredQuestions = Object.values(mortgage).filter(
      (answer: unknown) => {
        return answer && answer.toString().length !== 0
      },
    ).length

    return (
      propertyInput.has_outstanding_mortgage === true &&
      mortgage !== undefined &&
      numAnsweredQuestions >= 5
    )
  }
}

const compassPersonalAssetsService = new CompassPersonalAssetsService()

export default compassPersonalAssetsService
