import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@rq-ratings/pro-light-svg-icons'
import React from 'react'

import PlainButton from '../../PlainButton'

const ReloadButton: React.FC = () => (
  <PlainButton
    className="position-absolute top-0 end-0 text-reset hover-rotate"
    title="Reload page"
    onClick={() => window.location.reload()}
  >
    <FontAwesomeIcon icon={faSync} style={{ fontSize: '22px' }} />
  </PlainButton>
)

export default ReloadButton
