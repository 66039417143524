import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@rq-ratings/pro-solid-svg-icons'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import React, { useState } from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

import { filterText } from '../../../pages/inviteToRq/helpers'

type Props = ButtonProps & {
  buttonText: string
  textToCopy: string
  format?: 'text/html' | 'text/plain'
}

const CopyLink: React.FC<Props> = ({
  variant,
  buttonText,
  textToCopy,
  format = 'text/plain',
  onClick,
  className,
  ...props
}) => {
  const [clicked, setClicked] = useState(false)

  const isLinkVariant = variant === 'link'

  return (
    <Button
      {...props}
      variant={clicked && !isLinkVariant ? 'success' : variant}
      onClick={(event) => {
        if (typeof onClick === 'function') {
          onClick(event)
        }

        if (textToCopy.length) {
          copy(filterText(textToCopy), {
            format: format,
          })
        }

        setClicked(true)

        setTimeout(() => {
          setClicked(false)
        }, 1500)
      }}
      className={classNames(
        { 'text-success': clicked && isLinkVariant },
        className,
      )}
    >
      {clicked ? (
        <>
          <FontAwesomeIcon icon={faCircleCheck} className="me-2" />
          Copied
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faLink} className="me-2" />
          {buttonText}
        </>
      )}
    </Button>
  )
}

export default CopyLink
