import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ClientMetadataItem } from '../../types/responses/client-metadata'
import { RootState } from '../store'

interface ClientMetadataState {
  metadata?: ClientMetadataItem
  isFetched: boolean
}

const INITIAL_STATE: ClientMetadataState = {
  isFetched: false,
}

const clientMetadata = createSlice({
  name: 'clientMetadata',
  initialState: INITIAL_STATE,
  reducers: {
    setClientMetadata: (state, action: PayloadAction<ClientMetadataItem>) => {
      state.metadata = action.payload
      state.isFetched = true
    },
  },
})

export const selectClientMetadataSlice = (state: RootState) =>
  state.clientMetadata

export const selectClientMetadata = createSelector(
  selectClientMetadataSlice,
  (slice) => slice.metadata,
)

export const { setClientMetadata } = clientMetadata.actions

export const clientMetadataReducer = clientMetadata.reducer
