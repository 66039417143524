import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'
import { selectShouldShowCommercialAgreementsModal } from './commercialAgreementsModal'

export const panel = createSlice({
  name: 'panel',
  initialState: {
    shouldShowFirstRelationshipRequestApprovedModal: false,
  },
  reducers: {
    setShouldShowFirstRelationshipRequestApprovedModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.shouldShowFirstRelationshipRequestApprovedModal = action.payload
    },
  },
})

export const selectPanel = (state: RootState) => state.panel

export const selectShouldShowFirstRelationshipRequestApprovedModal =
  createSelector(
    selectPanel,
    selectShouldShowCommercialAgreementsModal,
    (panel, shouldShowCommercialAgreementsModal) => {
      // We don't to show this modal if the user needs to review commercial agreements
      return (
        panel.shouldShowFirstRelationshipRequestApprovedModal &&
        !shouldShowCommercialAgreementsModal
      )
    },
  )

export const { setShouldShowFirstRelationshipRequestApprovedModal } =
  panel.actions

export const panelReducer = panel.reducer
