import { ObjectMap } from '../../../../../types/misc'
import {
  CreditCardInput,
  FinancialDependantInput,
  LiabilitiesInput,
  MortgageInput,
  MortgageTermType,
  MortgageType,
  PersonalLoanInput,
} from '../../utils/types'

export const LiabilitiesInputFields: ObjectMap<LiabilitiesInput> = {
  has_financial_dependants: 'has_financial_dependants',
  financial_dependants: 'financial_dependants',
  family_protection_options: 'family_protection_options',
  will_last_updated_date: 'will_last_updated_date',
  professional_protection_options: 'professional_protection_options',
  has_personal_loans: 'has_personal_loans',
  has_credit_card: 'has_credit_card',
  personal_loans: 'personal_loans',
  credit_card: 'credit_card',
}

export const MortgageInputFields: ObjectMap<MortgageInput> = {
  mortgage_type: 'mortgage_type',
  mortgage_outstanding_balance: 'mortgage_outstanding_balance',
  mortgage_interest_rate: 'mortgage_interest_rate',
  mortgage_capital_repaid_month: 'mortgage_capital_repaid_month',
  mortgage_capital_repaid_year: 'mortgage_capital_repaid_year',
  fixed_rate_mortgage: 'fixed_rate_mortgage',
  mortgage_term_end_month: 'mortgage_term_end_month',
  mortgage_term_end_year: 'mortgage_term_end_year',
  had_mortgage_broker: 'had_mortgage_broker',
  mortgage_broker_name: 'mortgage_broker_name',
}

export const PersonalLoanInputFields: ObjectMap<PersonalLoanInput> = {
  outstanding_balance: 'outstanding_balance',
  interest_rate: 'interest_rate',
  repayment_year: 'repayment_year',
  repayment_month: 'repayment_month',
}

export const CreditCardInputFields: ObjectMap<CreditCardInput> = {
  credit_card_outstanding_balance: 'credit_card_outstanding_balance',
}

export const FinancialDependantInputFields: ObjectMap<FinancialDependantInput> =
  {
    financial_dependant_birth_month: 'financial_dependant_birth_month',
    financial_dependant_birth_year: 'financial_dependant_birth_year',
  }

export const MORTGAGE_TYPE = {
  interestOnly: 'Interest only',
  capitalRepayment: 'Capital repayment',
} satisfies Record<string, MortgageType>

export const MORTGAGE_TERM_TYPE = {
  tracker: 'Tracker',
  fixedRate: 'Fixed rate',
} satisfies Record<string, MortgageTermType>
