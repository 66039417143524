import classNames from 'classnames'
import React from 'react'
import { matchPath } from 'react-router-dom'

import ActionLink, { ActionLinkProps } from './ActionLink'

type Props = ActionLinkProps & {
  label: React.ReactNode
}

const NavigationButton: React.FC<Props> = ({
  className,
  label,
  to,
  ...props
}) => {
  const isActive = window.location.pathname
    ? !!matchPath(
        {
          path: window.location.pathname,
          end: false,
        },
        to as string,
      )
    : false

  return (
    <ActionLink
      to={to}
      className={classNames(
        'btn btn-navigation box-shadow w-100 w-md-auto',
        isActive && 'active',
        className,
      )}
      {...props}
    >
      {label}
    </ActionLink>
  )
}

export default NavigationButton
