import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import FormGroup from '../form/FormGroup'
import MultiCheckboxInput from '../form/MultiSelectCheckbox'
import TextInput from '../form/TextInput'
import ActionButton from './ActionButton'

interface Props {
  companyName: string
  initialValues?: RequestCallbackFormInput
  onSubmit: (values: RequestCallbackFormInput) => void
}

export interface RequestCallbackFormInput {
  times: string[]
  telephone: string
}

const RequestReferralCallbackFormFields: Record<
  keyof RequestCallbackFormInput,
  keyof RequestCallbackFormInput
> = {
  times: 'times',
  telephone: 'telephone',
}

const RequestReferralCallbackForm: React.FC<Props> = ({
  companyName,
  initialValues,
  onSubmit,
}) => {
  const validationSchema = Yup.object().shape({
    [RequestReferralCallbackFormFields.times]: Yup.array()
      .min(1, 'Please select at least one callback time')
      .required('required'),
    [RequestReferralCallbackFormFields.telephone]: Yup.string().required(
      'Your telephone is required',
    ),
  })

  return (
    <Formik
      initialValues={initialValues ?? { times: [], telephone: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <>
            <h4 className="mb-4">Request a callback from {companyName}</h4>

            <p>
              Please provide the telephone number you would like {companyName}{' '}
              to contact you on.
            </p>

            <FormGroup>
              <TextInput
                label="Telephone *"
                name={RequestReferralCallbackFormFields.telephone}
              />
            </FormGroup>

            <FormGroup>
              <MultiCheckboxInput
                label="Preferred callback time"
                name={RequestReferralCallbackFormFields.times}
                compact
                options={[
                  { label: 'Morning', value: 'morning' },
                  { label: 'Afternoon', value: 'afternoon' },
                  { label: 'Evening', value: 'evening' },
                ]}
              />
            </FormGroup>

            <div className="d-flex justify-content-end mt-4">
              <ActionButton
                size="lg"
                type="submit"
                isProcessing={isSubmitting}
                className="px-4"
              >
                Send request
              </ActionButton>
            </div>
          </>
        </Form>
      )}
    </Formik>
  )
}

export default RequestReferralCallbackForm
