import { UpdateOnboardingChecklistRequest } from '../../types/requests/onboarding-checklists'
import {
  OnboardingChecklistItem,
  UpdateOnboardingChecklistResponse,
} from '../../types/responses/onboarding-checklists'
import apiService from './apiService'

class OnboardingChecklistService {
  private endpoint = '/v1/onboarding-checklists'

  async getChecklist(checklistId: number): Promise<OnboardingChecklistItem> {
    const response = await apiService.get(`${this.endpoint}/${checklistId}`)

    return response.data
  }

  async updateChecklist(
    checklistId: number,
    request: UpdateOnboardingChecklistRequest,
  ): Promise<UpdateOnboardingChecklistResponse> {
    const response = await apiService.patch(
      `${this.endpoint}/${checklistId}`,
      request,
    )

    return response.data
  }

  async completeChecklist(
    checklistId: number,
  ): Promise<UpdateOnboardingChecklistResponse> {
    const response = await apiService.post(
      `${this.endpoint}/${checklistId}/complete`,
      {},
    )

    return response.data
  }
}

const onboardingChecklistService = new OnboardingChecklistService()

export default onboardingChecklistService
