import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface VideoModalState {
  videoUrl: string | null
}

const INITIAL_STATE: VideoModalState = {
  videoUrl: null,
}

const videoModal = createSlice({
  name: 'videoModal',
  initialState: INITIAL_STATE,
  reducers: {
    openVideoModal: (state, action: PayloadAction<string>) => {
      state.videoUrl = action.payload
    },

    hideVideoModal: (state) => {
      state.videoUrl = null
    },
  },
})

export const { openVideoModal, hideVideoModal } = videoModal.actions

export const selectVideoModal = (state: RootState) => state.demoVideo

export const selectVideoModalVideoUrl = createSelector(
  selectVideoModal,
  (demoVideo) => demoVideo.videoUrl,
)

export const demoVideoReducer = videoModal.reducer
