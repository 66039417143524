import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Button, FormLabel, Modal } from 'react-bootstrap'

import ActionButton from '../../../../../components/misc/ActionButton'
import PlainActionButton from '../../../../../components/misc/PlainActionButton'
import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import { useShowGenericServerError } from '../../../../../hooks/useShowGenericServerError'
import { QUERY_KEYS } from '../../../../../lib/queryKeys'
import rqCertifiedAssessmentService from '../../../../../lib/services/rqCertifiedAssessmentService'

interface Props {
  onConfirm: () => void
  onHide: () => void
}

const ConfirmNdaModal: React.FC<Props> = ({ onConfirm, onHide }) => {
  const currentCompany = useCurrentCompanyOrFail()
  const [hasAgreedToNda, setHasAgreedToNda] = useState(false)
  const createNdaMutation = useCreateNdaMutation()
  const confirmNdaMutation = useConfirmNdaMutation()
  const isCreatingNda = createNdaMutation.isPending
  const isConfirmingNda = confirmNdaMutation.isPending

  const ndaFileUrl = createNdaMutation.data?.ndaFileUrl

  return (
    <>
      <Modal show onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Non-Disclosure Agreement</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-check">
            <FormLabel className="d-flex gap-2">
              <input
                type="checkbox"
                checked={hasAgreedToNda}
                className="form-check-input"
                onChange={(event) => {
                  setHasAgreedToNda(event.target.checked)
                }}
              />

              <span>
                By ticking this box, {currentCompany.presentationName} confirms
                its intention to be legally bound by the provisions of the{' '}
                {ndaFileUrl ? (
                  <a href={ndaFileUrl} target="_blank" rel="noreferrer">
                    Non-Disclosure Agreement.
                  </a>
                ) : (
                  <PlainActionButton
                    onClick={() => createNdaMutation.mutate(currentCompany.id)}
                    isProcessing={isCreatingNda}
                    isProcessingText="Non-Disclosure Agreement"
                  >
                    Non-Disclosure Agreement.
                  </PlainActionButton>
                )}
              </span>
            </FormLabel>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <Button variant="outline-secondary" onClick={onHide}>
            Close
          </Button>

          <ActionButton
            disabled={!hasAgreedToNda || isConfirmingNda}
            isProcessing={isConfirmingNda}
            variant="primary"
            onClick={() => {
              confirmNdaMutation.mutate(currentCompany.id, {
                onSuccess: () => onConfirm(),
              })
            }}
          >
            Start application
          </ActionButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function useCreateNdaMutation() {
  const showGenericServerError = useShowGenericServerError()

  return useMutation({
    mutationFn: async (companyId: number) =>
      rqCertifiedAssessmentService.createNdaPdf(companyId),
    onSuccess: (assessment) => {
      window.location.href = assessment.ndaFileUrl
    },
    onError: showGenericServerError,
  })
}

function useConfirmNdaMutation() {
  const queryClient = useQueryClient()
  const showGenericServerError = useShowGenericServerError()

  return useMutation({
    mutationFn: async (companyId: number) =>
      rqCertifiedAssessmentService.confirmNda(companyId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.rqCertifiedAssessment,
      })
    },
    onError: showGenericServerError,
  })
}

export default ConfirmNdaModal
