import React from 'react'

import { useRqCertifiedAssessmentOrFail } from '../../../../../hooks/useRqCertifiedAssessmentQuery'

const ContinueApplicationButtons: React.FC = () => {
  const assessment = useRqCertifiedAssessmentOrFail()

  return (
    <a
      href={assessment.assessmentUrl}
      className="btn btn-primary btn-fluid"
      target="_blank"
      rel="noreferrer"
    >
      Continue application
    </a>
  )
}

export default ContinueApplicationButtons
