import { IfaDetailsFormValues } from './components/IfaDetailsForm/IfaDetailsForm'

export const IFA_DETAILS_FORM_FIELDS: Record<
  keyof IfaDetailsFormValues,
  keyof IfaDetailsFormValues
> = {
  isTransactionalWork: 'isTransactionalWork',
  hasMinInvestableAssetsRequirement: 'hasMinInvestableAssetsRequirement',
  minInvestableAssets: 'minInvestableAssets',
}
