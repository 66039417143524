import { useEffect } from 'react'
export function useGlobalEventListeners() {
  useEffect(() => {
    // https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
    function disableScrollingNumberInputs() {
      const activeElement = document.activeElement

      const isNumberInput =
        activeElement instanceof HTMLInputElement &&
        activeElement.type === 'number'

      if (isNumberInput) {
        activeElement.blur()
      }
    }

    document.addEventListener('wheel', disableScrollingNumberInputs)

    return () => {
      document.removeEventListener('wheel', disableScrollingNumberInputs)
    }
  })
}
