import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DATE_FORMATS } from '../../lib/constants'
import { isValidDateFormat } from '../../lib/helpers/helperFunctions'
import { LatestCompassReportItem } from '../../types/responses/compass-reports'
import { RootState } from '../store'
import { selectIsAuthenticatedClient } from './session'

interface ClientPortalState {
  latestCompassReport?: LatestCompassReportItem
}

const INITIAL_STATE: ClientPortalState = {
  latestCompassReport: undefined,
}

const clientPortal = createSlice({
  name: 'clientPortal',
  initialState: INITIAL_STATE,
  reducers: {
    setLatestCompassReport(
      state,
      action: PayloadAction<LatestCompassReportItem>,
    ) {
      state.latestCompassReport = action.payload
    },
  },
})

export const selectClientPortal = (state: RootState) => state.clientPortal

export const selectLatestCompassReport = createSelector(
  selectClientPortal,
  (clientPortal) => clientPortal.latestCompassReport,
)

export const selectIsClientUserWithoutCompassReport = createSelector(
  selectIsAuthenticatedClient,
  selectLatestCompassReport,
  (isAuthenticatedClient, latestCompassReport) =>
    isAuthenticatedClient && !latestCompassReport,
)

export const selectClientDateOfBirth = createSelector(
  selectLatestCompassReport,
  (latestCompassReport) => {
    const dateOfBirth =
      latestCompassReport?.jsonPayload?.input?.aboutYourClient
        ?.user_date_of_birth

    const isValidDateOfBirth =
      dateOfBirth && isValidDateFormat(dateOfBirth, DATE_FORMATS.DAY_MONTH_YEAR)

    return isValidDateOfBirth ? dateOfBirth : undefined
  },
)

export const { setLatestCompassReport } = clientPortal.actions

export const clientPortalReducer = clientPortal.reducer
