import classNames from 'classnames'
import React from 'react'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
}

const NavigationButtonsContainer: React.FC<Props> = ({
  className,
  ...props
}) => (
  <div
    className={classNames(
      'd-flex flex-row flex-wrap w-100 w-md-auto gap-2 gap-md-3',
      className,
    )}
    {...props}
  />
)

export default NavigationButtonsContainer
