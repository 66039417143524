import React, { PropsWithChildren } from 'react'

import { useReferralWithESignatureDetailsQuery } from '../../hooks/useReferralWithESignatureDetailsQuery'
import InvalidOrExpiredPage from '../../pages/InvalidOrExpiredPage'
import LoadingWidget from '../widgets/LoadingWidget'

const ReferralWithESignatureDetailsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const referralWithESignatureQuery = useReferralWithESignatureDetailsQuery()

  if (referralWithESignatureQuery.isLoading) {
    return <LoadingWidget />
  }

  const error = referralWithESignatureQuery.error

  // An error most likely means the eSignature has already been signed so we
  // can show a friendly message to the user.
  if (error || !referralWithESignatureQuery.data) {
    return (
      <InvalidOrExpiredPage
        content={
          <p>
            This link is either invalid or has expired. If you already submitted
            the eSignature letter, there is nothing else for you to do.
          </p>
        }
      />
    )
  }

  return children
}

export default ReferralWithESignatureDetailsProvider
