import React, { Suspense } from 'react'
import { HelpCircle } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { useMount } from 'react-use'

import compassIcon from '../../../assets/img/icons/compass.svg'
import contactsIcon from '../../../assets/img/icons/contacts.svg'
import professionalNetworkIcon from '../../../assets/img/icons/professional-network.svg'
import referClientIcon from '../../../assets/img/icons/refer-client.svg'
import referralsIcon from '../../../assets/img/icons/referrals.svg'
import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import { useAppStateQuery } from '../../../hooks/useAppStateQuery'
import { useCompanyConsentQuery } from '../../../hooks/useCompanyConsentQuery'
import { useNotificationsCount } from '../../../hooks/useNotificationsCount'
import { COMPANY_DASHBOARD_SIDEBAR_LINKS } from '../../../lib/constants'
import { matchesPaths } from '../../../lib/helpers/routeHelpers'
import { ROUTES } from '../../../lib/routes'
import routeService from '../../../lib/services/routeService'
import { LAYOUTS, setLayout } from '../../../redux/slices/layout'
import { selectIsOnboardingChecklistIncomplete } from '../../../redux/slices/onboardingChecklist'
import { REFER_CLIENT_STEP } from '../../../redux/slices/referClient'
import Main from '../../misc/Main'
import RqRoadmapBanner from '../../misc/RqRoadmapBanner'
import SentryErrorBoundary from '../../misc/SentryErrorBoundary'
import SvgImage from '../../misc/SvgImage'
import BookMeetingModal from '../../modals/BookMeetingModal'
import CompanyConsentModal from '../../modals/CompanyConsentModal'
import VideoModal from '../../modals/VideoModal'
import LoadingWidget from '../../widgets/LoadingWidget'
import Footer from './components/Footer'
import Navbar from './components/Navbar/Navbar'
import Sidebar, { SidebarPage } from './components/sidebar/Sidebar'

interface Props {
  children: React.ReactNode
}

const CompanyDashboardLayout: React.FC<Props> = ({ children }) => {
  const shouldShowOnboardingChecklist = useAppSelector(
    selectIsOnboardingChecklistIncomplete,
  )
  const notificationsCount = useNotificationsCount()
  const location = useLocation()
  const companyConsentQuery = useCompanyConsentQuery()
  const dispatch = useAppDispatch()

  function buildSidebarLinks(): SidebarPage[] {
    const links: SidebarPage[] = []

    if (shouldShowOnboardingChecklist) {
      links.push({
        title: 'Getting started with RQ',
        href: ROUTES.gettingStarted,
        icon: <HelpCircle style={{ color: '#0D96FF' }} />,
      })
    }

    const professionalNetworkLink: SidebarPage = {
      href: ROUTES.professionalNetworkRelationships,
      icon: (
        <SvgImage src={professionalNetworkIcon} alt="Professional network" />
      ),
      title: COMPANY_DASHBOARD_SIDEBAR_LINKS.yourRelationships,
      badge:
        notificationsCount.panels > 0
          ? `${notificationsCount.panels}`
          : undefined,
    }

    const contactsLink: SidebarPage = {
      title: COMPANY_DASHBOARD_SIDEBAR_LINKS.contacts,
      href: ROUTES.contacts,
      icon: <SvgImage src={contactsIcon} alt="Contacts" />,
    }

    const referralsLink: SidebarPage = {
      title: COMPANY_DASHBOARD_SIDEBAR_LINKS.referrals,
      href: ROUTES.referrals,
      icon: <SvgImage src={referralsIcon} alt="Referrals" />,
      badge:
        notificationsCount.referrals > 0
          ? `${notificationsCount.referrals}`
          : undefined,
    }

    const referYourClientLink: SidebarPage = {
      title: COMPANY_DASHBOARD_SIDEBAR_LINKS.referYourClient,
      href: routeService.referClient({
        step: Object.keys(REFER_CLIENT_STEP)[0],
      }),
      icon: <SvgImage src={referClientIcon} alt="Refer a client" />,
    }

    const compassLink: SidebarPage = {
      title: COMPANY_DASHBOARD_SIDEBAR_LINKS.compass,
      href: ROUTES.companyCompassDashboard,
      icon: <SvgImage src={compassIcon} alt="Compass" />,
    }

    links.push(
      professionalNetworkLink,
      contactsLink,
      referralsLink,
      referYourClientLink,
      compassLink,
    )

    return links
  }

  const sidebarLinks = buildSidebarLinks()

  useMount(() => {
    dispatch(setLayout(LAYOUTS.companyDashboard))
  })

  const appStateQuery = useAppStateQuery()

  if (appStateQuery.isLoading) {
    return <LoadingWidget />
  }

  // Some routes have dedicated loading skeletons, so prefer to show them
  // instead of the default loading widget
  function renderLoadingFallback() {
    const routesWithOwnFallback = [
      ROUTES.companyCompassSnapshots,
      ROUTES.contacts,
      ROUTES.professionalNetworkRelationships,
      ROUTES.referrals,
    ]

    return matchesPaths(location.pathname, routesWithOwnFallback) ? null : (
      <LoadingWidget />
    )
  }

  return (
    <>
      <RqRoadmapBanner />

      {companyConsentQuery.data && !companyConsentQuery.isFetching && (
        <CompanyConsentModal />
      )}

      <div className="wrapper">
        <Sidebar items={[{ pages: sidebarLinks }]} />

        <Main>
          <Navbar />

          <div className="content">
            <SentryErrorBoundary key={location.pathname}>
              <Suspense fallback={renderLoadingFallback()}>{children}</Suspense>
            </SentryErrorBoundary>
          </div>
          <Footer />
        </Main>

        <VideoModal />
        <BookMeetingModal />
      </div>
    </>
  )
}

export default CompanyDashboardLayout
