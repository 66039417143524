import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { COMPANY_TYPE_CODE, REFERRAL_STATUS } from '../../lib/constants'
import { companyTypeHasServices } from '../../lib/helpers/companyHelpers'
import companyTypeService from '../../lib/services/companyTypeService'
import regulatorService from '../../lib/services/regulatorService'
import { HydraMember } from '../../types/api'
import { CompanyTypeCode, ReferralStatus } from '../../types/misc'
import {
  CommonData,
  CompanyTypeItem,
  RegulatorItem,
  ServiceAreaItem,
} from '../../types/responses/common-data'
import { RootState } from '../store'
import { selectCurrentCompany } from './session'

export const initialState: CommonData = {
  serviceAreas: [],
  fcaServiceAreas: [],
  fcaServiceTypes: [],
  expertiseAreas: [],
  referralStatuses: [],
  regulators: [],
  companyTypes: [],
  networks: [],
}

const sliceName = 'commonData'

export const commonData = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCommonData: (_state, { payload }: PayloadAction<CommonData>) => {
      return payload
    },
  },
})

const selectCommonData = (state: RootState) => state.commonData

export const selectServiceAreas = createSelector(
  selectCommonData,
  (commonData) => commonData.serviceAreas,
)

export const selectActiveServiceAreas = createSelector(
  selectCommonData,
  (commonData) =>
    commonData.serviceAreas.filter(
      (serviceArea) => serviceArea.companyTypes.length > 0,
    ),
)

export const selectActiveServiceAreasByCompanyTypeCode = (
  companyTypeCode: CompanyTypeCode,
) => {
  return createSelector(
    selectActiveServiceAreas,
    selectCompanyTypeByCode(companyTypeCode),
    (serviceAreas, companyType) => {
      return serviceAreas.filter((serviceArea) => {
        return serviceArea.companyTypes.some(
          (companyTypeIri) => companyTypeIri === companyType['@id'],
        )
      })
    },
  )
}

export const selectSortedServiceAreas = createSelector(
  selectServiceAreas,
  (serviceAreas) => [...serviceAreas].sort((a, b) => a.sortOrder - b.sortOrder),
)

export const selectCompanyTypes = createSelector(
  selectCommonData,
  (commonData) => commonData.companyTypes,
)

export const selectOtherCompanyTypes = (
  currentCompanyTypeCode: CompanyTypeCode,
) =>
  createSelector(selectCompanyTypes, (companyTypes) =>
    companyTypes.filter(
      (companyType) => companyType.code !== currentCompanyTypeCode,
    ),
  )

export const selectMainCompanyTypes = createSelector(
  selectCompanyTypes,
  (companyTypes) => companyTypes.filter((companyType) => companyType.main),
)

export const selectNonMainAndOtherCompanyTypes = createSelector(
  selectCompanyTypes,
  (companyTypes) =>
    companyTypes.filter(
      (companyType) =>
        companyType.main === false ||
        companyType.code === COMPANY_TYPE_CODE.other,
    ),
)

export const selectFcaCompanyTypes = createSelector(
  selectCompanyTypes,
  (companyTypes) =>
    companyTypes.filter((companyType) => companyType.fca === true),
)

export const selectCompanyTypesWithServices = createSelector(
  selectCompanyTypes,
  selectServiceAreas,
  (companyTypes, serviceAreas) => {
    return companyTypes.filter((companyType) =>
      companyTypeHasServices(companyType, serviceAreas),
    )
  },
)

export const selectCompanyTypeByIRI = (iri: IRI) =>
  createSelector(selectCompanyTypes, (companyTypes) => {
    return companyTypes.find((companyType) => companyType['@id'] === iri)
  })

export const selectExpertiseAreas = createSelector(
  selectCommonData,
  (commonData) => commonData.expertiseAreas,
)

export const selectExpertiseAreasByCompanyTypeIri = (companyTypeIri: IRI) => {
  return createSelector(selectExpertiseAreas, (expertiseAreas) => {
    return expertiseAreas.filter((expertiseArea) => {
      return expertiseArea.companyTypes.some(
        (expertiseAreaCompanyTypeIri) =>
          expertiseAreaCompanyTypeIri === companyTypeIri,
      )
    })
  })
}

export const selectReferralStatuses = createSelector(
  selectCommonData,
  selectCurrentCompany,
  (commonData, currentCompany) => {
    if (!currentCompany?.isPco && !currentCompany?.hasPcoReferralPartner) {
      return commonData.referralStatuses.filter(
        (referralStatus) =>
          referralStatus.code !== REFERRAL_STATUS.client_details_passed_to_pco,
      )
    }

    return commonData.referralStatuses
  },
)

export const selectReferralStatusByCode = (code: ReferralStatus) =>
  createSelector(selectCommonData, (commonData) =>
    commonData.referralStatuses.find(
      (referralStatus) => referralStatus.code === code,
    ),
  )

export const selectFcaServiceAreas = createSelector(
  selectCommonData,
  (commonData) => commonData.fcaServiceAreas,
)

export const selectServiceAreasByCompanyTypeCode = (
  companyTypeCode: CompanyTypeCode,
) => {
  return createSelector(
    selectServiceAreas,
    selectCompanyTypeByCode(companyTypeCode),
    (serviceAreas, companyType) => {
      return serviceAreas.filter((serviceArea) => {
        return serviceArea.companyTypes.some(
          (companyTypeIri) => companyTypeIri === companyType['@id'],
        )
      })
    },
  )
}

export const selectServiceAreasByCompanyTypeIri = (companyTypeIri: IRI) => {
  return createSelector(selectServiceAreas, (serviceAreas) => {
    return serviceAreas.filter((serviceArea) => {
      return serviceArea.companyTypes.some(
        (serviceAreaCompanyTypeIri) =>
          serviceAreaCompanyTypeIri === companyTypeIri,
      )
    })
  })
}

export function getServiceAreasByIds(
  serviceAreas: ServiceAreaItem[],
  ids: number[],
) {
  return ids.length > 0
    ? serviceAreas.filter((serviceArea) => ids.includes(serviceArea.id))
    : []
}

export const selectFcaServiceAreasByIds = (ids: number[]) => {
  return createSelector(selectFcaServiceAreas, (fcaServiceAreas) =>
    ids.length > 0
      ? fcaServiceAreas.filter((fcaServiceArea) =>
          ids.includes(fcaServiceArea.id),
        )
      : [],
  )
}

export const selectRestrictedFcaServiceAreas = createSelector(
  selectFcaServiceAreas,
  (fcaServiceAreas) =>
    fcaServiceAreas.filter(
      (fcaServiceArea) => fcaServiceArea.isRegulatedActivity,
    ),
)

export const selectRestrictedFcaServiceAreaIds = createSelector(
  selectRestrictedFcaServiceAreas,
  (fcaServiceAreas) =>
    fcaServiceAreas.map((fcaServiceArea) => fcaServiceArea.id),
)

export const selectRegulators = createSelector(
  selectCommonData,
  (commonData) => commonData.regulators,
)

export const selectRegulatorsByCompanyType = (code: CompanyTypeCode) =>
  createSelector(selectRegulators, (regulators) =>
    regulatorService.getRegulatorsByCompanyType(regulators, code),
  )

export const selectOtherRegulator = createSelector(
  selectRegulators,
  (regulators) =>
    regulators.find(
      (regulator) => regulator.name === 'Other',
    ) as HydraMember<RegulatorItem>,
)

export const selectCompanyTypeByCode = (code: CompanyTypeCode) =>
  createSelector(selectCompanyTypes, (companyTypes) => {
    return companyTypeService.getCompanyTypeByCodeOrFail(companyTypes, code)
  })

export const selectCompanyTypeById = (id: number) => {
  return createSelector(selectCompanyTypes, (companyTypes): CompanyTypeItem => {
    const companyType = companyTypes.find(
      (companyType) => companyType.id === id,
    )

    if (!companyType) {
      throw new Error(`No company type found with with ID ${id}`)
    }

    return companyType
  })
}

export const selectCompanyTypeNameByCode = (
  code: CompanyTypeCode,
  shortName?: boolean,
) => {
  return createSelector(selectCompanyTypeByCode(code), (companyType) => {
    return shortName ? companyType.shortName : companyType.name
  })
}

export const selectNetworks = createSelector(
  selectCommonData,
  (commonData) => commonData.networks,
)

export const commonDataReducer = commonData.reducer

export const { setCommonData } = commonData.actions
