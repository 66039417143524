import React from 'react'

import { ROUTES } from '../../../../lib/routes'
import NavigationButton from '../../../misc/NavigationButton'
import NavigationButtonsContainer from '../../../misc/NavigationButtonsContainer'
import PageTitle from '../../../typography/PageTitle'

const RqCertifiedPageHeader: React.FC = () => (
  <>
    <PageTitle>RQ Certified</PageTitle>

    <NavigationButtonsContainer className="my-4">
      <NavigationButton
        to={ROUTES.rqCertifiedApplicationStatus}
        label="Application"
      />

      <NavigationButton
        to={ROUTES.rqCertifiedApplicationProcess}
        label="Application Process"
      />

      <NavigationButton to={ROUTES.rqCertifiedGlossary} label="Glossary" />

      <NavigationButton to={ROUTES.rqCertifiedPdfGuides} label="PDF Guides" />
    </NavigationButtonsContainer>
  </>
)

export default RqCertifiedPageHeader
