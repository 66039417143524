import { DEFAULT_FINANCIAL_INDEPENDENCE_AGE } from '../../../components/flows/CompassFlow/utils/constants'
import { InterestsInput } from '../../../components/flows/CompassFlow/utils/types'

class CompassInterestsService {
  getUserFinancialIndependenceAge(interests?: InterestsInput): number {
    return (
      interests?.user_financial_independence_age ||
      DEFAULT_FINANCIAL_INDEPENDENCE_AGE
    )
  }

  getPartnerFinancialIndependenceAge(interests?: InterestsInput): number {
    return (
      interests?.partner_financial_independence_age ||
      DEFAULT_FINANCIAL_INDEPENDENCE_AGE
    )
  }

  isUserAndPartnerFinancialIndependenceAgeSame(
    interests?: InterestsInput,
  ): boolean {
    const userFinancialIndependenceAge =
      interests?.user_financial_independence_age ||
      DEFAULT_FINANCIAL_INDEPENDENCE_AGE
    const partnerFinancialIndependenceAge =
      interests?.partner_financial_independence_age ||
      DEFAULT_FINANCIAL_INDEPENDENCE_AGE

    return userFinancialIndependenceAge === partnerFinancialIndependenceAge
  }
}

const compassInterestsService = new CompassInterestsService()

export default compassInterestsService
