import { COMPANY_TYPE_CODE } from '../../../../lib/constants'
import { InvitationEmails } from '../../../../pages/inviteToRq/types'
import { ToFcaFirmEmail } from './ToFcaFirmEmail'

export const FromAccountantEmails: InvitationEmails = {
  accountant: null,
  solicitor: {
    subject: 'Referring clients to your firm',
    renderText: (invitationLink) => (
      <>
        <p>Hi [solicitor],</p>
        <p>
          We have just signed up to the collaboration platform RQ so that we can
          work more closely with your firm. From now on we would like to use RQ
          to manage our collaboration with you.
        </p>

        <p>From our point of view as Accountants, RQ:</p>

        <p>
          <strong>1) Identifies</strong> which of our clients are going to need
          your services (LPAs, Will drafting, Estate planning, transaction
          support and so on) at some point in the future.
        </p>

        <p>
          <strong>2) Ensures</strong> that we are fulfilling our regulatory
          obligations when introducing and collaborating with your firm.
        </p>

        <p>
          <strong>3) Streamlines</strong> data-sharing between our firms, saving
          us both time and effort on the administrative side and giving us both
          an up-to-date view of a client's journey. This will give our joint
          clients a more seamless experience.
        </p>

        <p>
          <b>What to do</b>
        </p>
        <p>
          Please follow this link to set up your account on the RQ
          platform:&nbsp;
          <a href={invitationLink}>{invitationLink}</a>
          <br />
          <em>
            Note: if you use this link to sign up within the next 14 days, you
            will receive 15% off your first year’s subscription with RQ.
          </em>
        </p>

        <p>
          Alternatively, let me know a time that works for your firm and we can
          coordinate a three way call with the RQ team where they can answer any
          questions you may have. If easier, you can book a meeting directly
          with the&nbsp;
          <a href="https://meetings-eu1.hubspot.com/johnny-ridd/rq-demo">
            RQ team here
          </a>
          .
        </p>

        <p>
          Your single RQ subscription will allow you to invite all professionals
          you collaborate with – lawyers, mortgage advisers and others - to join
          you on RQ. This will ensure you are front of mind and bring your
          circle of trusted professionals even closer together.
        </p>

        <p>
          Yours,
          <br />
          [Your name]
        </p>
      </>
    ),
  },
  ifa: {
    subject: 'Referring clients to your firm',
    renderText: (invitationLink) => (
      <>
        <p>Hi [financial planner],</p>
        <p>
          We have just signed up to the collaboration platform RQ so that we can
          work more closely with your firm. From now on we would like to use RQ
          to manage our collaboration with you.
        </p>

        <p>From our point of view as Accountants, RQ:</p>

        <p>
          <strong>1) Identifies</strong> which of our clients will likely
          benefit from your financial planning support through a professional,
          repeatable and scalable discovery process.
        </p>

        <p>
          <strong>2) Ensures</strong> that we are fulfilling our regulatory
          obligations when introducing and collaborating with your firm.
        </p>

        <p>
          <strong>3) Streamlines</strong> data-sharing between our firms, saving
          us both time and effort on the administrative side and giving us both
          an up-to-date view of a client's journey. This will give our joint
          clients a more seamless experience.
        </p>

        <p>
          <b>What to do</b>
        </p>
        <p>
          Please follow this link to set up your account on the RQ
          platform:&nbsp;
          <a href={invitationLink}>{invitationLink}</a>.
        </p>

        <p>
          Alternatively, let me know a time that works for your firm and we can
          coordinate a three way call with the RQ team where they can answer any
          questions you may have. If easier, you can book a meeting directly
          with the&nbsp;
          <a href="https://meetings-eu1.hubspot.com/johnny-ridd/rq-demo">
            RQ team here
          </a>
          .
        </p>

        <p>
          Your single RQ subscription will allow you to invite all professionals
          you collaborate with – lawyers, mortgage advisers and others - to join
          you on RQ. This will ensure you are front of mind and bring your
          circle of trusted professionals even closer together.
        </p>

        <p>
          Yours,
          <br />
          [Your name]
        </p>
      </>
    ),
  },
  insurance_adviser: ToFcaFirmEmail.build(
    COMPANY_TYPE_CODE.accountant,
    COMPANY_TYPE_CODE.insurance_adviser,
  ),
  mortgage_adviser: ToFcaFirmEmail.build(
    COMPANY_TYPE_CODE.accountant,
    COMPANY_TYPE_CODE.mortgage_adviser,
  ),
}
