import { CreateSessionMetadataRequest } from '../../types/requests/session-metadata'
import { SessionMetadataItem } from '../../types/responses/session-metadata'
import apiService from './apiService'

class SessionService {
  private endpoint = '/v1/session-metadata'

  async createSessionMetadata(
    request: CreateSessionMetadataRequest,
  ): Promise<SessionMetadataItem> {
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }
}

const sessionMetadataService = new SessionService()

export default sessionMetadataService
