import queryString from 'query-string'
import { useSearchParams } from 'react-router-dom'

import { ROUTES } from '../../lib/routes'
import { ObjectMap } from '../../types/misc'

interface Params {
  backLabel?: string
  backTo?: string
}

const PARAMS: ObjectMap<Params> = {
  backLabel: 'backLabel',
  backTo: 'backTo',
}

export function buildBookMeetingUrl(params: Params = {}) {
  return `${ROUTES.bookMeeting}?${queryString.stringify(params)}`
}

export function useBookMeetingParams() {
  const [searchParams] = useSearchParams()

  return {
    backLabel: searchParams.get(PARAMS.backLabel),
    backTo: searchParams.get(PARAMS.backTo),
  }
}
