import { COMPANY_TYPE_CODE } from '../../../../lib/constants'
import { getCompanyTypePlaceholder } from '../../../../pages/inviteToRq/helpers'
import { InvitationEmail } from '../../../../pages/inviteToRq/types'

export const FromFcaFirmToSolicitorEmail = {
  build: (): InvitationEmail => {
    return {
      subject: 'Helping your COLP with private client referrals',
      renderText: (invitationLink: string) => {
        const companyTypePlaceholder = getCompanyTypePlaceholder(
          COMPANY_TYPE_CODE.solicitor,
        )

        return (
          <>
            <p>Hi [{companyTypePlaceholder}],</p>
            <p>
              We have just signed up to the collaboration platform RQ, so that
              we can work more closely with your firm. From now on, we would
              like to use RQ to manage our collaboration with your firm (you can
              use it for all the other professionals you introduce clients to,
              not just us)
            </p>

            <p>
              Based on the feedback of other {companyTypePlaceholder}s, RQ will:
            </p>

            <ol>
              <li>
                <b>Give firm-wide visibility</b> and a defined process for all
                referrals colleagues are making to third parties to ensure risk
                is being mitigated.
              </li>
              <li>
                <b>Ensure</b> you are fulfilling your regulatory obligations
                when introducing, sharing revenue and collaborating with other
                professionals.
              </li>
              <li>
                <b>Streamline</b> data-sharing between our firms, saving us both
                time and effort on the administrative side and giving us both an
                up-to-date view of a client's journey. This will give our joint
                clients a more seamless experience.
              </li>
            </ol>

            <p>
              <b>What to do</b>
            </p>
            <p>
              Please follow this link to set up your account on the RQ
              platform:&nbsp;
              <a href={invitationLink}>{invitationLink}</a>
              <br />
              <em>
                Note: use this link to sign up and the RQ platform is free for
                your firm to use.
              </em>
            </p>

            <p>
              Your single RQ subscription will allow you to invite all
              professionals you collaborate with – other financial planners,
              protection specialists and others - to join you on RQ. This will
              ensure you are front of mind and bring your circle of trusted
              professionals even closer together.
            </p>

            <p>
              Yours,
              <br />
              [Your name]
            </p>
          </>
        )
      },
    }
  },
}
