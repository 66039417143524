import { useMutation } from '@tanstack/react-query'
import { AxiosProgressEvent } from 'axios'
import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'

import useAppDispatch from '../../../../../hooks/useAppDispatch'
import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import companyService from '../../../../../lib/services/companyService'
import {
  endEdit,
  startEdit,
} from '../../../../../redux/slices/completeRegistrationPopup'
import { updateCurrentCompany } from '../../../../../redux/slices/session'
import { SessionMetadataCompany } from '../../../../../types/responses/session-metadata'
import UploadFile from '../../components/UploadFile/UploadFile'

const UploadVideo: React.FC = () => {
  const [progress, setProgress] = useState<number | null>(null)

  const dispatch = useAppDispatch()
  const currentCompany = useCurrentCompanyOrFail()

  const uploadVideoMutation = useMutation({
    onMutate: () => dispatch(startEdit()),
    mutationFn: async (
      file: File,
    ): Promise<Partial<SessionMetadataCompany>> => {
      return companyService.uploadVideo(
        currentCompany.id,
        file,
        // onUploadProgress:
        (data: AxiosProgressEvent) => {
          setProgress(
            data.total === undefined
              ? null
              : Math.round(100 * data.loaded) / data.total,
          )
        },
      )
    },
    onSuccess: async (data: Partial<SessionMetadataCompany>) => {
      dispatch(updateCurrentCompany(data))
      setProgress(null)
    },
    onSettled: () => dispatch(endEdit()),
  })

  return (
    <div className="h-100 d-flex flex-column pop-in">
      <div className="d-flex flex-grow-1 flex-column align-items-stretch justify-content-center w-100">
        <Alert className="d-flex justify-content-center" variant="light">
          <div>
            <p className="mb-0 p-3">
              Please share a video introducing you and your firm that can be
              added to your RQ profile.
            </p>
          </div>
        </Alert>

        <UploadFile
          uploadMutation={uploadVideoMutation}
          uploadProgressPercent={progress}
          // Based these on what's allowed on Vimeo. See the input's
          // `accept` attribute, on https://vimeo.com/upload/videos
          acceptableFileTypes={['video/*', '.mkv', '.m2ts']}
          previewType="video"
          currentFileUrl={currentCompany.videoUrl}
          incorrectFileTypeMessage="Please choose a valid video file."
          currentFileExistsButIsUnavailable={currentCompany.hasUnprocessedVideo}
        />
      </div>
    </div>
  )
}

export default UploadVideo
