import { FormikHelpers } from 'formik'
import * as yup from 'yup'

import {
  replaceObjectKeys,
  setFieldErrors,
} from '../../../../../../lib/helpers/helperFunctions'
import { yupSchemas } from '../../../../../../lib/helpers/yupSchemas'
import constraintViolationService from '../../../../../../lib/services/constraintViolationService'
import { ConstraintViolationListError } from '../../../../../../types/api'
import { ClientEntity } from '../../../../../../types/entities'
import { formatApiErrors } from '../../../utils/helpers'
import { AboutYourClientInput } from '../../../utils/types'
import { AboutYourClientInputFields } from '../constants'

export function getInitialValues(
  previousData?: AboutYourClientInput,
): AboutYourClientInput {
  return {
    existing_client: previousData?.existing_client,
    user_name_first: previousData?.user_name_first || '',
    user_name_last: previousData?.user_name_last || '',
    user_date_of_birth: previousData?.user_date_of_birth,
    user_email: previousData?.user_email || '',
    user_private_situation: previousData?.user_private_situation,
    user_professional_situation:
      previousData?.user_professional_situation || [],

    partner_name_first: previousData?.partner_name_first || '',
    partner_name_last: previousData?.partner_name_last || '',
    partner_date_of_birth: previousData?.partner_date_of_birth,
    partner_professional_situation:
      previousData?.partner_professional_situation || [],
  }
}

export function getValidationSchema(shouldShowPartnerQuestions: boolean) {
  const withoutPartnerValidationSchema = yup.object({
    [AboutYourClientInputFields.user_name_first]: yup
      .string()
      .required('First name is a required field'),
    [AboutYourClientInputFields.user_name_last]: yup
      .string()
      .required('Last name is a required field'),
    [AboutYourClientInputFields.user_date_of_birth]: yupSchemas
      .dayMonthYear()
      .required(),
    [AboutYourClientInputFields.user_email]: yup.string().required().email(),
    [AboutYourClientInputFields.user_professional_situation]: yup
      .array()
      .of(yup.string())
      .min(1),
  })

  const withPartnerValidationSchema = withoutPartnerValidationSchema.shape({
    [AboutYourClientInputFields.partner_date_of_birth]: yupSchemas
      .dayMonthYear()
      .required(),
  })

  return shouldShowPartnerQuestions
    ? withPartnerValidationSchema
    : withoutPartnerValidationSchema
}

export function handleCreateOrUpdateClientError(
  errors: ConstraintViolationListError,
  formikHelpers: FormikHelpers<AboutYourClientInput>,
) {
  const apiErrors = constraintViolationService.formatErrors(errors)
  const formattedErrors = formatApiErrors(apiErrors)

  // Map the field names for the Client entity to the field names we
  // use in the form.
  const keyReplacements: Partial<
    Record<keyof ClientEntity, keyof AboutYourClientInput>
  > = {
    email: AboutYourClientInputFields.user_email,
    firstName: AboutYourClientInputFields.user_name_first,
    lastName: AboutYourClientInputFields.user_name_last,
    dateOfBirth: AboutYourClientInputFields.user_date_of_birth,
  }

  const fieldErrors = replaceObjectKeys(formattedErrors, keyReplacements)

  setFieldErrors(fieldErrors, formikHelpers)
}
