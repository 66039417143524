import { PersonalAssetsInput } from '../../../components/flows/CompassFlow/utils/types'
import { CompassReportStatus } from '../../../types/misc'
import { COMPASS_REPORT_STATUS } from '../../constants'

class CompassFlowService {
  isReadOnlyMode(status?: CompassReportStatus): boolean {
    return status === COMPASS_REPORT_STATUS.COMPLETE
  }

  isEditMode(status?: CompassReportStatus): boolean {
    return status !== COMPASS_REPORT_STATUS.COMPLETE
  }

  shouldShowRentalIncomeQuestion(options: {
    personalAssets?: PersonalAssetsInput
    isForPartner?: boolean
  }): boolean {
    const { personalAssets, isForPartner = false } = options

    // If the user and their partner have a joint rental property, we always
    // want to ask both for their rental incomes (even if one of them said
    // they don't own a rental property in their own name).
    if (personalAssets?.joint_has_rental_property) {
      return true
    }

    return isForPartner
      ? !!personalAssets?.partner_has_rental_property
      : !!personalAssets?.user_has_rental_property
  }
}

const compassFlowService = new CompassFlowService()

export default compassFlowService
