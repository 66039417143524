import { NoContentResponse } from '../../types/api'
import {
  CreateAddressRequest,
  UpdateAddressRequest,
  ValidatePostcodeRequest,
} from '../../types/requests/addresses'
import { CompanyAddressCollectionItem } from '../../types/responses/companies'
import apiService from './apiService'

class AddressService {
  private endpoint = '/v1/addresses'

  async createAddress(request: CreateAddressRequest) {
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }

  async updateAddress(addressId: number, request: UpdateAddressRequest) {
    const response = await apiService.patch(
      `${this.endpoint}/${addressId}`,
      request,
    )

    return response.data
  }

  async deleteAddress(addressId: number) {
    const response = await apiService.delete<NoContentResponse>(
      `${this.endpoint}/${addressId}`,
    )

    return response.data
  }

  async getCurrentCompanyAddresses(): Promise<CompanyAddressCollectionItem> {
    const response = await apiService.get(this.endpoint)

    return response.data['hydra:member']
  }

  async validatePostcode(request: ValidatePostcodeRequest) {
    const response = await apiService.post(
      `${this.endpoint}/validate-postcode`,
      request,
    )

    return response.data
  }
}

const addressService = new AddressService()

export default addressService
