import React from 'react'

import { useAuth } from '../../hooks/useAuth'
import useScript from '../../hooks/useScript'
import hubspotChatService from '../../lib/services/hubspotChatService'

// See https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
const HubspotChatWidget: React.FC = () => {
  const { currentUser } = useAuth()
  useScript('//js.hs-scripts.com/' + import.meta.env.VITE_HUBSPOT_ID + '.js')

  // Configure the HubSpot chat widget
  window.hsConversationsSettings = { loadImmediately: false }
  window.hsConversationsOnReady = [onConversationsAPIReady]

  function onConversationsAPIReady() {
    if (currentUser) {
      window.hsConversationsSettings = {
        identificationEmail: currentUser.email,
        identificationToken: currentUser.hubspotIdentificationToken,
      }
    }

    hubspotChatService.loadChatWidget()
  }

  return null
}

export default HubspotChatWidget
