import { isDocker, isFeature, isHotfix } from './envHelpers'

export function getApiUrl() {
  if (isFeature()) {
    return import.meta.env.VITE_RQ_API_FEATURE_URL
  }

  if (isHotfix()) {
    return import.meta.env.VITE_RQ_API_HOTFIX_URL
  }

  if (isDocker()) {
    return import.meta.env.VITE_RQ_API_PRIVATE_URL
  }

  return import.meta.env.VITE_RQ_API_URL
}
