import classNames from 'classnames'
import React from 'react'
import { Dropdown } from 'react-bootstrap'

import useAppSelector from '../../../../../hooks/useAppSelector'
import { useAuth } from '../../../../../hooks/useAuth'
import { useCompanies } from '../../../../../hooks/useCompanies'
import { isDevelopment } from '../../../../../lib/helpers/envHelpers'
import { truncateText } from '../../../../../lib/helpers/helperFunctions'
import { selectCompanyTypes } from '../../../../../redux/slices/commonData'
import { selectCurrentCompany } from '../../../../../redux/slices/session'
import { CompanyCollectionItem } from '../../../../../types/responses/companies'
import CompanyTypeIcon from '../../../../misc/CompanyIcon/CompanyTypeIcon'

const UserCompanies: React.FC = () => {
  const currentCompany = useAppSelector(selectCurrentCompany)
  const userCompanies = useCompanies()
  const { updateCompanyContext } = useAuth()
  const companyTypes = useAppSelector(selectCompanyTypes)

  const companySections = companyTypes.map((companyType) => ({
    companyTypeLabel: companyType.shortName,
    companies: userCompanies.filter(
      (userCompany) => userCompany.companyType['@id'] === companyType['@id'],
    ),
  }))

  if (!userCompanies.length) {
    return null
  }

  return (
    <>
      {companySections.map(({ companyTypeLabel, companies }) =>
        renderCompanies(companyTypeLabel, companies),
      )}
    </>
  )

  function renderCompanies(
    companyTypeLabel: string,
    companies: CompanyCollectionItem[],
  ) {
    if (companies.length === 0) {
      return null
    }

    return (
      <section key={companyTypeLabel}>
        <div className="ps-3">{companyTypeLabel}</div>

        {companies.map((company) => (
          <Dropdown.Item
            onClick={() => updateCompanyContext(company['@id'])}
            key={company.id + company.companyType.code}
            title={isDevelopment() ? `ID: ${company.id.toString()}` : undefined}
            className={classNames('d-flex align-items-center gap-2', {
              active: company.id === currentCompany?.id,
            })}
          >
            <CompanyTypeIcon
              companyTypeCode={company.companyType.code}
              style={{ width: '16px', height: '16px' }}
            />

            {truncateText(company.presentationName, { maxLength: 50 })}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
      </section>
    )
  }
}

export default UserCompanies
