import React from 'react'
import { Button } from 'react-bootstrap'

import useModal from '../../../../../hooks/useModal'
import { useRqCertifiedAssessmentOrFail } from '../../../../../hooks/useRqCertifiedAssessmentQuery'
import ConfirmNdaModal from '../../../../../pages/rqCertifiedApplicationStatus/components/Content/shared/ConfirmNdaModal'
import MakePaymentClaimModal from '../../../../../pages/rqCertifiedApplicationStatus/components/Content/shared/MakePaymentClaimModal'

const AssessmentTwoNotStartedButtons: React.FC = () => {
  const assessment = useRqCertifiedAssessmentOrFail()

  const {
    isShowingModal: isShowingConfirmNdaModal,
    showModal: showConfirmNdaModal,
    hideModal: hideConfirmNdaModal,
  } = useModal()

  const {
    isShowingModal: isShowingPaymentInstructionsModal,
    showModal: showPaymentInstructionsModal,
    hideModal: hidePaymentInstructionsModal,
  } = useModal()

  const shouldShowConfirmNdaModal = !assessment.ndaAgreedAt

  return (
    <>
      <Button
        variant="primary"
        className="btn-fluid"
        onClick={() => {
          if (shouldShowConfirmNdaModal) {
            showConfirmNdaModal()
          } else {
            showPaymentInstructionsModal()
          }
        }}
      >
        Take {assessment.assessmentTwoName}
      </Button>

      {isShowingConfirmNdaModal && (
        <ConfirmNdaModal
          onConfirm={() => {
            hideConfirmNdaModal()
            showPaymentInstructionsModal()
          }}
          onHide={hideConfirmNdaModal}
        />
      )}

      {isShowingPaymentInstructionsModal && (
        <MakePaymentClaimModal onHide={hidePaymentInstructionsModal} />
      )}
    </>
  )
}

export default AssessmentTwoNotStartedButtons
