export function isDevelopment(): boolean {
  return process.env.NODE_ENV === 'development'
}

export function isStaging(): boolean {
  return (
    window.location.host === 'frontend-staging.rqratings.com' ||
    window.location.host === 'staging.rq.app'
  )
}

export function isFeature(): boolean {
  return (
    window.location.host === 'frontend-feature.rqratings.com' ||
    window.location.host === 'feature.rq.app'
  )
}

export function isProduction(): boolean {
  return (
    window.location.host === 'app.rqratings.com' ||
    window.location.host === 'refer.rq.app'
  )
}

export function isHotfix(): boolean {
  return (
    window.location.host === 'frontend-hotfix.rqratings.com' ||
    window.location.host === 'hotfix.rq.app'
  )
}

export function isDocker(): boolean {
  const hostUrl = `${window.location.protocol}//${window.location.host}`

  return hostUrl.includes('host.docker.internal')
}

export function isNotProduction(): boolean {
  return !isProduction()
}
