import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getOrFail } from '../../lib/helpers/helperFunctions'
import { CommercialAgreementsMetadataItem } from '../../types/responses/commercial-agreements'
import { RootState } from '../store'

type CommercialAgreementsMetadataState = CommercialAgreementsMetadataItem | null

export const commercialAgreementsMetadata = createSlice({
  name: 'commercialAgreementsMetadata',
  initialState: null as CommercialAgreementsMetadataState,
  reducers: {
    setCommercialAgreementsMetadata: (
      _state,
      action: PayloadAction<CommercialAgreementsMetadataItem | null>,
    ) => {
      return action.payload
    },
  },
})

export const { setCommercialAgreementsMetadata } =
  commercialAgreementsMetadata.actions

export const selectCommercialAgreementsMetadata = (state: RootState) =>
  state.commercialAgreementsMetadata

export const selectCommercialAgreementsMetadataOrFail = (state: RootState) =>
  getOrFail(
    state.commercialAgreementsMetadata,
    'Expected commercial agreements metadata to be set',
  )

export const commercialAgreementsMetadataReducer =
  commercialAgreementsMetadata.reducer
