import classNames from 'classnames'
import React from 'react'
import { Container, ContainerProps } from 'react-bootstrap'

type Props = ContainerProps

const PageContainer: React.FC<Props> = ({ className, children, ...props }) => (
  <Container fluid className={classNames('p-0', className)} {...props}>
    {children}
  </Container>
)

export default PageContainer
