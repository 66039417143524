import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface BookMeetingModalState {
  videoUrl: string | null
}

const INITIAL_STATE: BookMeetingModalState = {
  videoUrl: null,
}

const bookMeetingModal = createSlice({
  name: 'bookMeetingModal',
  initialState: INITIAL_STATE,
  reducers: {
    openBookMeetingModal: (state, action: PayloadAction<string>) => {
      state.videoUrl = action.payload
    },

    hideBookMeetingModal: (state) => {
      state.videoUrl = null
    },
  },
})

export const { openBookMeetingModal, hideBookMeetingModal } =
  bookMeetingModal.actions

export const selectBookMeetingModal = (state: RootState) =>
  state.bookMeetingModal

export const selectBookMeetingModalUrl = createSelector(
  selectBookMeetingModal,
  (bookMeetingModal) => bookMeetingModal.videoUrl,
)

export const bookMeetingModalReducer = bookMeetingModal.reducer
