import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

export function useParamOrFail(paramName: string) {
  const params = useParams()
  const param = params[paramName]

  invariant(param, `Expected param "${paramName}" to be defined`)

  return param
}
