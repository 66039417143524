import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { useMount } from 'react-use'

export interface OverlayProps {
  children: React.ReactNode
  variant: 'dark' | 'body-bg' | 'white'
  contentCentered?: boolean
  className?: string
  fullScreen?: boolean
}

const Overlay: React.FC<OverlayProps> = ({
  children,
  variant,
  contentCentered,
  className,
  fullScreen = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const backgroundColor =
    variant === 'dark'
      ? 'color-mix(in srgb, black 70%, transparent)'
      : variant === 'body-bg'
        ? 'color-mix(in srgb, var(--bs-body-bg) 50%, transparent)'
        : 'color-mix(in srgb, white 50%, transparent)'

  // Scroll the overlay card into view if it's not visible
  useMount(() => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 200)
  })

  // Prevent scrolling on the body when the overlay is in full screen mode
  useEffect(() => {
    if (!fullScreen) {
      return
    }

    document.getElementById('root')?.classList.add('overflow-y-hidden')

    return () => {
      if (fullScreen) {
        document.getElementById('root')?.classList.remove('overflow-y-hidden')
      }
    }
  })

  return (
    <div
      ref={containerRef}
      style={{
        backdropFilter: 'blur(5px)',
        backgroundColor: backgroundColor,
        zIndex: 9999999,
      }}
      className={classNames(
        'w-100 top-0 bottom-0 start-0 end-0 p-3 position-absolute',
        contentCentered
          ? 'd-flex justify-content-center align-items-center'
          : '',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Overlay
