import { LegislationCollection } from '../../types/responses/legislations'
import apiService from './apiService'

class LegislationService {
  async getLegislationsByFcaServiceAreaIds(
    serviceAreaIds: ID[],
  ): Promise<LegislationCollection> {
    const response = await apiService.get<LegislationCollection>(
      `/v1/legislations`,
      { params: { 'serviceAreas.id': serviceAreaIds } },
    )

    return response.data
  }
}

const legislationService = new LegislationService()

export default legislationService
