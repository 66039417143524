import { useMutation } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import React from 'react'
import * as yup from 'yup'

import { yupSchemas } from '../../../../../../../lib/helpers/yupSchemas'
import companyService from '../../../../../../../lib/services/companyService'
import sentryService from '../../../../../../../lib/services/sentryService'
import { UpdateIfaDetailsRequest } from '../../../../../../../types/requests/companies'
import { CompanyItem } from '../../../../../../../types/responses/companies'
import FormGroup from '../../../../../../form/FormGroup'
import MoneyInput from '../../../../../../form/MoneyInput'
import RadioOptionsInput from '../../../../../../form/RadioOptionsInput'
import FormikEffect from '../../../../../../utils/FormikEffect'
import { IFA_DETAILS_FORM_FIELDS } from '../../constants'
import ValidationTracker from './ValidationTracker'

interface Props {
  company: CompanyItem
}

export interface IfaDetailsFormValues {
  isTransactionalWork?: boolean
  hasMinInvestableAssetsRequirement?: boolean
  minInvestableAssets?: number
}

const IfaDetailsForm: React.FC<Props> = ({ company }) => {
  const ifaDetails = company.companyDetail?.ifa

  const initialValues: IfaDetailsFormValues = {
    isTransactionalWork: ifaDetails?.isTransactionalWork ?? undefined,
    hasMinInvestableAssetsRequirement:
      ifaDetails?.hasMinInvestableAssetsRequirement ?? undefined,
    minInvestableAssets: ifaDetails?.minInvestableAssets ?? undefined,
  }

  const updateIfaDetailsMutation = useMutation({
    mutationFn: (values: IfaDetailsFormValues) => {
      const request: UpdateIfaDetailsRequest = {
        ...values,
      }

      if (!values.hasMinInvestableAssetsRequirement) {
        request.minInvestableAssets = null
      }

      return companyService.updateIfaDetails(company.id, request)
    },
    onError: (_error, values) =>
      sentryService.captureMessage({
        message: 'Failed to update IFA details',
        extra: { formValues: values },
      }),
  })

  function handleChange(values: IfaDetailsFormValues) {
    updateIfaDetailsMutation.mutate(values)
  }

  const debouncedUpdateIfaDetails = debounce(handleChange, 500)

  const validationSchema = yup.object().shape({
    [IFA_DETAILS_FORM_FIELDS.isTransactionalWork]: yupSchemas.requiredBoolean(),
    [IFA_DETAILS_FORM_FIELDS.hasMinInvestableAssetsRequirement]:
      yupSchemas.requiredBoolean(),
    [IFA_DETAILS_FORM_FIELDS.minInvestableAssets]: yup
      .number()
      .when(IFA_DETAILS_FORM_FIELDS.hasMinInvestableAssetsRequirement, {
        is: (value: boolean | undefined) => value === true,
        then: (schema) => schema.required(),
      }),
  })

  return (
    <FormGroup className="mt-4 text-start">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={noop}
      >
        {({ values }) => (
          <Form>
            <ValidationTracker />

            <FormikEffect<IfaDetailsFormValues>
              onChange={({ values }) => debouncedUpdateIfaDetails(values)}
            />

            <FormGroup>
              <RadioOptionsInput
                label="Does your firm take on transactional (one-off) work?"
                name={IFA_DETAILS_FORM_FIELDS.isTransactionalWork}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
              />
            </FormGroup>

            <FormGroup>
              <RadioOptionsInput
                label="Is there a minimum amount of readily investable assets a client needs to have in order to become a client?"
                name={IFA_DETAILS_FORM_FIELDS.hasMinInvestableAssetsRequirement}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
              />
            </FormGroup>

            {values.hasMinInvestableAssetsRequirement && (
              <FormGroup style={{ maxWidth: '240px' }}>
                <MoneyInput
                  label="Amount"
                  name={IFA_DETAILS_FORM_FIELDS.minInvestableAssets}
                />
              </FormGroup>
            )}
          </Form>
        )}
      </Formik>
    </FormGroup>
  )
}

export default IfaDetailsForm
