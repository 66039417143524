import { CompanyProfileTab } from '../../components/misc/CompanyProfile/CompanyProfile'
import { COMPANY_PROFILE_TAB } from '../../components/misc/CompanyProfile/constants'
import { CompanyIdentifierType } from '../../types/misc'
import { COMPANY_IDENTIFIER } from '../constants'

class CompanyProfileService {
  isValidIdentifierType(
    identifierType: string,
  ): identifierType is CompanyIdentifierType {
    return (
      identifierType === COMPANY_IDENTIFIER.companyId ||
      identifierType === COMPANY_IDENTIFIER.frn ||
      identifierType === COMPANY_IDENTIFIER.sraNumber
    )
  }

  isValidTab(tab?: string | null): tab is CompanyProfileTab {
    return (
      !!tab && (Object.values(COMPANY_PROFILE_TAB) as string[]).includes(tab)
    )
  }
}

const companyProfileService = new CompanyProfileService()

export default companyProfileService
