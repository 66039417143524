import { COMPANY_TYPE_CODE } from '../../../../lib/constants'
import inviteToRqService from '../../../../lib/services/inviteToRqService'
import { getCompanyTypePlaceholder } from '../../../../pages/inviteToRq/helpers'
import { InvitationEmail } from '../../../../pages/inviteToRq/types'
import { CompanyTypeCode } from '../../../../types/misc'
import DiscountNote from '../components/DiscountNote'

export const ToFcaFirmEmail = {
  build: (
    fromCompanyTypeCode: CompanyTypeCode,
    toCompanyTypeCode: CompanyTypeCode,
  ): InvitationEmail => {
    const fromCompanyLabel = getCompanyTypePlaceholder(fromCompanyTypeCode)
    const greetingPlaceholder = getCompanyTypePlaceholder(toCompanyTypeCode)

    const otherProfessionalsPhrase =
      toCompanyTypeCode === COMPANY_TYPE_CODE.mortgage_adviser
        ? 'lawyers, accountants, and others'
        : 'lawyers, mortgage advisers, and others'

    return {
      subject: 'Referring clients to your firm',
      renderText: (invitationLink) => (
        <>
          <p>Hi [{greetingPlaceholder}],</p>
          <p>
            We have just signed up to the collaboration platform RQ so that we
            can work more closely with your firm. From now on we would like to
            use RQ to manage our collaboration with you.
          </p>

          <p>From our point of view as {fromCompanyLabel}, RQ:</p>

          <ol>
            <li>
              <b>Identifies</b> which of our clients will likely benefit from
              your financial planning support through a professional, repeatable
              and scalable discovery process.
            </li>
            <li>
              <b>Ensures</b> that we are fulfilling our regulatory obligations
              when introducing and collaborating with your firm.
            </li>
            <li>
              <b>Streamlines</b> data-sharing between our firms, saving us both
              time and effort on the administrative side and giving us both an
              up-to-date view of a client's journey. This will give our joint
              clients a more seamless experience.
            </li>
          </ol>

          <p>
            <b>What to do</b>
          </p>
          <p>
            Please follow this link to set up your account on the RQ platform:{' '}
            <a href={invitationLink}>{invitationLink}</a>.
            <br />
            {inviteToRqService.shouldShowDiscountNote(toCompanyTypeCode) && (
              <DiscountNote />
            )}
          </p>

          <p>
            Your single RQ subscription will allow you to invite all
            professionals you collaborate with – {otherProfessionalsPhrase} - to
            join you on RQ. This will ensure you are front of mind and bring
            your circle of trusted professionals even closer together.
          </p>

          <p>
            Yours,
            <br />
            [Your name]
          </p>
        </>
      ),
    }
  },
}
