import classNames from 'classnames'
import React from 'react'

import Overlay, { OverlayProps } from './Overlay'

type Props = Pick<OverlayProps, 'fullScreen' | 'variant'> & {
  children: React.ReactNode
  centered?: boolean
  className?: string
}

const OverlayCard: React.FC<Props> = ({
  children,
  centered,
  className,
  fullScreen,
  variant,
}) => (
  <Overlay variant={variant} fullScreen={fullScreen} contentCentered={centered}>
    <div
      className={classNames(
        'bg-white border-box shadow-lg rounded-lg mx-auto p-4',
        className,
      )}
      style={{ maxWidth: '700px' }}
    >
      {children}
    </div>
  </Overlay>
)

export default OverlayCard
