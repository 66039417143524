import { RqCertifiedAssessmentStage } from '../../types/misc'
import { RqCertifiedPaymentClaimRequest } from '../../types/requests/rq-certified-assessments'
import {
  CreateNdaPdfResponse,
  RqCertifiedAssessmentItem,
} from '../../types/responses/rq-certified-assessment'
import apiService from './apiService'

class RqCertifiedAssessmentService {
  async getAssessmentByIri(iri: IRI): Promise<RqCertifiedAssessmentItem> {
    const response = await apiService.get(iri)

    return response.data
  }

  async submitPaymentClaim(
    companyId: number,
    request: RqCertifiedPaymentClaimRequest,
  ) {
    const response = await apiService.post(
      `/v1/companies/${companyId}/rq-certified-assessment/submit-payment-claim`,
      request,
    )

    return response.data
  }

  async review(companyId: number, stage: RqCertifiedAssessmentStage) {
    const response = await apiService.post(
      `/v1/companies/${companyId}/rq-certified-assessment/review`,
      {
        assessmentStage: stage,
        passed: true,
      },
    )

    return response.data
  }

  async confirmNda(companyId: number): Promise<unknown> {
    const response = await apiService.post(
      `/v1/companies/${companyId}/rq-certified-assessment/confirm-nda`,
      {},
    )

    return response.data
  }

  async createNdaPdf(companyId: number): Promise<CreateNdaPdfResponse> {
    const response = await apiService.post(
      `/v1/companies/${companyId}/rq-certified-assessment/nda-pdf`,
      {},
    )

    return response.data
  }
}

const rqCertifiedAssessmentService = new RqCertifiedAssessmentService()

export default rqCertifiedAssessmentService
