import invariant from 'tiny-invariant'

import { selectCurrentCompany } from '../redux/slices/session'
import useAppSelector from './useAppSelector'

function useCurrentCompanyOrFail() {
  const currentCompany = useAppSelector(selectCurrentCompany)

  invariant(
    currentCompany,
    'Used useCurrentCompany in a place where user is not authenticated',
  )

  return currentCompany
}

export default useCurrentCompanyOrFail
