import { PROFESSIONAL_SITUATION_OPTIONS } from '../../../components/flows/CompassFlow/utils/constants'
import {
  AboutYourClientInput,
  CompassInput,
} from '../../../components/flows/CompassFlow/utils/types'

class CompassClientDetailsService {
  isBusinessOwnerOrPartner(
    professionalSituation: AboutYourClientInput['user_professional_situation'],
  ) {
    return (professionalSituation || []).some(
      (situation) =>
        situation === PROFESSIONAL_SITUATION_OPTIONS.businessOwner ||
        situation === PROFESSIONAL_SITUATION_OPTIONS.partner,
    )
  }

  getPdfPassword(compassInput: CompassInput | null | undefined): string | null {
    return (
      compassInput?.aboutYourClient?.user_date_of_birth?.replaceAll('/', '') ||
      null
    )
  }
}

const compassClientDetailsService = new CompassClientDetailsService()

export default compassClientDetailsService
