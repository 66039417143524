import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { CSSProperties } from 'react'
import { Spinner } from 'react-bootstrap'

import useAppSelector from '../../../../../hooks/useAppSelector'
import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import companyExpertiseAreaService from '../../../../../lib/services/companyExpertiseAreaService'
import { selectExpertiseAreasByCompanyTypeIri } from '../../../../../redux/slices/commonData'
import { ExpertiseAreaItem } from '../../../../../types/responses/common-data'
import CompanyExpertiseAreaToggleButton from './components/CompanyExpertiseAreaToggleButton'

const CompanyExpertiseAreas: React.FC = () => {
  const currentCompany = useCurrentCompanyOrFail()
  const queryClient = useQueryClient()

  const expertiseAreas = useAppSelector(
    selectExpertiseAreasByCompanyTypeIri(currentCompany.companyType['@id']),
  )

  const companyExpertiseAreasQuery = useQuery({
    queryKey: ['company-expertise-areas', currentCompany.id],
    queryFn: () => companyExpertiseAreaService.getCollectionForCurrentCompany(),
  })

  function getCompanyExpertiseAreaIdOrUndefinedByExpertiseAreaId(
    expertiseAreaId: number,
  ): number | undefined {
    if (companyExpertiseAreasQuery.data === undefined) {
      return undefined
    }

    const companyExpertiseArea = companyExpertiseAreasQuery.data.find(
      (companyExpertiseArea) =>
        companyExpertiseArea.expertiseArea.id === expertiseAreaId,
    )

    return companyExpertiseArea === undefined
      ? undefined
      : companyExpertiseArea.id
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100 pop-in">
      <div
        style={
          {
            // Even columns. Ideally max 10 per column. Definitely max 3 columns
            '--number-of-columns': Math.min(
              Math.ceil(expertiseAreas.length / 10),
              3,
            ),
            display: 'grid',
            gridTemplateColumns:
              'repeat(var(--number-of-columns), minmax(auto, 1fr))',
            gap: '.5rem',
          } as CSSProperties
        }
      >
        {companyExpertiseAreasQuery.isPending && <Spinner />}

        {!companyExpertiseAreasQuery.isPending &&
          expertiseAreas.map((expertiseArea: ExpertiseAreaItem) => (
            <CompanyExpertiseAreaToggleButton
              key={expertiseArea.id}
              companyExpertiseAreaId={getCompanyExpertiseAreaIdOrUndefinedByExpertiseAreaId(
                expertiseArea.id,
              )}
              expertiseAreaId={expertiseArea.id}
              companyId={currentCompany.id}
              expertiseAreaName={expertiseArea.name}
              onChangeSaved={() => {
                queryClient.invalidateQueries({
                  queryKey: ['company-expertise-areas'],
                })
              }}
            />
          ))}
      </div>
    </div>
  )
}

export default CompanyExpertiseAreas
