import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import InviteReferralPartners from '../../misc/InviteReferralPartners/InviteReferralPartners'
import InviteTypeChoice from './InviteTypeChoice'

interface Props {
  onHide: () => void
}

export type StepName = 'inviteTypeChoice' | 'referralPartners'

const InviteToRqModal: React.FC<Props> = ({ onHide }) => {
  const [stepName, setStepName] = useState<StepName>('inviteTypeChoice')

  const steps: {
    [key in StepName]: { title: string; render: () => JSX.Element }
  } = {
    inviteTypeChoice: {
      title: 'Invite people to RQ',
      render: () => (
        <InviteTypeChoice
          onSelect={(newStepName?: StepName) =>
            newStepName ? setStepName(newStepName) : onHide()
          }
        />
      ),
    },

    referralPartners: {
      title: 'Invite your referral partners to RQ',
      render: () => <InviteReferralPartners />,
    },
  }

  const step = steps[stepName]

  return (
    <Modal show centered onHide={(): void => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong className="fs-2">{step.title}</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{step.render()}</Modal.Body>
    </Modal>
  )
}

export default InviteToRqModal
