import { onlineManager } from '@tanstack/react-query'
import posthog from 'posthog-js'
import { useCallback } from 'react'

import { POSTHOG_EVENT } from '../lib/constants'
import sentryService from '../lib/services/sentryService'
import { useSessionMetadata } from './useSessionMetadata'

export function useCaptureErrorShownToUserEvent() {
  const sessionMetadata = useSessionMetadata()
  const isOnline = onlineManager.isOnline()

  return useCallback(
    (error?: unknown) => {
      const logData: Record<string, unknown> = { isOnline }

      if (error) {
        logData.error = error
      }

      console.log('Capturing error shown to user event', logData)

      sentryService.captureMessage({
        messageId: 'error-message-shown-to-user',
        message: 'Error message shown to user',
        extra: { sessionMetadata, error, isOnline },
      })

      posthog.capture(POSTHOG_EVENT.errorMessageShownToUser, {
        isOnline,
      })
    },
    [isOnline, sessionMetadata],
  )
}
