import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faShuffle } from '@fortawesome/free-solid-svg-icons/faShuffle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculatorSimple } from '@rq-ratings/pro-light-svg-icons'
import classNames from 'classnames'
import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'

import logoBlack from '../../assets/img/logo.svg'
import logoWhite from '../../assets/img/logo-inverse.svg'
import { useAuth } from '../../hooks/useAuth'
import CompanyLogoOrName from '../misc/CompanyLogoOrName'

interface Props {
  children: React.ReactNode
  companyName?: string | null
  companyLogo?: string | null
  isDarkMode?: boolean
}

const MinimalLayout: React.FC<Props> = ({
  children,
  companyName = null,
  companyLogo = null,
  isDarkMode = true,
}) => {
  const {
    isAuthenticated,
    signOut,
    companies,
    currentCompany,
    updateCompanyContext,
  } = useAuth()

  const otherCompanies = currentCompany
    ? companies.filter((company) => company['@id'] !== currentCompany['@id'])
    : companies

  const showBranding = companyName !== null

  return (
    <div className="container">
      <div className="row">
        <div
          className={classNames(
            'col-lg-8 offset-lg-2',
            isDarkMode ? 'mt-5' : 'mt-4 pt-sm-2',
          )}
        >
          <div className="row px-3 px-sm-0">
            <div className="col-5">
              {showBranding ? (
                <CompanyLogoOrName
                  name={companyName}
                  logoUrl={companyLogo}
                  showLogoFallback={false}
                  wrapperProps={{
                    className: isDarkMode ? 'text-white' : 'text-black',
                  }}
                  imageStyle={{ maxHeight: '24px', maxWidth: '110px' }}
                />
              ) : (
                <img
                  src={isDarkMode ? logoWhite : logoBlack}
                  style={{ maxWidth: '60px' }}
                  alt="RQ"
                />
              )}
            </div>

            <div className="col-7 align-self-end d-flex  gap-2 flex-wrap align-items-start justify-content-end">
              {otherCompanies.length > 0 && (
                <Dropdown className="d-inline">
                  <Dropdown.Toggle
                    variant={isDarkMode ? 'outline-light' : 'outline-secondary'}
                    id="dropdown-basic"
                  >
                    <FontAwesomeIcon icon={faShuffle} /> Switch account{' '}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{ maxHeight: '700px', overflowY: 'auto' }}
                  >
                    {otherCompanies.map((company) => (
                      <Dropdown.Item
                        onClick={() => updateCompanyContext(company['@id'])}
                        key={company.id + company.companyType.code}
                      >
                        <FontAwesomeIcon
                          icon={faCalculatorSimple}
                          className="align-middle me-2"
                        />
                        {company.presentationName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {isAuthenticated && (
                <Button
                  className="d-inline"
                  variant={isDarkMode ? 'outline-light' : 'outline-secondary'}
                  onClick={() => signOut()}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </Button>
              )}
            </div>
          </div>

          <div className="mt-3">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default MinimalLayout
