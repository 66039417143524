import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import { useUpdateIcaewConsentMutation } from '../../hooks/useUpdateIcaewConsentMutation'
import { CONSENT_DECISION, EXTERNAL_LINKS } from '../../lib/constants'
import ActionButton from '../misc/ActionButton'

const IcaewConsentModal: React.FC = () => {
  const [hasClickedOptOut, setHasClickedOptOut] = useState(false)
  const updateConsentMutation = useUpdateIcaewConsentMutation()
  const isProcessing = updateConsentMutation.isPending

  const modalFooterClass = 'd-flex flex-column flex-sm-row'

  const isOptingIn =
    isProcessing && updateConsentMutation.variables === CONSENT_DECISION.OPT_IN
  const isOptingOut =
    isProcessing && updateConsentMutation.variables === CONSENT_DECISION.OPT_OUT

  function renderInitialScreen() {
    return (
      <>
        <Modal.Body>
          <p>
            We have partnered with the ICAEW. Are you happy for your data to be
            shared with the ICAEW for monitoring purposes?
          </p>

          <p className="mb-0">
            You can change your mind at any time by going to preferences. For
            further information about how this data will be used, see our{' '}
            <a
              href={EXTERNAL_LINKS.privacyPolicy}
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </a>
            .
          </p>
        </Modal.Body>

        <Modal.Footer className={modalFooterClass}>
          <Button
            variant="outline-danger"
            onClick={() => setHasClickedOptOut(true)}
          >
            Opt out
          </Button>

          <ActionButton
            variant="success"
            isProcessing={isProcessing}
            onClick={() => {
              updateConsentMutation.mutate(CONSENT_DECISION.OPT_IN)
            }}
          >
            Yes, share data with the ICAEW
          </ActionButton>
        </Modal.Footer>
      </>
    )
  }

  function renderOptOutConfirmationScreen() {
    return (
      <>
        <Modal.Body>
          <p className="mb-0">
            Are you sure you don't want to share data with the ICAEW?
          </p>
        </Modal.Body>

        <Modal.Footer className={modalFooterClass}>
          <ActionButton
            disabled={isProcessing}
            isProcessing={isOptingOut}
            variant="outline-danger"
            onClick={() =>
              updateConsentMutation.mutate(CONSENT_DECISION.OPT_OUT)
            }
          >
            I'm sure. Opt out
          </ActionButton>

          <ActionButton
            disabled={isProcessing}
            isProcessing={isOptingIn}
            variant="success"
            onClick={() => {
              updateConsentMutation.mutate(CONSENT_DECISION.OPT_IN)
            }}
          >
            Opt in
          </ActionButton>
        </Modal.Footer>
      </>
    )
  }

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>Data sharing</Modal.Title>
      </Modal.Header>

      {hasClickedOptOut
        ? renderOptOutConfirmationScreen()
        : renderInitialScreen()}
    </Modal>
  )
}

export default IcaewConsentModal
