import invariant from 'tiny-invariant'
import { objectKeys } from 'ts-extras'

import { ServiceFeeTableHeading } from '../../components/misc/ServiceFeesTable/ServiceFeesTableHeaders'
import { ServiceFeeDetail } from '../../components/misc/ServiceFeesTable/ServiceFeesTableRows'
import {
  CommercialAgreementTypeOption,
  ServiceFeeInput,
} from '../../redux/slices/commercialAgreementsForm'
import { SelectOption } from '../../types/misc'
import { ValidateServiceFeeRequest } from '../../types/requests/service-fees'
import { CommonData, ServiceAreaItem } from '../../types/responses/common-data'
import { ValidateServiceFeeResponse } from '../../types/responses/service-fees'
import {
  COMMERCIAL_AGREEMENT_TYPE,
  SERVICE_FEE_CATEGORY_TYPES,
  SERVICE_FEE_DURATION_OPTIONS,
  SERVICE_FEE_SHARE_TYPE,
} from '../constants'
import { getOrFail } from '../helpers/helperFunctions'
import apiService from './apiService'
import serviceAreaService from './serviceAreaService'

export const SERVICE_AREA_OPTION_ALL = 'All'
export const SERVICE_AREA_OPTION_OTHER = 'OTHER'

export const FEE_CATEGORY_OPTION_OTHER = 'OTHER'

class ServiceFeeService {
  private endpoint = '/v1/service-fees'

  async validateServiceFee(
    request: ValidateServiceFeeRequest,
  ): Promise<ValidateServiceFeeResponse> {
    const response = await apiService.post(`${this.endpoint}/validate`, request)

    return response.data
  }

  getServiceAreaOptions(
    serviceAreas: CommonData['serviceAreas'],
  ): SelectOption[] {
    const options = serviceAreas.map((serviceArea) => ({
      label: serviceArea.name,
      value: serviceArea['@id'],
    }))

    return [
      {
        label: this.getAllServiceAreasLabel(),
        value: SERVICE_AREA_OPTION_ALL,
      },
      ...options,
      {
        label: 'Other (please specify)',
        value: SERVICE_AREA_OPTION_OTHER,
      },
    ]
  }

  getAllServiceAreasLabel(): string {
    return 'All services (unless otherwise specified)'
  }

  getServiceFeeCategoryOptions(): SelectOption[] {
    return objectKeys(SERVICE_FEE_CATEGORY_TYPES).map((key) => ({
      label: SERVICE_FEE_CATEGORY_TYPES[key].label,
      value: SERVICE_FEE_CATEGORY_TYPES[key].value,
    }))
  }

  getServiceFeeCategoryByValue(value: string): SelectOption {
    const feeCategory = Object.entries(SERVICE_FEE_CATEGORY_TYPES).find(
      ([_, feeCategoryType]) => {
        return feeCategoryType.value === value
      },
    )

    return getOrFail(
      feeCategory?.[1],
      `Fee category not found for value "${value}"`,
    )
  }

  getShareTypeOptions(): SelectOption[] {
    return objectKeys(SERVICE_FEE_SHARE_TYPE).map((key) => ({
      label: SERVICE_FEE_SHARE_TYPE[key].label,
      value: SERVICE_FEE_SHARE_TYPE[key].value,
    }))
  }

  getDurationOptions(): SelectOption[] {
    return SERVICE_FEE_DURATION_OPTIONS
  }

  isAllServiceAreas(serviceFee: ServiceFeeDetail): boolean {
    // serviceArea can be 'All' in AddServiceFeeModal.tsx
    if (serviceFee.serviceArea === SERVICE_AREA_OPTION_ALL) {
      return true
    }

    // serviceAreaOther can be 'All' for already saved service fees
    if (
      !serviceFee.serviceArea &&
      serviceFee.serviceAreaOther === SERVICE_AREA_OPTION_ALL
    ) {
      return true
    }

    return false
  }

  normalizeServiceFee<
    InputType extends ServiceFeeInput | ValidateServiceFeeRequest,
  >(serviceFee: InputType): InputType {
    let normalizedServiceFee = { ...serviceFee }
    const serviceArea = serviceFee.serviceArea

    // Clear the `serviceArea` field if the `All` option was selected and
    // set `serviceAreaOther` to 'All'
    if (serviceArea === SERVICE_AREA_OPTION_ALL) {
      normalizedServiceFee = {
        ...normalizedServiceFee,
        serviceArea: undefined,
        serviceAreaOther: SERVICE_AREA_OPTION_ALL,
      }
    }

    // Clear the `serviceArea` field if the `Other (please specify)` option
    // was selected
    if (serviceArea === SERVICE_AREA_OPTION_OTHER) {
      normalizedServiceFee = {
        ...normalizedServiceFee,
        serviceArea: undefined,
      }
    }

    if ('duration' in normalizedServiceFee) {
      normalizedServiceFee.duration = Number(normalizedServiceFee.duration)
    }

    return normalizedServiceFee
  }

  getServiceAreaName(
    serviceFee: ServiceFeeDetail,
    serviceAreas: ServiceAreaItem[],
  ): string {
    const { serviceArea } = serviceFee

    if (this.isAllServiceAreas(serviceFee)) {
      return this.getAllServiceAreasLabel()
    }

    if (serviceFee.serviceAreaOther) {
      return serviceFee.serviceAreaOther
    }

    if (serviceArea) {
      return serviceAreaService.getServiceAreaByIri(serviceArea, serviceAreas)
        .name
    }

    return ''
  }

  isOtherFeeCategory(feeCategory: string) {
    return feeCategory === SERVICE_FEE_CATEGORY_TYPES.other.value
  }

  isOngoingFeesAgreement(feeCategory?: string) {
    return feeCategory === SERVICE_FEE_CATEGORY_TYPES.ongoingFees.value
  }

  shouldShowFeePassBackColumn(options: {
    serviceFees: ServiceFeeDetail[]
    isRecommendedPartnerTerms?: boolean
  }) {
    const { serviceFees, isRecommendedPartnerTerms } = options

    if (isRecommendedPartnerTerms) {
      return false
    }

    return serviceFees.some((serviceFee) => serviceFee.passBackFee)
  }

  shouldShowNotesColumn(serviceFees: ServiceFeeDetail[]) {
    return (
      serviceFees.length === 0 || // Show if table is empty
      serviceFees.some((serviceFee) => serviceFee.notes)
    )
  }

  shouldShowShareTypeAndValueColumns({
    isCompanyUser,
    isPreviewLetter,
  }: {
    isCompanyUser: boolean
    isPreviewLetter: boolean
  }) {
    return isCompanyUser && !isPreviewLetter
  }

  shouldShowExampleColumn(
    agreementType: CommercialAgreementTypeOption,
    serviceFees: ServiceFeeDetail[],
  ) {
    return (
      agreementType === COMMERCIAL_AGREEMENT_TYPE.YFS &&
      (serviceFees.length === 0 || // Show if table is empty
        serviceFees.some((serviceFee) => serviceFee.example))
    )
  }

  // This is a temporary check to ensure that the API handles migrating all
  // commercial agreements to the service fee structure.
  // TODO: Remove this method once the new service fee structure has been in
  // production for a few weeks.
  validateServiceFeesOrFail(serviceFees: ServiceFeeDetail[]) {
    if (!Array.isArray(serviceFees)) {
      throw new Error(
        `Expected serviceFees to be an array, but got ${serviceFees}. ` +
          "This is probably because the commercial terms haven't been migrated to " +
          'the new more granular service fees data format.',
      )
    }

    serviceFees.forEach((serviceFee) => {
      invariant(
        serviceFee.serviceArea || serviceFee.serviceAreaOther,
        'Expected serviceFee to have a serviceArea or serviceAreaOther',
      )
    })
  }

  getTableHeaders(options: {
    serviceFees: ServiceFeeDetail[]
    showActionsColumn: boolean
    agreementType: CommercialAgreementTypeOption
    isRecommendedPartnerTerms?: boolean
    isCompanyUser: boolean
    isPreviewLetter: boolean
  }): ServiceFeeTableHeading[] {
    const {
      serviceFees,
      showActionsColumn,
      agreementType,
      isRecommendedPartnerTerms,
      isCompanyUser,
      isPreviewLetter,
    } = options

    const tableHeaders: ServiceFeeTableHeading[] = [
      {
        label: 'Service',
        className: 'sticky-column-start',
        style: { width: '130px' },
      },
      {
        label: 'Fee category',
        style: { width: '120px' },
      },
    ]

    if (
      serviceFeeService.shouldShowShareTypeAndValueColumns({
        isCompanyUser,
        isPreviewLetter,
      })
    ) {
      tableHeaders.push(
        {
          label: 'Share type',
          style: { width: '120px' },
        },
        {
          label: 'Share value',
          style: { width: '120px' },
        },
      )
    }

    if (
      serviceFeeService.shouldShowFeePassBackColumn({
        serviceFees,
        isRecommendedPartnerTerms,
      })
    ) {
      tableHeaders.push({
        label: 'Pass back some or all to your client?',
        style: { width: '150px' },
      })
    }

    if (serviceFeeService.shouldShowNotesColumn(serviceFees)) {
      tableHeaders.push({
        label: 'Notes',
        style: { width: '150px' },
      })
    }

    if (serviceFeeService.shouldShowExampleColumn(agreementType, serviceFees)) {
      tableHeaders.push({
        label: 'Example',
        style: { width: '150px' },
      })
    }

    if (showActionsColumn) {
      tableHeaders.push({
        label: 'Actions',
        className: 'sticky-column-end',
        style: { width: '100px' },
      })
    }

    return tableHeaders
  }

  getNotesFieldPlaceholder(fromCompanyName: string) {
    return `${fromCompanyName} retain the first 50% and then share 50% thereafter with the client`
  }

  getExampleFieldPlaceholder(fromCompanyName: string) {
    return `If you become a client of ${fromCompanyName}, we will receive fees of £250`
  }

  getServiceFeeGroupsForDisplay(options: {
    serviceFees: ServiceFeeDetail[]
    serviceAreas: ServiceAreaItem[]
  }): ServiceFeeRowGroups {
    const { serviceFees, serviceAreas } = options
    const groups: ServiceFeeRowGroups = {}

    serviceFees.forEach((serviceFee, serviceFeeIndex) => {
      const serviceAreaName = this.getServiceAreaName(serviceFee, serviceAreas)

      if (groups[serviceAreaName]) {
        groups[serviceAreaName].push({ ...serviceFee, index: serviceFeeIndex })
      } else {
        groups[serviceAreaName] = [{ ...serviceFee, index: serviceFeeIndex }]
      }
    })

    return groups
  }

  orderServiceFeesByName(
    serviceFees: ServiceFeeDetail[],
    serviceAreas: ServiceAreaItem[],
  ): ServiceFeeDetail[] {
    return serviceFees.slice().sort((a, b) => {
      const aServiceAreaName = this.getServiceAreaName(a, serviceAreas)
      const bServiceAreaName = this.getServiceAreaName(b, serviceAreas)

      return aServiceAreaName.localeCompare(bServiceAreaName)
    })
  }

  shouldShowExampleField(options: {
    agreementType?: CommercialAgreementTypeOption
    shareType: string
  }) {
    const { agreementType, shareType } = options

    return (
      agreementType === COMMERCIAL_AGREEMENT_TYPE.YFS &&
      shareType === SERVICE_FEE_SHARE_TYPE.percentage.value
    )
  }
}

type ServiceFeeRowGroups = {
  [key: string]: Array<ServiceFeeDetail & { index: number }>
}

const serviceFeeService = new ServiceFeeService()

export default serviceFeeService
