import React, { useEffect } from 'react'

import LoadingWidget from '../components/widgets/LoadingWidget'
import { useAuth } from '../hooks/useAuth'
import { useCreateClientMetadataMutation } from '../hooks/useCreateClientMetadataMutation'

interface Props {
  children: React.ReactNode
}

const ClientMetadataProvider: React.FC<Props> = ({ children }) => {
  const { isClientUser } = useAuth()
  const createClientMetadataMutation = useCreateClientMetadataMutation()

  // Call the metadata endpoint if a client user is logged in
  useEffect(() => {
    if (isClientUser && createClientMetadataMutation.isIdle) {
      createClientMetadataMutation.mutate()
    }
  }, [isClientUser, createClientMetadataMutation])

  if (isClientUser && !createClientMetadataMutation.data) {
    return <LoadingWidget />
  }

  return <>{children}</>
}

export default ClientMetadataProvider
