import { NoContentResponse } from '../../types/api'
import { CreateServiceRequest } from '../../types/requests/services'
import apiService from './apiService'

class ServiceService {
  private endpoint = '/v1/services'

  async createService(request: CreateServiceRequest) {
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }

  async deleteService(serviceId: number): Promise<NoContentResponse> {
    const response = await apiService.delete<NoContentResponse>(
      `${this.endpoint}/${serviceId}`,
    )

    return response.data
  }
}

const serviceService = new ServiceService()

export default serviceService
