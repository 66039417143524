import classNames from 'classnames'
import React, { HTMLAttributes, useEffect, useRef } from 'react'

import useScript from '../../hooks/useScript'

type Props = HTMLAttributes<HTMLDivElement> & {
  embedUrl: string
  onMeetingBooked?: () => void
}

const HubspotMeeting: React.FC<Props> = ({
  className,
  embedUrl,
  onMeetingBooked,
  ...props
}) => {
  const onMeetingBookedRef = useRef(onMeetingBooked)

  useScript(
    'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
  )

  useEffect(() => {
    // Hubspot posts a message to the window object when the booking is complete
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
    function handleMessage({ origin, data }: MessageEvent) {
      if (origin !== 'https://meetings-eu1.hubspot.com') {
        return
      }

      const hasBookingBeenCompleted =
        data.hasOwnProperty('meetingBookSucceeded') &&
        data.meetingBookSucceeded === true
      if (hasBookingBeenCompleted && onMeetingBookedRef.current) {
        onMeetingBookedRef.current()
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <div
      className={classNames('meetings-iframe-container', className)}
      data-src={embedUrl}
      {...props}
    />
  )
}

export default HubspotMeeting
