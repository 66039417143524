import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ClientCollectionItem } from '../../types/responses/clients'
import { RootState } from '../store'

interface ClientsState {
  selectedClients: ClientCollectionItem[]
}

const INITIAL_STATE: ClientsState = {
  selectedClients: [],
}

// TODO: Reuse the compassClients slice
const clients = createSlice({
  name: 'clients',
  initialState: INITIAL_STATE,
  reducers: {
    toggleClientSelection(state, action: PayloadAction<ClientCollectionItem>) {
      const selectedClients = state.selectedClients
      const toggledClient = action.payload

      const isAlreadySelected = selectedClients.some(
        (client) => client.id === toggledClient.id,
      )

      // Remove client from selected clients if it's already selected
      if (isAlreadySelected) {
        state.selectedClients = selectedClients.filter(
          (client) => client.id !== toggledClient.id,
        )
      }
      // Add client to selected clients if it's not already selected
      else {
        state.selectedClients = [...selectedClients, toggledClient]
      }
    },

    removeSelectedClients(state, action: PayloadAction<IRI[]>) {
      const irisToRemove = action.payload

      state.selectedClients = state.selectedClients.filter(
        (client) => !irisToRemove.includes(client['@id']),
      )
    },

    clearSelectedClients(state) {
      state.selectedClients = []
    },
  },
})

export const selectClients = (state: RootState) => state.clients

export const selectSelectedClients = createSelector(
  selectClients,
  (clients) => clients.selectedClients,
)

export const selectHasSelectedClient = createSelector(
  selectSelectedClients,
  (selectedClients) => selectedClients.length > 0,
)

export const {
  toggleClientSelection,
  removeSelectedClients,
  clearSelectedClients,
} = clients.actions

export const clientsReducer = clients.reducer

export function isClientSelected(
  selectedClients: ClientsState['selectedClients'],
  client: ClientCollectionItem,
) {
  return selectedClients.some(
    (selectedClient) => selectedClient.id === client.id,
  )
}
