import { useAppState } from './useAppState'

export function useNotificationsCount() {
  const appState = useAppState()

  return {
    panels: Array.from(
      new Set([
        ...appState.panelRequestedNew,
        ...appState.panelRequestedUpdated,
        ...appState.panelApprovedNew,
        ...appState.panelApprovedUpdated,
      ]),
    ).length,

    referrals: Array.from(
      new Set([
        ...appState.referralFromNew,
        ...appState.referralFromUpdated,
        ...appState.referralToNew,
        ...appState.referralToUpdated,
      ]),
    ).length,
  }
}
