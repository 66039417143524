import { ClientSelectOption } from '../../components/select/SelectClient'
import {
  DISABLED_REASON,
  DisabledReason,
} from '../../pages/referClient/steps/2_SelectServiceOrAdviser/components/SelectServiceFirm/constants'
import { ClientDetailsInput } from '../../pages/referClient/steps/4_ClientDetails/constants'
import { getToCompanyContactName } from '../../pages/referClient/steps/5a_ConfirmReferral/helpers'
import {
  REFERRAL_CONSENT_TYPE,
  REFERRAL_METHOD,
  ReferralConsentType,
  ReferralMethod,
} from '../../redux/slices/referClient'
import { CompanyEntity } from '../../types/entities'
import {
  CompanyTypeCode,
  NormalReferralTypeOption,
  ReferralIndividual,
  ReferralType,
  ReferralTypeOption,
} from '../../types/misc'
import {
  CreateClientForReferralRequest,
  UpdateClientForReferralRequest,
} from '../../types/requests/clients'
import { ClientItem } from '../../types/responses/clients'
import {
  CompanyTypeItem,
  ServiceAreaItem,
} from '../../types/responses/common-data'
import {
  ReferralFirmCollectionItem,
  ReferralFirmIndividualsCollectionItem,
} from '../../types/responses/companies'
import { CompassReportCollectionItem } from '../../types/responses/compass-reports'
import {
  NewReferralMetadataItem,
  ReferralEmailItem,
} from '../../types/responses/referrals'
import {
  DATE_FORMATS,
  FCA_SERVICE_TYPE_NAMES,
  PANEL_STATUS,
  REFERRAL_TYPE,
  REFERRAL_TYPE_OPTION,
  SERVICE_AREA_ID_CREATION_OF_A_FINANCIAL_PLAN,
} from '../constants'
import {
  arrayToCommaSeparatedString,
  convertDate,
  isValidDateFormat,
  normalizeDateOfBirth,
  startsWithVowel,
} from '../helpers/helperFunctions'
import clientService from './clientService'
import companyTypeService from './companyTypeService'

class ReferClientService {
  hasClientConsent(consentType?: ReferralConsentType) {
    return !this.needsClientConsent(consentType)
  }

  needsClientConsent(consentType?: ReferralConsentType) {
    return consentType === REFERRAL_CONSENT_TYPE.yesESignature
  }

  isNormalReferralType(
    referralTypeOption?: ReferralTypeOption,
  ): referralTypeOption is NormalReferralTypeOption {
    return (
      !!referralTypeOption &&
      referralTypeOption !== REFERRAL_TYPE_OPTION.bookCallForClient
    )
  }

  isBookCallForClientReferralType(
    referralTypeOption?: ReferralTypeOption,
  ): boolean {
    return (
      !!referralTypeOption &&
      referralTypeOption === REFERRAL_TYPE_OPTION.bookCallForClient
    )
  }

  isDirectReferral(referralType?: ReferralTypeOption) {
    return (
      referralType === REFERRAL_TYPE_OPTION.direct ||
      referralType === REFERRAL_TYPE_OPTION.directCc
    )
  }

  clientNeedsToConsentToIntroductionEmail(options: {
    consentType?: ReferralConsentType
    referralType?: ReferralTypeOption
  }) {
    const { consentType, referralType } = options

    if (consentType === undefined) {
      return null
    }

    return (
      this.needsClientConsent(consentType) &&
      consentType !== REFERRAL_CONSENT_TYPE.yesESignature &&
      this.isDirectReferral(referralType)
    )
  }

  clientNeedsToSignESignatureLetter(options: {
    consentType?: ReferralConsentType
  }) {
    const { consentType } = options

    return consentType === REFERRAL_CONSENT_TYPE.yesESignature
  }

  normalizeReferralType(
    referralType?: ReferralTypeOption,
  ): ReferralType | undefined {
    if (!referralType) {
      return undefined
    }

    if (
      referralType === REFERRAL_TYPE_OPTION.directCc ||
      referralType === REFERRAL_TYPE_OPTION.direct ||
      referralType === REFERRAL_TYPE_OPTION.bookCallForClient ||
      referralType === REFERRAL_TYPE_OPTION.directPco
    ) {
      return REFERRAL_TYPE.direct
    }

    return referralType as ReferralType
  }

  buildUserEmailMessage(context: UserEmailMessageContext): string {
    const {
      client,
      clientDetails,
      referralType,
      toCompany,
      toCompanyContactNames,
      selectedServiceAreas,
      compassReport,
      companyTypes,
      companyTypeCode,
      metadata,
    } = context

    const toCompanyContactName = getToCompanyContactName(toCompanyContactNames)
    const serviceAreaNames = selectedServiceAreas.map(
      (serviceArea) => serviceArea.name,
    )
    const serviceAreaNamesString = arrayToCommaSeparatedString(serviceAreaNames)
    const compassReportMessage = compassReport
      ? ` Please find background information on ${client.firstName}'s personal affairs in the attached snapshot. `
      : ' '

    const readableCompanyType = companyTypeService.getCompanyTypeByCodeOrFail(
      companyTypes,
      companyTypeCode,
    )

    const internalRelationship = metadata.panel?.internalRelationship

    const messages: Record<NormalReferralTypeOption, string[]> = {
      direct: [
        `Dear ${toCompanyContactName},\n\n`,
        `Our client, ${client?.abbreviatedName} is in need of advice regarding ${serviceAreaNamesString}.${compassReportMessage}Are you able to please reach out to them on the below number / email address?`,
        clientDetails?.reason?.length ? `\n\n${clientDetails.reason}` : '',
      ],
      directCc: [`Dear ${toCompanyContactName},\n\n`],
      directPco: [
        `Dear ${client.firstName},\n\n`,
        'In order to get a Will organised, we have passed your details to Private Client Online. You will hear from them shortly.',
      ],
      indirect: [`Dear ${client.firstName},\n\n`],
    }

    if (!internalRelationship) {
      messages['directCc'].push(
        `${client?.firstName} ${client?.lastName} (cc'd) is after some advice on ${serviceAreaNamesString}.${compassReportMessage}Can I leave it with you to please reach out to ${client?.firstName}?`,
        clientDetails?.reason?.length ? `\n\n${clientDetails.reason}` : '',
      )
      messages['indirect'].push(
        `You have requested advice concerning ${serviceAreaNamesString}. Please see below for details of ${
          startsWithVowel(readableCompanyType.name) ? 'an' : 'a'
        } ${
          readableCompanyType.name
        } that I think will be able to assist. Feel free to schedule a call with them using the below 'Schedule a conversation' link.`,
      )
    } else {
      messages['directCc'].push(
        `${client?.firstName} ${client?.lastName} (cc'd) is after some advice on ${serviceAreaNamesString}, this is to introduce you to my ${toCompany.presentationName} colleagues.`,
        compassReportMessage,
        clientDetails?.reason?.length ? `\n\n${clientDetails.reason}` : '',
      )
      messages['indirect'].push(
        `You have requested advice concerning ${serviceAreaNamesString}, this is to introduce you to my ${toCompany.presentationName} colleagues. Feel free to schedule a call with them using the below 'Schedule a conversation' link.`,
      )
    }

    const message = messages[referralType]

    return message.join('')
  }

  isValidReferralTarget(value?: unknown): value is ReferralMethod {
    return (
      typeof value === 'string' && Object.keys(REFERRAL_METHOD).includes(value)
    )
  }

  getMostRestrictedServiceType(
    selectedServices: ReferralFirmCollectionItem['services'],
  ): string {
    if (selectedServices.length === 0) {
      return FCA_SERVICE_TYPE_NAMES.no
    }

    const servicesOrderedByRestrictionLevel = selectedServices.sort(
      (serviceA, serviceB) => {
        const serviceARestrictionLevel =
          serviceA.serviceType?.restrictionLevel || 0
        const serviceBRestrictionLevel =
          serviceB.serviceType?.restrictionLevel || 0

        return serviceBRestrictionLevel - serviceARestrictionLevel
      },
    )

    const mostRestrictedService = servicesOrderedByRestrictionLevel[0]

    return mostRestrictedService?.serviceType?.name || FCA_SERVICE_TYPE_NAMES.no
  }

  makeReferralFirmIndividual(
    individual: ReferralFirmIndividualsCollectionItem,
  ): ReferralIndividual {
    return {
      companyUserId: individual.id,
      userId: individual.user.id,
      fullName: individual.user.fullName,
      email: individual.user.email,
    }
  }

  clearCommissionQuestions(
    clientDetails: ClientDetailsInput,
  ): ClientDetailsInput {
    return {
      ...clientDetails,
      isExpectingCommission: undefined,
      knowsCommissionAmount: undefined,
      expectedCommissionAmount: undefined,
    }
  }

  getFirmDisabledReason(options: {
    referralFirm: ReferralFirmCollectionItem
    companyTypes: CompanyTypeItem[]
    selectedServiceAreaIds: number[]
  }): DisabledReason | undefined {
    const { referralFirm, companyTypes, selectedServiceAreaIds } = options

    const matchingServices = referralFirm.services.filter((service) =>
      selectedServiceAreaIds.includes(service.serviceArea.id),
    )

    const firmHasAllSelectedServices =
      matchingServices.length === selectedServiceAreaIds.length

    const mostRestrictedServiceType =
      referClientService.getMostRestrictedServiceType(matchingServices)

    const isApproved =
      referralFirm.panelWithCurrentCompany.status === PANEL_STATUS.approved

    const companyType = companyTypes.find(
      (companyType) => companyType['@id'] === referralFirm.companyType,
    )

    const isFcaFirm = companyType?.fca

    const nonStandaloneServicesHaveFinancialPlanSelected =
      !matchingServices.some((service) => !service.isStandaloneService) ||
      selectedServiceAreaIds.includes(
        SERVICE_AREA_ID_CREATION_OF_A_FINANCIAL_PLAN,
      )

    if (!firmHasAllSelectedServices && !isApproved) {
      return DISABLED_REASON.missingServiceAndUnapproved
    }

    if (!firmHasAllSelectedServices) {
      return DISABLED_REASON.missingService
    }

    if (!isApproved) {
      return DISABLED_REASON.unapproved
    }

    if (!nonStandaloneServicesHaveFinancialPlanSelected) {
      return DISABLED_REASON.nonStandaloneServicesHaveFinancialPlanSelected
    }

    if (isFcaFirm && mostRestrictedServiceType === FCA_SERVICE_TYPE_NAMES.no) {
      return DISABLED_REASON.fcaFirmRestrictedService
    }

    return
  }

  buildCreateClientRequest(
    input: ClientDetailsInput,
  ): CreateClientForReferralRequest {
    const [firstName, lastName] = clientService.getFirstAndLastName(
      input.fullName || '',
    )

    const dateOfBirth = input.dateOfBirth
      ? normalizeDateOfBirth(input.dateOfBirth)
      : undefined

    const request: CreateClientForReferralRequest = {
      firstName: firstName || '',
      lastName: lastName || '',
      email: input.email,
      dateOfBirth,
      telephone: input.telephone || '',
      clientId: input.clientId || '',
      addressLine1: input.addressLine1 || '',
      city: input.city || '',
    }

    if (input.postcode) {
      request.postcode = input.postcode
    }

    return request
  }

  getPatchedFields(
    client: ClientSelectOption | null,
    formValues?: ClientDetailsInput,
  ): Array<keyof UpdateClientForReferralRequest> {
    const fields: Array<keyof UpdateClientForReferralRequest> = []

    // If we don't have a client, then no fields have been patched
    if (!client || !formValues) {
      return fields
    }

    if (!client.telephone && !!formValues.telephone) {
      fields.push('telephone')
    }

    if (!client.dateOfBirth && !!formValues.dateOfBirth) {
      fields.push('dateOfBirth')
    }

    if (!client.clientId && !!formValues.clientId) {
      fields.push('clientId')
    }

    if (!client.addressLine1 && !!formValues.addressLine1) {
      fields.push('addressLine1')
    }

    if (!client.city && !!formValues.city) {
      fields.push('city')
    }

    if (!client.postcode && !!formValues.postcode) {
      fields.push('postcode')
    }

    return fields
  }

  buildInitialClientDetailsValues(
    previousInput?: ClientDetailsInput,
  ): ClientDetailsInput {
    const expectedCommissionAmount =
      typeof previousInput?.expectedCommissionAmount === 'number'
        ? previousInput.expectedCommissionAmount
        : undefined

    return {
      fullName: previousInput?.fullName || '',
      email: previousInput?.email || '',
      telephone: previousInput?.telephone || '',
      dateOfBirth: referClientService.getFormattedDateOfBirth(
        previousInput?.dateOfBirth,
      ),
      clientId: previousInput?.clientId || '',
      addressLine1: previousInput?.addressLine1 || '',
      city: previousInput?.city || '',
      postcode: previousInput?.postcode || '',
      isExpectingCommission: previousInput?.isExpectingCommission,
      knowsCommissionAmount: previousInput?.knowsCommissionAmount,
      expectedCommissionAmount,
      circumstances: previousInput?.circumstances || '',
      reason: previousInput?.reason || '',
    }
  }

  // Get a date of birth in the DD/MM/YYYY format
  getFormattedDateOfBirth(dateOfBirth?: string | null): string | undefined {
    // Extract just the date part from the datetime string
    const date = (dateOfBirth || '').split('T')[0] || ''

    // No date, so return undefined
    if (!date) {
      return
    }

    // Date of birth format already valid, so return it
    if (isValidDateFormat(date, DATE_FORMATS.DAY_MONTH_YEAR)) {
      return date
    }

    // Date of birth format is YYYY-MM-DD, so convert it to DD/MM/YYYY
    if (isValidDateFormat(date, DATE_FORMATS.YEAR_MONTH_DAY)) {
      return convertDate(
        date,
        DATE_FORMATS.YEAR_MONTH_DAY,
        DATE_FORMATS.DAY_MONTH_YEAR,
      )
    }

    return undefined
  }
}

const referClientService = new ReferClientService()

export interface UserEmailMessageContext {
  client: ClientItem
  clientDetails: ClientDetailsInput
  referralType: NormalReferralTypeOption
  toCompany: Pick<CompanyEntity, 'presentationName'>
  toCompanyContactNames: ReferralEmailItem['toNames']
  selectedServiceAreas: ServiceAreaItem[]
  compassReport?: CompassReportCollectionItem
  companyTypes: CompanyTypeItem[]
  companyTypeCode: CompanyTypeCode
  metadata: NewReferralMetadataItem
}

export default referClientService
