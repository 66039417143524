import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'

import companyExpertiseAreaService from '../../../../../../lib/services/companyExpertiseAreaService'
import ActionButton from '../../../../../misc/ActionButton'

interface Props {
  /**
   * The ID of the joining record between Company and ExpertiseArea (if one exists)
   */
  companyExpertiseAreaId?: number
  expertiseAreaId: number
  expertiseAreaName: string
  companyId: number
  onChangeSaved: () => void
}

const CompanyExpertiseAreaToggleButton: React.FC<Props> = ({
  companyExpertiseAreaId,
  expertiseAreaId,
  expertiseAreaName,
  companyId,
  onChangeSaved,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(
    companyExpertiseAreaId !== undefined,
  )

  // Watch for changes
  useEffect(() => {
    setIsSelected(companyExpertiseAreaId !== undefined)
  }, [companyExpertiseAreaId])

  const postMutation = useMutation({
    mutationFn: async () => {
      setIsSelected(true)
      return companyExpertiseAreaService.post(companyId, expertiseAreaId)
    },
    onSuccess: () => {
      onChangeSaved()
    },
    onError: () => {
      // Put this back how we found it before the mutation started
      setIsSelected(false)
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async () => {
      if (companyExpertiseAreaId === undefined) {
        return
      }

      setIsSelected(false)
      return companyExpertiseAreaService.delete(companyExpertiseAreaId)
    },
    onSuccess: () => {
      onChangeSaved()
    },
    onError: () => {
      // Put this back how we found it before the mutation started
      setIsSelected(true)
    },
  })

  // I.e. the prop passed doesn't reflect what we now think the state should be
  // which will be because we're waiting to re-query in the parent component
  const isWaitingForSync = (companyExpertiseAreaId !== undefined) !== isSelected

  return (
    <ActionButton
      variant={isSelected ? 'primary' : 'outline-secondary'}
      size="lg"
      className="fs-5"
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      isProcessing={
        postMutation.isPending || deleteMutation.isPending || isWaitingForSync
      }
      // Don't change text during processing
      isProcessingText={expertiseAreaName}
      onClick={() => {
        if (isSelected) {
          deleteMutation.mutate()
        } else {
          postMutation.mutate()
        }
      }}
    >
      {expertiseAreaName}
    </ActionButton>
  )
}

export default CompanyExpertiseAreaToggleButton
