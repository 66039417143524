import { ObjectMap } from '../../../../../types/misc'
import {
  DefinedBenefitInput,
  DefinedBenefitStatus,
  PersonalAssetsInput,
  PropertyInput,
} from '../../utils/types'

export const PersonalAssetsInputFields: ObjectMap<PersonalAssetsInput> = {
  // Joint
  joint_current_savings: 'joint_current_savings',
  joint_current_investments: 'joint_current_investments',
  joint_has_properties: 'joint_has_properties',
  joint_properties: 'joint_properties',
  joint_has_rental_property: 'joint_has_rental_property',

  // User
  user_current_savings: 'user_current_savings',
  user_current_pension: 'user_current_pension',
  user_current_investments: 'user_current_investments',
  user_has_defined_benefit_scheme: 'user_has_defined_benefit_scheme',
  user_defined_benefits: 'user_defined_benefits',
  user_has_properties: 'user_has_properties',
  user_properties: 'user_properties',
  user_has_rental_property: 'user_has_rental_property',

  // Partner
  partner_current_savings: 'partner_current_savings',
  partner_current_pension: 'partner_current_pension',
  partner_current_investments: 'partner_current_investments',
  partner_has_defined_benefit_scheme: 'partner_has_defined_benefit_scheme',
  partner_defined_benefits: 'partner_defined_benefits',
  partner_has_properties: 'partner_has_properties',
  partner_properties: 'partner_properties',
  partner_has_rental_property: 'partner_has_rental_property',
}

export const PropertyInputFields: ObjectMap<PropertyInput> = {
  property_name: 'property_name',
  property_estimated_value: 'property_estimated_value',
  has_outstanding_mortgage: 'has_outstanding_mortgage',
  mortgage: 'mortgage',
}

export const DEFINED_BENEFIT_STATUS = {
  active: 'Active',
  deferred: 'Deferred',
  activeAndDeferred: 'Active & Deferred',
  dontKnow: "I don't know",
} satisfies Record<string, DefinedBenefitStatus>
export const DefinedBenefitInputFields: Record<
  keyof DefinedBenefitInput,
  string
> = {
  defined_benefit_commence_month: 'defined_benefit_commence_month',
  defined_benefit_commence_year: 'defined_benefit_commence_year',
  defined_benefit_status: 'defined_benefit_status',
  defined_benefit_annual_gross_benefit: 'defined_benefit_annual_gross_benefit',
  defined_benefit_is_unknown: 'defined_benefit_is_unknown',
}
