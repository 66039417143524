import React from 'react'

import { EMAIL_ADDRESSES } from '../../../lib/constants'
import { buildUrl } from '../../../lib/helpers/helperFunctions'
import { filterText, stripTags } from '../../../pages/inviteToRq/helpers'

interface Props {
  email?: string
  subject: string
  body?: string
  children: string
  className?: string
}

const MailTo: React.FC<Props> = ({
  email = '',
  subject,
  body = '',
  children,
  className = 'btn btn-outline-secondary btn-lg',
}) => {
  body = filterText(body)

  // @todo use html-to-text instead
  body = stripTags(body)

  const mailtoUrl = buildUrl(`mailto:${email}`, {
    cc: EMAIL_ADDRESSES.rqHello,
    subject,
    body,
  })

  return (
    <a href={mailtoUrl} className={className} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

export default MailTo
