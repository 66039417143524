import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import ActionButton from '../../../../../components/misc/ActionButton'
import useAppSelector from '../../../../../hooks/useAppSelector'
import useNotyf from '../../../../../hooks/useNotyf'
import { useRqCertifiedAssessmentOrFail } from '../../../../../hooks/useRqCertifiedAssessmentQuery'
import { useShowGenericServerError } from '../../../../../hooks/useShowGenericServerError'
import { QUERY_KEYS } from '../../../../../lib/queryKeys'
import rqCertifiedAssessmentService from '../../../../../lib/services/rqCertifiedAssessmentService'
import { selectCurrentCompanyOrFail } from '../../../../../redux/slices/session'
import PaymentDetails from '../../PaymentDetails'

interface Props {
  onHide: () => void
}

const MakePaymentClaimModal: React.FC<Props> = ({ onHide }) => {
  const currentCompany = useAppSelector(selectCurrentCompanyOrFail)
  const assessment = useRqCertifiedAssessmentOrFail()
  const notyf = useNotyf()
  const queryClient = useQueryClient()
  const showGenericServerError = useShowGenericServerError()
  const stage = assessment.currentStage

  const submitPaymentClaim = useMutation({
    mutationFn: async () =>
      rqCertifiedAssessmentService.submitPaymentClaim(currentCompany.id, {
        assessmentStage: stage,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.rqCertifiedAssessment,
      })
      notyf.success('Thank you!')
    },
    onError: showGenericServerError,
  })

  return (
    <>
      <Modal show onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Start your application</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <>
            <p>
              {assessment.isAtStageOne ? (
                <InitialAssessmentInstructions />
              ) : (
                <FullAssessmentInstructions />
              )}
            </p>

            <p>
              <PaymentDetails />
            </p>

            <p className="mb-0">
              We will notify you when your payment details have been confirmed,
              and you can continue your application.
            </p>
          </>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <Button variant="outline-secondary" onClick={onHide}>
            Close
          </Button>

          <ActionButton
            isProcessing={submitPaymentClaim.isPending}
            variant="primary"
            onClick={() => {
              submitPaymentClaim.mutate()
            }}
          >
            I've paid
          </ActionButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const InitialAssessmentInstructions: React.FC = () => {
  const currentCompany = useAppSelector(selectCurrentCompanyOrFail)

  return (
    <>
      Before you begin, there is an application processing fee of £450
      (Including VAT). Please use the details below with the payment reference "
      {currentCompany.id}-RQC-1".
    </>
  )
}

const FullAssessmentInstructions: React.FC = () => {
  const currentCompany = useAppSelector(selectCurrentCompanyOrFail)

  return (
    <>
      Before you begin, there is an application processing fee of £1,200
      (Including VAT) and once certified, there is an annual charge of £1,200
      thereafter. Please use the details below with the payment reference "
      {currentCompany.id}-RQC-2".
    </>
  )
}

export default MakePaymentClaimModal
