import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FEEDBACK_CATEGORY_KEYS } from '../../components/flows/CompassFlow/utils/constants'
import {
  CompassClientCollectionItem,
  CompassClientCollectionItemReport,
} from '../../types/responses/compass-clients'
import { RootState } from '../store'

export type SnapshotDetail = {
  client: CompassClientCollectionItem
  snapshot: CompassClientCollectionItemReport
}

interface CompassClientsState {
  isShowingAllColumns: boolean
  selectedClients: CompassClientCollectionItem[]
}

const INITIAL_STATE: CompassClientsState = {
  isShowingAllColumns: false,
  selectedClients: [],
}

const DEFAULT_NUM_COLUMNS = 6

const compassClients = createSlice({
  name: 'compassClients',
  initialState: INITIAL_STATE,
  reducers: {
    setIsShowingAllColumns(state, action: PayloadAction<boolean>) {
      state.isShowingAllColumns = action.payload
    },

    toggleClientSelection(
      state,
      action: PayloadAction<CompassClientCollectionItem>,
    ) {
      const selectedClients = state.selectedClients
      const toggledClient = action.payload

      const isAlreadySelected = selectedClients.some(
        (client) => client.id === toggledClient.id,
      )

      // Remove client from selected clients if it's already selected
      if (isAlreadySelected) {
        state.selectedClients = selectedClients.filter(
          (client) => client.id !== toggledClient.id,
        )
      }
      // Add client to selected clients if it's not already selected
      else {
        state.selectedClients = [...selectedClients, toggledClient]
      }
    },

    clearSelectedClients(state) {
      state.selectedClients = []
    },
  },
})

export const selectCompassClients = (state: RootState) => state.compassClients

export const selectIsShowingAllColumns = createSelector(
  selectCompassClients,
  (compassClients) => compassClients.isShowingAllColumns,
)

export const selectSelectedClients = createSelector(
  selectCompassClients,
  (compassClients) => compassClients.selectedClients,
)

export const selectHasSelectedClient = createSelector(
  selectSelectedClients,
  (selectedClients) => selectedClients.length > 0,
)

export const selectNumVisibleColumns = createSelector(
  selectIsShowingAllColumns,
  (isShowingAllColumns) =>
    isShowingAllColumns
      ? DEFAULT_NUM_COLUMNS + FEEDBACK_CATEGORY_KEYS.length
      : DEFAULT_NUM_COLUMNS,
)

export const {
  setIsShowingAllColumns,
  toggleClientSelection,
  clearSelectedClients,
} = compassClients.actions

export const compassClientsReducer = compassClients.reducer

export function isClientSelected(
  selectedClients: CompassClientsState['selectedClients'],
  client: CompassClientCollectionItem,
) {
  return selectedClients.some(
    (selectedClient) => selectedClient.id === client.id,
  )
}
