import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faWarning } from '@rq-ratings/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap'

import usePosthogCapture from '../../../hooks/posthogCapture/usePosthogCapture'
import useCurrentCompanyOrFail from '../../../hooks/useCurrentCompanyOrFail'
import useNotyf from '../../../hooks/useNotyf'
import companyService from '../../../lib/services/companyService'
import errorTypeService from '../../../lib/services/errorTypeService'
import inviteReferralPartnerService from '../../../lib/services/inviteReferralPartnerService'
import { ConstraintViolation } from '../../../types/api'
import InviteReferralPartnerEmailPreview from '../../modals/InviteReferralPartnerEmailPreview'
import EmailAddresses from '../EmailAddresses/EmailAddresses'
import CopyLink from '../PanelInviteEmailTemplate/CopyLink'

interface InviteReferralPartnerFormValues {
  email: string
}

interface CreateInviteReferralPartnerMutationVariables {
  values: InviteReferralPartnerFormValues
}

interface SubmissionResult {
  email: string
  messages?: string[]
  success: boolean
}

const InviteReferralPartners: React.FC = () => {
  const [emailAddresses, setEmailAddresses] = useState<string[]>([])
  const [emailAddressesAreValid, setEmailAddressesAreValid] =
    useState<boolean>(false)
  const { posthogCapture } = usePosthogCapture()

  const [showEmailPreview, setShowEmailPreview] = useState<boolean>(false)

  const [submissionResults, setSubmissionResults] = useState<
    SubmissionResult[]
  >([])

  const notyf = useNotyf()

  const currentCompany = useCurrentCompanyOrFail()

  const createInviteMutation = useMutation({
    mutationFn: async ({
      values,
    }: CreateInviteReferralPartnerMutationVariables) =>
      inviteReferralPartnerService.createInvite({
        email: values.email,
      }),
    onSuccess: async (data) => {
      setSubmissionResults([
        ...submissionResults,
        {
          email: data.email,
          success: true,
        },
      ])
    },
    onMutate: () => {
      posthogCapture({
        appArea: 'relationship-management',
        action: 'invite-sent-via-rq',
      })
    },
    onError: async (error, { values }) => {
      if (errorTypeService.isConstraintViolationListError(error)) {
        const violationMessages =
          error.response?.data.violations.map(
            (violation: ConstraintViolation): string => violation.message,
          ) ?? []

        if (violationMessages.length > 0) {
          setSubmissionResults([
            ...submissionResults,
            {
              email: values.email,
              messages: violationMessages,
              success: false,
            },
          ])
        }
      } else {
        notyf.error('Failed to send invitation')
      }
    },
  })

  const successfulSubmissionResults = submissionResults.filter(
    (submissionResult: SubmissionResult): boolean => submissionResult.success,
  )
  const unsuccessfulSubmissionResults = submissionResults.filter(
    (submissionResult: SubmissionResult): boolean => !submissionResult.success,
  )

  const shareLink = companyService.getInvitationLink(
    currentCompany.referralCode,
  )

  async function sendInvite(): Promise<void> {
    setSubmissionResults([])

    if (!emailAddressesAreValid || emailAddresses.length === 0) {
      // Don't bother doing anything - there's already a validation
      // UI, and the button should be disabled at this point anyway
      return
    }

    for (const emailAddress of emailAddresses) {
      const values: InviteReferralPartnerFormValues = {
        email: emailAddress,
      }

      // Send each invite request sequentially - it's more polite for
      // the API if there's a large number of these, plus it makes the
      // results UI look a lot smoother
      try {
        await createInviteMutation.mutateAsync({ values })
      } catch {
        // do nothing - onError already handles errors
      }
    }

    // Reset the form to help avoid duplicate submissions
    setEmailAddresses(
      unsuccessfulSubmissionResults.map(
        (submissionResult: SubmissionResult): string => submissionResult.email,
      ),
    )
  }

  return (
    <Form>
      {successfulSubmissionResults.length > 0 && (
        <Alert variant="success" className="p-3 d-block fade-in">
          <h3 className="h4 d-flex gap-2 align-items-center">
            <span
              className="rounded-circle bg-success text-white d-inline-flex align-items-center justify-content-center fs-5"
              style={{ height: '1.5rem', width: '1.5rem' }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </span>
            Invites sent
          </h3>

          <ul className="mb-0 list-style-type-none">
            {successfulSubmissionResults.map(
              (submissionResult: SubmissionResult) => (
                <li key={submissionResult.email} className="fade-in">
                  {submissionResult.email}
                </li>
              ),
            )}
          </ul>
        </Alert>
      )}

      {unsuccessfulSubmissionResults.length > 0 && (
        <Alert variant="danger" className="p-3 d-block fade-in">
          <h3 className="h4 d-flex gap-2 align-items-center">
            <span
              className="rounded-circle bg-danger text-white d-inline-flex align-items-center justify-content-center fs-5"
              style={{ height: '1.5rem', width: '1.5rem' }}
            >
              <FontAwesomeIcon icon={faWarning} />
            </span>
            These invites couldn't be sent
          </h3>

          <ul className="mb-0">
            {unsuccessfulSubmissionResults.map(
              (submissionResult: SubmissionResult) => (
                <li key={submissionResult.email} className="fade-in">
                  <strong>{submissionResult.email}</strong>
                  {` - ${submissionResult.messages?.join(' | ')}`}
                </li>
              ),
            )}
          </ul>
        </Alert>
      )}

      {submissionResults.length > 0 && <h3>Send more invites</h3>}

      <Form.Group className="mb-3">
        <Form.Label>To:</Form.Label>

        <EmailAddresses
          values={emailAddresses}
          onChange={(newValues: string[], hasErrors: boolean): void => {
            setEmailAddresses(newValues)
            setEmailAddressesAreValid(newValues.length > 0 && !hasErrors)
          }}
        />
      </Form.Group>

      <div className="d-flex justify-content-between align-items-start mb-3">
        <CopyLink
          variant="link"
          textToCopy={shareLink}
          buttonText="Or copy your unique invite link"
          className="p-0"
        />

        <Button
          disabled={!emailAddressesAreValid && !createInviteMutation.isPending}
          variant="primary"
          size="lg"
          onClick={(): Promise<void> => sendInvite()}
        >
          {createInviteMutation.isPending ? (
            <>
              Sending
              <Spinner size="sm" className="ms-2" />
            </>
          ) : (
            <>Send invite</>
          )}
        </Button>
      </div>

      <div className="d-flex justify-content-end">
        <Button
          variant="link"
          className="p-0"
          onClick={() => setShowEmailPreview(true)}
        >
          Preview invite
        </Button>

        {showEmailPreview && (
          <Modal
            show
            centered
            size="lg"
            onHide={() => setShowEmailPreview(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <strong className="fs-2">Preview of invitation email</strong>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <InviteReferralPartnerEmailPreview />

              <div className="mt-3 d-flex justify-content-end">
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowEmailPreview(false)}
                >
                  Close
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </Form>
  )
}

export default InviteReferralPartners
