import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { QUERY_KEYS } from '../lib/queryKeys'
import compassReportService from '../lib/services/compass/compassReportService'
import { setLatestCompassReport } from '../redux/slices/clientPortal'
import useAppDispatch from './useAppDispatch'

export function useLatestCompassReportQuery() {
  const dispatch = useAppDispatch()

  const latestCompassReportQuery = useQuery({
    queryKey: QUERY_KEYS.latestCompassReport,
    queryFn: () => compassReportService.getLatestCompassReport(),
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: keepPreviousData,
  })

  const { data: latestCompassReport } = latestCompassReportQuery

  useEffect(() => {
    if (latestCompassReport) {
      dispatch(setLatestCompassReport(latestCompassReport))
    }
  }, [dispatch, latestCompassReport])

  return latestCompassReportQuery
}
