import React from 'react'

export type EmailAddressDetails = {
  displayName: string
  emailAddress: string
}

export interface IItemState {
  from: EmailAddressDetails
  to: EmailAddressDetails[]
  cc: EmailAddressDetails[]
  internetMessageId: string
  conversationId: string
  itemType: string
  body: Office.Body
}

export interface IMailboxState {
  item?: IItemState
  userProfile: EmailAddressDetails
}

export interface IOfficeState {
  mailbox?: IMailboxState
}

export interface IOfficeContext {
  isInitialized: boolean
  state?: IOfficeState
}

export const OfficeContext = React.createContext<IOfficeContext>(
  {} as IOfficeContext,
)
