import classNames from 'classnames'
import React from 'react'

type Props = React.HTMLAttributes<HTMLHeadingElement>

const CardHeading: React.FC<Props> = ({ className, ...rest }) => (
  <h4
    className={classNames('text-black font-weight-700', className)}
    {...rest}
  />
)

export default CardHeading
