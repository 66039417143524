import React from 'react'
import { useEffectOnce } from 'react-use'

import LoadingWidget from '../components/widgets/LoadingWidget'
import { useCreateCompassMetadataMutation } from '../hooks/useCreateCompassMetadataMutation'

interface Props {
  children: React.ReactNode
}

const CompassMetadataProvider: React.FC<Props> = ({ children }) => {
  const createMetadataMutation = useCreateCompassMetadataMutation()

  useEffectOnce(() => {
    createMetadataMutation.mutate()
  })

  return createMetadataMutation.isSuccess ? <>{children}</> : <LoadingWidget />
}

export default CompassMetadataProvider
