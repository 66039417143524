import { CompanyProfileTab } from './CompanyProfile'

export const COMPANY_PROFILE_TAB: Record<CompanyProfileTab, CompanyProfileTab> =
  {
    overview: 'overview',
    servicesAndExpertise: 'servicesAndExpertise',
    reviews: 'reviews',
    team: 'team',
    monitoring: 'monitoring',
    commercialAgreements: 'commercialAgreements',
    yourComments: 'yourComments',
    referralLinks: 'referralLinks',
  }
