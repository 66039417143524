import { useFormikContext } from 'formik'
import React from 'react'

import {
  importFaker,
  randomBoolean,
} from '../../../../../../lib/helpers/randomData'
import PageActions from '../../../../../utils/PageActions'
import { AddressFormValues } from './CompanyAddressRow'

const DevActions: React.FC = () => {
  const { setValues } = useFormikContext<AddressFormValues>()

  return (
    <PageActions
      actions={[
        {
          label: 'Fill form',
          action: () => {
            importFaker().then((faker) => {
              setValues({
                street: faker.location.street(),
                county: faker.location.county(),
                isMainAddress: randomBoolean(),
                city: faker.location.city(),
                postcode: faker.location.zipCode(),
                telephone: faker.phone.number(),
              })
            })
          },
        },
      ]}
    />
  )
}

export default DevActions
