import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@rq-ratings/pro-solid-svg-icons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'

import { useCompanyProfileQuery } from '../../../../../hooks/useCompanyProfileQuery'
import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import { COMPANY_IDENTIFIER } from '../../../../../lib/constants'
import { QUERY_KEYS } from '../../../../../lib/queryKeys'
import addressService from '../../../../../lib/services/addressService'
import CompanyAddressRow, {
  AddressFormValues,
} from './components/CompanyAddressRow'

const CompanyOfficeLocations: React.FC = () => {
  const [newAddress, setNewAddress] = useState<AddressFormValues | null>(null)
  const currentCompany = useCurrentCompanyOrFail()
  const queryClient = useQueryClient()

  const companyProfileQuery = useCompanyProfileQuery({
    identifier: currentCompany.id.toString(),
    identifierType: COMPANY_IDENTIFIER.companyId,
  })

  const { companiesHouse, directory } = companyProfileQuery.data
  const isTradingName = !!directory?.fca?.frnTradingNameId
  const hasDefaultHq =
    !isTradingName && companiesHouse && companiesHouse.address

  const addressesQuery = useQuery({
    queryKey: QUERY_KEYS.addresses(),
    queryFn: () => addressService.getCurrentCompanyAddresses(),
  })

  useEffect(() => {
    if (
      !hasDefaultHq &&
      addressesQuery.data !== undefined &&
      addressesQuery.data.length === 0
    ) {
      setNewAddress({
        street: '',
        county: '',
        city: '',
        postcode: '',
        telephone: '',
        isMainAddress: true,
      })
    }
  }, [addressesQuery.data, hasDefaultHq])

  const addresses = addressesQuery.data

  function renderInner() {
    if (
      addressesQuery.isLoading ||
      !addresses ||
      companyProfileQuery.isLoading ||
      companyProfileQuery.data === undefined
    ) {
      return (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      )
    }

    // Main address first
    const orderedAddresses = addresses.toSorted((a) =>
      a.isMainAddress ? 1 : -1,
    )

    async function invalidateAddressesQuery() {
      return queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.addresses(),
      })
    }

    return (
      <div className="d-flex flex-column gap-3 pb-3">
        {hasDefaultHq && (
          <p>
            <strong>Your headquarters:</strong> {companiesHouse.address}
          </p>
        )}

        <div className="d-flex flex-column gap-3">
          {orderedAddresses.map((address) => {
            return (
              <>
                <CompanyAddressRow
                  key={address.id}
                  address={address}
                  allowDelete={true}
                  isEditingByDefault={false}
                  onSave={() => invalidateAddressesQuery()}
                  onDelete={() => invalidateAddressesQuery()}
                />
              </>
            )
          })}

          {newAddress && (
            <CompanyAddressRow
              address={newAddress}
              allowDelete={orderedAddresses.length > 0}
              isEditingByDefault={true}
              onSave={() => {
                invalidateAddressesQuery()

                setNewAddress(null)
              }}
              onDelete={() => setNewAddress(null)}
            />
          )}
        </div>

        <div className="d-flex justify-content-center">
          <Button
            onClick={() =>
              setNewAddress({
                street: '',
                county: '',
                city: '',
                postcode: '',
                telephone: '',
                isMainAddress: false,
              })
            }
            disabled={!!newAddress}
            variant="link"
            size="lg"
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Add another location
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="pop-in h-100 d-flex flex-column align-items-stretch">
      {renderInner()}
    </div>
  )
}

export default CompanyOfficeLocations
