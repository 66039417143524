import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

export type ActionButtonProps = ButtonProps & {
  isProcessing: boolean
  isProcessingText?: React.ReactNode
  showIsProcessingText?: boolean
  showSpinner?: boolean
}

const ActionButton = React.forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      children,
      isProcessing,
      isProcessingText = 'Processing',
      disabled = isProcessing,
      showIsProcessingText = true,
      showSpinner = true,
      ...props
    },
    ref,
  ) => {
    return (
      <Button disabled={disabled} {...props} ref={ref}>
        {isProcessing && showIsProcessingText ? isProcessingText : children}

        {isProcessing && showSpinner && (
          <FontAwesomeIcon
            icon={faSpinner}
            className="fa-spin"
            style={{ marginLeft: '6px' }}
          />
        )}
      </Button>
    )
  },
)

export default ActionButton
