import React from 'react'
import { Modal } from 'react-bootstrap'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'

import LoadingOverlay from '../../widgets/LoadingOverlay'
import LoadingWidget from '../../widgets/LoadingWidget'

interface Props {
  companyName: string
  scheduleCallUrl: string
  onCallScheduled: () => void
  onHide: () => void
  isProcessing: boolean
}

const CalendlyModal: React.FC<Props> = ({
  companyName,
  scheduleCallUrl,
  onCallScheduled,
  onHide,
  isProcessing,
}) => {
  useCalendlyEventListener({
    onEventScheduled: () => {
      onCallScheduled()
    },
  })

  return (
    <>
      <Modal show onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Schedule a meeting</Modal.Title>
        </Modal.Header>

        <LoadingOverlay isActive={isProcessing}>
          <Modal.Body>
            <h2>{companyName}</h2>

            <InlineWidget
              url={scheduleCallUrl}
              styles={{ width: '100%', height: '700px' }}
              pageSettings={{ hideGdprBanner: true }}
              LoadingSpinner={LoadingWidget}
            />
          </Modal.Body>
        </LoadingOverlay>
      </Modal>
    </>
  )
}

export default CalendlyModal
