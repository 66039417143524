import invariant from 'tiny-invariant'

import { CompanyTypeCode } from '../../types/misc'
import { CompanyTypeItem } from '../../types/responses/common-data'
import { COMPANY_TYPE_CODE } from '../constants'

class CompanyTypeService {
  getCompanyTypeByCodeOrFail(
    companyTypes: CompanyTypeItem[],
    companyTypeCode: CompanyTypeCode,
  ): CompanyTypeItem {
    const companyType = this.getCompanyTypeByCode(companyTypes, companyTypeCode)

    invariant(
      companyType,
      `Company type with code '${companyTypeCode}' not found`,
    )

    return companyType
  }

  getCompanyTypeByCode(
    companyTypes: CompanyTypeItem[],
    companyTypeCode: CompanyTypeCode,
  ): CompanyTypeItem | undefined {
    return companyTypes.find(
      (companyType) => companyType.code === companyTypeCode,
    )
  }

  isValidCompanyTypeCode(value?: unknown): value is CompanyTypeCode {
    return (
      typeof value === 'string' &&
      Object.keys(COMPANY_TYPE_CODE).includes(value || '')
    )
  }
}

const companyTypeService = new CompanyTypeService()

export default companyTypeService
