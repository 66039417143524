import { COMPANY_TYPE_CODE } from '../../../../lib/constants'
import { InvitationEmails } from '../../../../pages/inviteToRq/types'
import { FromFcaFirmToAccountantEmail } from './FromFcaFirmToAccountantEmail'
import { FromFcaFirmToSolicitorEmail } from './FromFcaFirmToSolicitorEmail'
import { ToFcaFirmEmail } from './ToFcaFirmEmail'

export const FromInsuranceAdviserEmails: InvitationEmails = {
  accountant: FromFcaFirmToAccountantEmail.build(),
  solicitor: FromFcaFirmToSolicitorEmail.build(),
  ifa: ToFcaFirmEmail.build(
    COMPANY_TYPE_CODE.insurance_adviser,
    COMPANY_TYPE_CODE.ifa,
  ),
  insurance_adviser: null,
  mortgage_adviser: ToFcaFirmEmail.build(
    COMPANY_TYPE_CODE.insurance_adviser,
    COMPANY_TYPE_CODE.mortgage_adviser,
  ),
  corporate_finance: ToFcaFirmEmail.build(
    COMPANY_TYPE_CODE.insurance_adviser,
    COMPANY_TYPE_CODE.corporate_finance,
  ),
}
