import { QueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'

const MAX_RETRIES = 3
const HTTP_STATUSES_TO_NOT_RETRY = [404]

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // Don't retry more than MAX_RETRIES times
        if (failureCount > MAX_RETRIES) {
          return false
        }

        // Don't retry if the error is due to certain HTTP statuses
        if (
          isAxiosError(error) &&
          HTTP_STATUSES_TO_NOT_RETRY.includes(error.response?.status ?? 0)
        ) {
          return false
        }

        // Otherwise retry
        return true
      },
    },
  },
})

export default queryClient
