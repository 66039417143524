import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@rq-ratings/pro-regular-svg-icons'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { Spinner } from 'react-bootstrap'

import { useAppState } from '../../hooks/useAppState'
import { useCreateStateMutation } from '../../hooks/useCreateStateMutation'
import useNotyf from '../../hooks/useNotyf'
import {
  EXTERNAL_LINKS,
  LONG_MESSAGE_DURATION,
  STATE_KEY,
  STATE_TYPE,
} from '../../lib/constants'

const RqRoadmapBanner: React.FC = () => {
  const { hasInteractedWithRqRoadmapBanner } = useAppState()
  const createStateMutation = useCreateStateMutation()
  const notyf = useNotyf()

  const hasInteractedWithBannerMutation = useMutation({
    mutationFn: () =>
      createStateMutation.mutateAsync({
        stateKey: STATE_KEY.hasInteractedWithRqRoadmapBanner,
        stateValue: true,
        stateType: STATE_TYPE.user,
      }),
    onSuccess: () => {
      notyf.success({
        message:
          'You can access the roadmap at any time from your account dropdown',
        duration: LONG_MESSAGE_DURATION,
      })
    },
    onError: () => {
      notyf.error('There was a problem dismissing the banner')
    },
  })

  const shouldShow = !hasInteractedWithRqRoadmapBanner

  if (!shouldShow) {
    return null
  }

  const isClosing = hasInteractedWithBannerMutation.isPending

  return (
    <section className="d-flex justify-content-center align-items-center gap-5 bg-primary text-white text-center text-lg p-3">
      <div>
        View our{' '}
        <a
          className="text-reset text-decoration-underline"
          href={EXTERNAL_LINKS.rqRoadmap}
          target="_blank"
          rel="noreferrer"
        >
          roadmap
        </a>{' '}
        and have your say in what features we build next
      </div>

      {isClosing ? (
        <Spinner size="sm" className="hover-dim" />
      ) : (
        <FontAwesomeIcon
          onClick={() => hasInteractedWithBannerMutation.mutate()}
          icon={faTimes}
          className="text-xl"
          role="button"
          title="Dismiss"
        />
      )}
    </section>
  )
}

export default RqRoadmapBanner
