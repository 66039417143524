import React from 'react'

import { useIsUnauthenticatedClient } from '../flows/CompassFlow/utils/useIsUnauthenticatedClient'
import AuthGuard from './AuthGuard'

interface Props {
  children: React.ReactNode
}

// Use for pages where user must either be authenticated or have a valid
// Compass invite token
const CompassGuard: React.FC<Props> = ({ children }) => {
  const isClient = useIsUnauthenticatedClient()

  // If the user is a client, they can access without needing to authenticate
  return isClient ? <>{children}</> : <AuthGuard>{children}</AuthGuard>
}

export default CompassGuard
