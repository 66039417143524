import { FormikContextType, useFormikContext } from 'formik'
import { useDeepCompareEffect, useFirstMountState } from 'react-use'

interface Props<Values> {
  onChange: (formikContext: FormikContextType<Values>) => void
}

function FormikEffect<Values>({ onChange }: Props<Values>) {
  const formikContext = useFormikContext<Values>()
  const isFirstRender = useFirstMountState()
  const { values } = formikContext

  useDeepCompareEffect(() => {
    if (isFirstRender) {
      return
    }

    onChange(formikContext)
  }, [values])

  return null
}

export default FormikEffect
