import { useMedia } from 'react-use'

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

// See https://getbootstrap.com/docs/5.2/layout/breakpoints/
function useMinWidth(breakpoint: Breakpoint) {
  const breakpointDimensions: Record<Breakpoint, number> = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  }

  return useMedia(`(min-width: ${breakpointDimensions[breakpoint]}px)`)
}

export default useMinWidth
