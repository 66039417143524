import { useQueryClient } from '@tanstack/react-query'

import { QUERY_KEYS } from '../lib/queryKeys'

export function useInvalidateOnboardingChecklistQuery() {
  const queryClient = useQueryClient()

  // TODO: No need to invalidate if the user doesn't have a checklist
  return function () {
    return queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.onboardingChecklist(),
    })
  }
}
