import React from 'react'

const DiscountNote: React.FC = () => (
  <em>
    Note: if you use this link to sign up within the next 14 days, you will
    receive 15% off your first year’s subscription with RQ.
  </em>
)

export default DiscountNote
