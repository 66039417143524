import './assets/scss/app.scss'

import { setLocale } from 'yup'

import { ERROR_MESSAGES } from './lib/constants'

// Customise Yup's default error messages:
// https://github.com/jquense/yup#error-message-customization
setLocale({
  mixed: { required: ERROR_MESSAGES.required },
  string: { email: 'Please enter a valid email address' },
  number: {
    positive: 'Must be a positive number',
    min: ({ min }) => `Must be greater than or equal to ${min}`,
    max: ({ max }) => `Must be less than or equal to ${max}`,
  },
  array: { min: 'Please select at least one option' },
})
