import { useMutation } from '@tanstack/react-query'

import onboardingChecklistService from '../lib/services/onboardingChecklistService'
import { UpdateOnboardingChecklistRequest } from '../types/requests/onboarding-checklists'
import { useInvalidateOnboardingChecklistQuery } from './useInvalidateOnboardingChecklistQuery'

interface MutationOptions {
  checklistId: number
  request: UpdateOnboardingChecklistRequest
}

export function useUpdateOnboardingChecklistMutation() {
  const invalidateOnboardingChecklistQuery =
    useInvalidateOnboardingChecklistQuery()

  return useMutation({
    mutationFn: (options: MutationOptions) =>
      onboardingChecklistService.updateChecklist(
        options.checklistId,
        options.request,
      ),
    onSuccess: async () => {
      await invalidateOnboardingChecklistQuery()
    },
  })
}
