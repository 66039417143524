import React from 'react'

import Main from '../../misc/Main'
import SentryErrorBoundary from '../../misc/SentryErrorBoundary'

interface Props {
  children: React.ReactNode
}

const LandingBlackLayout: React.FC<Props> = ({ children }) => (
  <Main className="bg-black pb-3">
    <SentryErrorBoundary variant="full-screen">{children}</SentryErrorBoundary>
  </Main>
)

export default LandingBlackLayout
