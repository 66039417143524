import * as React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from '../../hooks/useAuth'
import { useHomeRoute } from '../../hooks/useHomeRoute'
import LoadingScreen from '../misc/LoadingScreen'

interface Props {
  children: React.ReactNode
}

// Use to prevent authenticated users from accessing a route
const GuestGuard: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth()
  const homeRoute = useHomeRoute()

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (isAuthenticated) {
    return <Navigate to={homeRoute} replace />
  }

  return <>{children}</>
}

export default GuestGuard
