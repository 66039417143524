import { CreateReferralPartnerInviteRequest } from '../../types/requests/invite-referral-artners'
import { InviteReferralPartnerItem } from '../../types/responses/invite-referral-partners'
import apiService from './apiService'

export default new (class InviteReferralPartnerService {
  private endpoint = '/v1/invite-referral-partners'

  public async createInvite(
    request: CreateReferralPartnerInviteRequest,
  ): Promise<InviteReferralPartnerItem> {
    const response = await apiService.post<InviteReferralPartnerItem>(
      this.endpoint,
      request,
    )

    return response.data
  }
})()
