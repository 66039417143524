import * as yup from 'yup'

import useAppSelector from '../../../../hooks/useAppSelector'
import {
  selectIsRequestingReferralCallbackForClient,
  selectIsSchedulingMeetingForClient,
  selectReferClientMetadataOrFail,
} from '../../../../redux/slices/referClient'
import { ReferralEmailItem } from '../../../../types/responses/referrals'
import { SelectReferralMethodInputFields } from './constants'

export function useSelectReferralMethodValidationSchema() {
  const metadata = useAppSelector(selectReferClientMetadataOrFail)
  const isRequestingReferralCallbackForClient = useAppSelector(
    selectIsRequestingReferralCallbackForClient,
  )
  const isSchedulingMeetingForClient = useAppSelector(
    selectIsSchedulingMeetingForClient,
  )

  return yup.object().shape({
    [SelectReferralMethodInputFields.confirmIncidentalReferral]: yup
      .boolean()
      .when('_', (_, schema) => {
        if (metadata.shouldConfirmIncidentalReferral) {
          return schema.oneOf(
            [true],
            'You must confirm that this referral is incidental.',
          )
        }

        return schema
      }),

    [SelectReferralMethodInputFields.callbackTelephone]: yup
      .string()
      .when('_', (_, schema) => {
        if (isRequestingReferralCallbackForClient) {
          return schema.required('Please provide a telephone number')
        }

        return schema
      }),

    [SelectReferralMethodInputFields.callbackTimes]: yup
      .array()
      .when('_', (_, schema) => {
        if (isRequestingReferralCallbackForClient) {
          return schema
            .min(1, 'Please select at least one callback time')
            .required('required')
        }

        return schema
      }),

    [SelectReferralMethodInputFields.hasConfirmedBookedCall]: yup
      .boolean()
      .when('_', (_, schema) => {
        if (isSchedulingMeetingForClient) {
          return schema
            .oneOf(
              [true],
              'Please confirm that you have booked a call for the client before proceeding',
            )
            .required()
        }

        return schema
      }),
  })
}

export function getToCompanyContactName(
  toNames: ReferralEmailItem['toNames'],
): string {
  // If the only user is 'RQ Admin', display 'Adviser' instead
  if (toNames.length === 1 && toNames[0] === 'RQ') {
    return 'Adviser'
  }

  // If there are multiple users, always prefer names of other users over
  // the 'RQ Admin' user
  const toNamesSorted = toNames.sort((nameA, nameB) => {
    if (nameA === 'RQ' || nameB === 'RQ') {
      return -1
    }

    return 0
  })

  return toNamesSorted.length > 1
    ? `${toNamesSorted[0]} and team`
    : `${toNamesSorted[0]}`
}
