import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import invariant from 'tiny-invariant'

import useAppSelector from '../../../hooks/useAppSelector'
import { useClientMetadata } from '../../../hooks/useClientMetadata'
import useNotyf from '../../../hooks/useNotyf'
import { COMPASS_REPORT_STATUS } from '../../../lib/constants'
import { getOrFail, isCalendlyUrl } from '../../../lib/helpers/helperFunctions'
import companyService from '../../../lib/services/companyService'
import { selectCompassReport } from '../../../redux/slices/compass'
import {
  CompanyRequestCallRequest,
  CreateCompanySelfReferralRequest,
} from '../../../types/requests/companies'
import { useCompassMetadataOrFail } from '../../flows/CompassFlow/utils/useCompassMetadataOrFail'
import {
  defaultServiceAreaIds,
  isServiceAreaIdsEmpty,
  ServiceAreaIds,
} from '../CompassPartner/helpers'
import { RequestCallbackFormInput } from '../RequestReferralCallbackForm'
import CalendlyModal from './CalendlyModal'
import NonCalendlyModal from './NonCalendlyModal'
import RequestCallbackModal from './RequestCallbackModal'
import ShareCompassReportModal from './ShareCompassReportModal'

interface Props {
  company: {
    id: number
    presentationName: string
    scheduleClientCallUrl: string | null
    requireConsent?: boolean
  }
  serviceAreaIds?: ServiceAreaIds
  isCreateReferral?: boolean
  onHoverEnter?: () => void
  onHoverLeave?: () => void
}

type ModalType =
  | 'compassSharing'
  | 'requestCallback'
  | 'bookMeetingViaCalendly'
  | 'bookMeetingNonCalendly'

const MODAL_TYPE: Record<ModalType, ModalType> = {
  compassSharing: 'compassSharing',
  requestCallback: 'requestCallback',
  bookMeetingViaCalendly: 'bookMeetingViaCalendly',
  bookMeetingNonCalendly: 'bookMeetingNonCalendly',
}

const RequestCompanyCallbackButton: React.FC<Props> = ({
  company,
  serviceAreaIds = [],
  isCreateReferral = true,
  onHoverEnter,
  onHoverLeave,
}) => {
  const notyf = useNotyf()
  const [isSharingCompassData, setIsSharingCompassData] = useState(false)
  const [activeModal, setActiveModal] = useState<ModalType | null>(null)
  const [companyIdsReferredToAlready, setCompanyIdsReferredToAlready] =
    useState<number[]>([])
  const clientMetadata = useClientMetadata()
  const compassMetadata = useCompassMetadataOrFail()
  const sponsorCompanyId =
    clientMetadata?.sponsorCompany?.id ?? compassMetadata.company?.id

  const compassReport = useAppSelector(selectCompassReport)
  const latestCompassReportIri =
    compassReport?.['@id'] &&
    compassReport.status === COMPASS_REPORT_STATUS.COMPLETE
      ? compassReport['@id']
      : clientMetadata?.latestCompletedCompassReport

  const requireDataSharingOptIn =
    sponsorCompanyId !== company.id && (company.requireConsent ?? true)

  invariant(
    sponsorCompanyId,
    'Expected either sponsorCompany or compass company to be set',
  )
  const sponsorCompanyIri = `/v1/companies/${sponsorCompanyId}`

  const hasAlreadyBeenReferredTo = companyIdsReferredToAlready.includes(
    company.id,
  )

  const scheduleCallUrl = company.scheduleClientCallUrl ?? ''

  function handleCtaClick() {
    if (hasAlreadyBeenReferredTo) {
      return
    }

    if (requireDataSharingOptIn) {
      setActiveModal(MODAL_TYPE.compassSharing)
      return
    }

    setIsSharingCompassData(true)
    showBookCallModal()
  }

  function showBookCallModal() {
    if (isCalendlyUrl(scheduleCallUrl)) {
      setActiveModal(MODAL_TYPE.bookMeetingViaCalendly)
      return
    }

    if (scheduleCallUrl) {
      setActiveModal(MODAL_TYPE.bookMeetingNonCalendly)
      return
    }

    setActiveModal(MODAL_TYPE.requestCallback)
  }

  function hideModal() {
    setActiveModal(null)
  }

  const requestCallbackMutation = useMutation({
    mutationFn: (values: RequestCallbackFormInput) => {
      const request: CompanyRequestCallRequest = {
        token: compassReport?.token,
        callbackTelephone: values.telephone,
        callbackTimes: values.times,
      }

      return companyService.createCompanyRequestCallRequest(company.id, request)
    },
    onSuccess: handleReferralCreatedSuccess,
    onError: handleReferralCreatedError,
  })

  const requestReferralCallbackMutation = useMutation({
    mutationFn: (values: RequestCallbackFormInput) => {
      const request: CreateCompanySelfReferralRequest = {
        token: compassReport?.token,
        sponsorCompany: sponsorCompanyIri,
        shareData: isSharingCompassData || !requireDataSharingOptIn,
        compassReport:
          isSharingCompassData && latestCompassReportIri
            ? latestCompassReportIri
            : undefined,
        serviceAreaIds: !isServiceAreaIdsEmpty(serviceAreaIds)
          ? serviceAreaIds
          : defaultServiceAreaIds(),
        callbackTelephone: values.telephone,
        callbackTimes: values.times,
      }

      return companyService.createCompanySelfReferralRequest(
        company.id,
        request,
      )
    },
    onSuccess: handleReferralCreatedSuccess,
    onError: handleReferralCreatedError,
  })

  const scheduleCallMutation = useMutation({
    mutationFn: async () => {
      if (hasAlreadyBeenReferredTo || !isCreateReferral) {
        return
      }

      const request: CreateCompanySelfReferralRequest = {
        token: compassReport?.token,
        sponsorCompany: sponsorCompanyIri,
        shareData: isSharingCompassData || !requireDataSharingOptIn,
        compassReport:
          isSharingCompassData && latestCompassReportIri
            ? latestCompassReportIri
            : undefined,
        serviceAreaIds: !isServiceAreaIdsEmpty(serviceAreaIds)
          ? serviceAreaIds
          : defaultServiceAreaIds(),
      }

      return companyService.createCompanySelfReferralRequest(
        company.id,
        request,
      )
    },
    onSuccess: handleReferralCreatedSuccess,
    onError: handleReferralCreatedError,
  })

  function handleReferralCreatedSuccess() {
    setCompanyIdsReferredToAlready([...companyIdsReferredToAlready, company.id])

    notyf.open({
      message: isCreateReferral
        ? 'Your referral has been created'
        : 'Your callback request has been sent',
      className: 'max-w-none',
      type: 'success',
    })

    if (activeModal === MODAL_TYPE.bookMeetingNonCalendly) {
      hideModal()
    }
  }

  function handleReferralCreatedError() {
    notyf.open({
      type: 'danger',
      message: isCreateReferral
        ? 'Could not create referral'
        : 'Unable to process your callback request',
    })
  }

  function renderCta() {
    if (hasAlreadyBeenReferredTo) {
      return 'Callback requested'
    }

    return company.scheduleClientCallUrl ? 'Book a call' : 'Request a callback'
  }

  const isSchedulingCall = scheduleCallMutation.isPending

  function renderModal() {
    switch (activeModal) {
      case MODAL_TYPE.compassSharing:
        return (
          <ShareCompassReportModal
            company={company}
            onAnswer={(isSharing) => {
              setIsSharingCompassData(isSharing)
              showBookCallModal()
            }}
            onHide={hideModal}
          />
        )
      case MODAL_TYPE.requestCallback:
        return (
          <RequestCallbackModal
            company={company}
            callbackTelephone={
              isCreateReferral
                ? requestReferralCallbackMutation.data?.callbackTelephone
                : requestCallbackMutation.data?.callbackTelephone
            }
            onSubmit={(values: RequestCallbackFormInput) =>
              isCreateReferral
                ? requestReferralCallbackMutation.mutate(values)
                : requestCallbackMutation.mutate(values)
            }
            onHide={hideModal}
          />
        )
      case MODAL_TYPE.bookMeetingViaCalendly:
        return (
          <CalendlyModal
            companyName={company.presentationName}
            scheduleCallUrl={getOrFail(scheduleCallUrl)}
            onCallScheduled={() => scheduleCallMutation.mutate()}
            onHide={hideModal}
            isProcessing={isSchedulingCall}
          />
        )
      case MODAL_TYPE.bookMeetingNonCalendly:
        return (
          <NonCalendlyModal
            companyName={company.presentationName}
            scheduleCallUrl={getOrFail(scheduleCallUrl)}
            onBooked={() => scheduleCallMutation.mutate()}
            onHide={hideModal}
            isProcessing={isSchedulingCall}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <Button
        onClick={handleCtaClick}
        disabled={hasAlreadyBeenReferredTo}
        onFocus={onHoverEnter}
        onMouseEnter={onHoverEnter}
        onMouseLeave={onHoverLeave}
        onBlur={onHoverLeave}
      >
        {renderCta()}
      </Button>

      {renderModal()}
    </>
  )
}

export default RequestCompanyCallbackButton
