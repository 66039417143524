import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'

import useNotyf from '../../../../../../hooks/useNotyf'
import errorTypeService from '../../../../../../lib/services/errorTypeService'
import serviceService from '../../../../../../lib/services/serviceService'
import ActionButton from '../../../../../misc/ActionButton'

interface Props {
  companyId: number
  serviceId?: number
  serviceAreaId: number
  serviceAreaName: string
  onChangeSaved: () => void
}

const CompanyServiceToggleButton: React.FC<Props> = ({
  companyId,
  serviceId,
  serviceAreaId,
  serviceAreaName,
  onChangeSaved,
}) => {
  const [isSelected, setIsSelected] = useState(serviceId !== undefined)
  const notyf = useNotyf()

  // Watch for changes
  useEffect(() => {
    setIsSelected(serviceId !== undefined)
  }, [serviceId])

  const createServiceMutation = useMutation({
    mutationFn: async () => {
      setIsSelected(true)

      return serviceService.createService({
        company: `/v1/companies/${companyId}`,
        serviceArea: `/v1/service-areas/${serviceAreaId}`,
      })
    },
    onSuccess: () => {
      onChangeSaved()
    },
    onError: () => {
      setIsSelected(false)
    },
  })

  const deleteServiceMutation = useMutation({
    mutationFn: async () => {
      if (!serviceId) {
        return
      }

      setIsSelected(false)

      return serviceService.deleteService(serviceId)
    },
    onSuccess: () => {
      onChangeSaved()
    },
    onError: (error) => {
      // Sometimes it's forbidden because the service already has a referral attached
      if (
        errorTypeService.isApiError(error) &&
        errorTypeService.isForbiddenError(error)
      ) {
        notyf.error({
          message: `Cannot remove: ${error.response?.data['hydra:description']}`,
          duration: 4000,
        })
      } else {
        notyf.error('Something went wrong - please try again')
      }

      // Put this back how we found it before the mutation started
      setIsSelected(true)
    },
  })

  // I.e. the prop passed doesn't reflect what we now think the state should be
  // which will be because we're waiting to re-query in the parent component
  const isWaitingForSync = (serviceId !== undefined) !== isSelected

  return (
    <ActionButton
      variant={isSelected ? 'primary' : 'outline-secondary'}
      size="lg"
      className="fs-5 overflow-hidden"
      style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
      isProcessing={
        createServiceMutation.isPending ||
        deleteServiceMutation.isPending ||
        isWaitingForSync
      }
      showIsProcessingText={false}
      onClick={() => {
        if (isSelected) {
          deleteServiceMutation.mutate()
        } else {
          createServiceMutation.mutate()
        }
      }}
    >
      {serviceAreaName}
    </ActionButton>
  )
}

export default CompanyServiceToggleButton
