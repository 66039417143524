import { faCircleExclamation } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { Button } from 'react-bootstrap'

import { useAuth } from '../../../hooks/useAuth'
import { useOfficeContext } from '../../../hooks/useOfficeContext'
import { isOffice } from '../../../lib/helpers/officeHelpers'
import InfoBox from '../../misc/InfoBox'
import LoadingWidget from '../../widgets/LoadingWidget'
import AccountDropdown from './components/AccountDropdown'

interface Props {
  children: React.ReactNode
}

const OfficeLayout: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, signOut } = useAuth()
  const office = useOfficeContext()

  if (!isOffice()) {
    return (
      <InfoBox variant="yellowDark" className="mt-3" icon={faCircleExclamation}>
        This add-in is only available in the Outlook client.
      </InfoBox>
    )
  }

  if (!office.isInitialized) {
    return <LoadingWidget />
  }

  return (
    <div className="wrapper">
      <div className="mx-2 w-100">
        <div>
          {isAuthenticated && (
            <div className="d-flex justify-content-between mt-2 mb-3">
              <div className="me-2">
                <AccountDropdown />
              </div>
              <Button
                variant="link"
                onClick={() => signOut()}
                className="p-0 m-0"
              >
                Sign Out
              </Button>
            </div>
          )}
        </div>

        {children}
      </div>
    </div>
  )
}

export default OfficeLayout
