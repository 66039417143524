import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { isDevelopment } from '../lib/helpers/envHelpers'
import commonDataService from '../lib/services/commonDataService'
import { setCommonData } from '../redux/slices/commonData'
import useAppDispatch from './useAppDispatch'

export function useCommonDataQuery() {
  const dispatch = useAppDispatch()

  const query = useQuery({
    queryKey: ['common-data'],
    queryFn: () => commonDataService.getAll(),
    refetchOnWindowFocus: false,
    retry: isDevelopment() ? false : undefined, // Leave undefined to use React Query's default,
  })

  const commonData = query.data

  useEffect(() => {
    if (commonData) {
      dispatch(setCommonData(commonData))
    }
  }, [commonData, dispatch])

  return query
}
