import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { useNetworkState } from 'react-use'

const NetworkStatusBar: React.FC = () => {
  const state = useNetworkState()

  if (state.online) {
    return null
  }

  return (
    <aside
      className="bg-danger position-fixed bottom-0 start-0 end-0 w-100 text-center p-3 text-white"
      style={{ zIndex: '10000' }}
    >
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        className="text-xl me-2 align-middle"
      />
      You are not connected to the internet. Please check your connection.
    </aside>
  )
}

export default NetworkStatusBar
