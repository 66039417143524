import { FallbackRender } from '@sentry/react'
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

import ErrorMessage from '../../../misc/ErrorMessage/ErrorMessage'

interface Props {
  error: Parameters<FallbackRender>[0]
  fallbackComponent?: React.ReactNode
}

const EmbeddedErrorFallback: React.FC<Props> = ({
  error,
  fallbackComponent,
}) => {
  if (fallbackComponent) {
    return fallbackComponent
  }

  return (
    <Container>
      <Row>
        <Col lg={8}>
          <Card>
            <Card.Body>
              <ErrorMessage error={error} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default EmbeddedErrorFallback
