/**
 * Validate an email address string.
 *
 * ⚠️ As ever, email validation is tricky (impossible, really), but it's often helpful to try.
 *
 * @returns {boolean} true if the email address **_looks_** valid
 * @see https://stackoverflow.com/a/46181
 */
export function validateEmailAddress(emailAddress: string): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    emailAddress,
  )
}
