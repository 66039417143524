import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { Alert } from 'react-bootstrap'

import useAppDispatch from '../../../../../hooks/useAppDispatch'
import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import companyService from '../../../../../lib/services/companyService'
import {
  endEdit,
  startEdit,
} from '../../../../../redux/slices/completeRegistrationPopup'
import { updateCurrentCompany } from '../../../../../redux/slices/session'
import { UpdateCompanyLogoResponse } from '../../../../../types/responses/companies'
import UploadFile from '../../components/UploadFile/UploadFile'

const UploadLogo: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentCompany = useCurrentCompanyOrFail()

  const uploadLogoMutation = useMutation({
    mutationFn: (file: File) =>
      companyService.updateLogo(currentCompany.id, { logoFile: file }),
    onMutate: () => dispatch(startEdit()),
    onSuccess: (company: UpdateCompanyLogoResponse) => {
      dispatch(updateCurrentCompany(company))
    },
    onSettled: () => dispatch(endEdit()),
  })

  return (
    <div className="h-100 d-flex flex-column pop-in">
      <div className="d-flex flex-grow-1 flex-column align-items-stretch justify-content-center w-100">
        <Alert className="d-flex justify-content-center" variant="light">
          <div>
            <p className="mb-0 p-3">
              Please upload a high-resolution PNG copy of your firm's company
              logo with a transparent background.
            </p>
          </div>
        </Alert>

        <UploadFile
          uploadMutation={uploadLogoMutation}
          acceptableFileTypes={['image/png', 'image/jpeg', 'image/gif']}
          previewType="image"
          currentFileUrl={currentCompany.logoUrl}
          incorrectFileTypeMessage="Please choose a PNG image with a transparent background."
          currentFileExistsButIsUnavailable={false}
        />
      </div>
    </div>
  )
}

export default UploadLogo
