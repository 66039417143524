import { faUser } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Dropdown } from 'react-bootstrap'

import { useAuth } from '../../../../../hooks/useAuth'

const UserClients: React.FC = () => {
  const { updateClientContext, isClientUser, currentUser } = useAuth()

  return (
    <div key="clients">
      <div className="ps-3">Client Account</div>
      <Dropdown.Item
        className={isClientUser ? 'active' : ''}
        onClick={() => updateClientContext()}
      >
        <FontAwesomeIcon icon={faUser} className="align-middle me-2" />
        {currentUser?.fullName}
      </Dropdown.Item>
      <Dropdown.Divider />
    </div>
  )
}

export default UserClients
