import {
  BusinessAssetsInput,
  BusinessSalePlanningAmount,
  ShareInput,
} from '../../utils/types'

export const ShareInputFields: Record<keyof ShareInput, string> = {
  company_name: 'company_name',
  companies_house_option: 'companies_house_option',
  is_incorporated: 'is_incorporated',
  unincorporated_name: 'unincorporated_name',
  want_to_sell_shares: 'want_to_sell_shares',
  year: 'year',
  expected_value: 'expected_value',
  business_planning: 'business_planning',
  personal_planning: 'personal_planning',
  confidence: 'confidence',
  is_sole_trader: 'is_sole_trader',
}

export const BusinessAssetsInputFields: Record<
  keyof BusinessAssetsInput,
  keyof BusinessAssetsInput
> = {
  shares: 'shares',
}

export const BUSINESS_SALE_PLANNING_AMOUNT: BusinessSalePlanningAmount[] = [
  'None',
  'Some',
  'Comprehensive',
]
