import { CommonData, ServiceAreaItem } from '../../types/responses/common-data'
import { getOrFail } from '../helpers/helperFunctions'

class ServiceAreaService {
  private endpoint = '/v1/service-areas'

  getServiceAreaByIri(
    iri: IRI,
    serviceAreas: CommonData['serviceAreas'],
  ): ServiceAreaItem {
    const serviceArea = serviceAreas.find(
      (serviceArea) => serviceArea['@id'] === iri,
    )

    return getOrFail(serviceArea, `Service area with IRI ${iri} not found`)
  }

  getIri(id: number): IRI {
    return `${this.endpoint}/${id}`
  }
}

const serviceAreaService = new ServiceAreaService()

export default serviceAreaService
