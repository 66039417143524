export const ROUTES = {
  // Guest
  index: '/',
  signIn: '/sign-in',
  signOut: '/sign-out',

  // Company dashboard
  addCompany: '/add-company',
  addExistingAdviser: '/add-existing-adviser',
  addRelationship: '/professional-network/add-relationship',
  addRelationshipAgreement:
    '/professional-network/relationships/:panelId/add-agreement',
  bookMeeting: '/book-meeting',
  createReferralLink: '/referral-link/create',
  companyCompassDashboard: '/company-compass/dashboard',
  companyCompassSnapshots: '/company-compass/snapshots',
  compassPdfReportPreview: '/compass/pdf-report/preview',
  compassPdfReportSection: '/compass/pdf-report/:section',
  companyProfile: '/company-profile',
  completeRegistration: '/complete-registration',
  contacts: '/contacts',
  contactsImport: '/contacts/import',
  demoVideos: '/demo-videos',
  debug: '/debug',
  disclaimer: '/disclaimer',
  findProfessional: '/find-professional',
  gettingStarted: '/getting-started',
  mobileLanding: '/mobile',
  inviteToRq: '/professional-network/invite-to-rq',
  manageUsers: '/manage-users',
  preferences: '/preferences',
  professionalNetworkApprovals: '/professional-network/approvals',
  professionalNetworkRelationships: '/professional-network/relationships',
  professionalNetworkStats: '/professional-network/stats',
  companyAggregatedStats: '/professional-network/company-aggregated-stats',
  referClient: '/refer-client',
  referrals: '/referrals/list',
  referralESignatureConsent:
    '/referrals/:referralId/e-signature-consent/:callbackCode',
  referralESignatureConsentPdf:
    '/referrals/:referralId/e-signature-consent/pdf/:callbackCode',
  referralESignatureConsentPdfPreview:
    '/referrals/:referralId/e-signature-consent/pdf-preview',
  referralFeedback: '/referrals/:referralId/feedback/:token',
  referralsDashboard: '/referrals/dashboard',
  referralsImportIndividualUpload: '/referrals/import/individual-upload',
  referralsImport: '/referrals/import',
  referralLink: '/referral-links/:code',
  referralSummary: '/referrals/:id',
  rqCertifiedApplicationProcess: '/rq-certified/application-process',
  rqCertifiedApplicationStatus: '/rq-certified/application-status',
  rqCertifiedGlossary: '/rq-certified/glossary',
  rqCertifiedNdaPdf: '/rq-certified/:companyId/nda',
  rqCertifiedPdfGuides: '/rq-certified/pdf-guides',
  walkthrough: '/walkthrough',

  // Compass portal
  compassActions: '/compass/snapshots/:token/actions',
  compassConfirmSharing: '/compass/:token/confirm-sharing',
  compassDashboard: '/compass/dashboard',
  compassDisclaimer: '/compass/disclaimer',
  compassDetailsAlreadyOnFile:
    '/compass/:companyReferralCode/details-already-on-file',
  compassHowItWorks: '/compass/how-it-works',
  compassSharing: '/compass/sharing',
  compassSnapshots: '/compass/snapshots',
  compassSnapshot: '/compass/snapshots/:token',
  compassStart: '/compass/start',
  compassUpdate: '/compass/update',
  compassPartners: '/compass/partners',
  compassAddCompany: '/compass/add-company',

  // Guest landing - black template
  confirmConsent: '/referral/confirm-consent/:id/:code',
  inviteUser: '/invite-user/:id/:code',
  requestCallback: '/referral/request-callback/:id/:code',
  commissionConsent: '/referral/commission-consent/:id/:code',
  withdrawGdprConsent: '/referral/withdraw-gdpr-consent/:id/:code',
  clientPayment: '/client-payment/:id/:code',

  // Guest landing - white template
  invitation: '/invitation/:referralCode/:companyTypeCode?', // :companyTypeCode isn't used but is kept to support old URLs

  // Authenticated landing
  welcome: '/welcome',
  suspended: '/company-suspended',
  shortlist: '/shortlist',
  register: '/register',

  // Misc
  companyCompass: '/compass',
  companyCompassGlobalLink: '/company-compass/global-link',
  companyCompassInvite: '/company-compass/:companyReferralCode',
  companyCompassInvitePreview: '/company-compass/:companyReferralCode/preview',
  companyCompassView: '/company-compass/view',
  compassConfirmStart: '/compass/:token/confirm-start',
  compassConfirmSharingViaGlobalLink:
    '/compass/:companyReferralCode/confirm-sharing-via-global-link',
  compassPdfGuide: '/compass/:companyId/pdf-guide',
  compassRedirect: '/compass/redirect',
  notFound: '/not-found',
  invalidOrExpired: '/invalid-or-expired',
  splashScreen: import.meta.env.VITE_SPLASH_URL,
  userCompass: '/user-compass', // For clients accessing Compass via invite link

  // Office Task Pane
  officeTaskPane: '/office365app/taskpane', // Landing page for Office 365 addons

  // ICAEW
  icaewOverview: '/icaew/overview',
  icaewFirmSearch: '/icaew/firm-search',
  icaewTrends: '/icaew/trends',
  icaewDisclaimer: '/icaew/disclaimer',
}

export type RouteKey = keyof typeof ROUTES
export type RoutePath = (typeof ROUTES)[RouteKey]
